export const LINKED_USER_ERROR = 'LinkedUsers';
export const USER_EXISTS_ERROR = 'Already found an entry for username';
export const NEW_USER_SIGNUP = 'NewAccountSignup';
export const CURRENT_IDP_IDENTIFIER = 'currentIdpIdentifier';
export const CODE_CHALLENGE = 'codeChallenge';
export const CODE_VERIFIER = 'codeVerifier';

// @ts-ignore
const authorizeEndpoint = window._env_.AUTHORIZE_ENDPOINT;
// @ts-ignore
const tokenEndpoint = window._env_.TOKEN_ENDPOINT;
// @ts-ignore
const clientId = window._env_.CLIENT_ID;
// @ts-ignore
const redirectUri = window._env_.BASE_URI + '/login';

export const buildSSORedirectURI = (idpIdentifier, codeChallenge, username) => {
    const ssoURI = new URL(authorizeEndpoint);
    ssoURI.searchParams.append('response_type', 'code');
    ssoURI.searchParams.append('identity_provider', idpIdentifier);
    ssoURI.searchParams.append('client_id', clientId);
    ssoURI.searchParams.append('redirect_uri', redirectUri);
    ssoURI.searchParams.append('scope', 'openid+profile');
    ssoURI.searchParams.append('code_challenge_method', 'S256');
    ssoURI.searchParams.append('code_challenge', codeChallenge);
    ssoURI.searchParams.append('login_hint', username);
    return decodeURIComponent(ssoURI.href);
};

export const buildGoogleSocialSigninURI = () => {
    const ssoURI = new URL(authorizeEndpoint);
    ssoURI.searchParams.append('response_type', 'code');
    ssoURI.searchParams.append('identity_provider', 'Google');
    ssoURI.searchParams.append('client_id', clientId);
    ssoURI.searchParams.append('redirect_uri', redirectUri);
    ssoURI.searchParams.append('scope', 'openid+profile');
    return decodeURIComponent(ssoURI.href);
};
