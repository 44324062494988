import { SchedulerLineItem } from './types';
import { CalendarModel, DateHelper, EventModel } from '@bryntum/schedulerpro/schedulerpro.module.js';
import {
    API_TIME_FORMAT,
    DateRange,
    mergeDateAndTime,
    toDateTimeISOWithoutOffset,
    toISOFromJSDate,
    toSimpleTimeFromJSDate
} from '../common/utils/dateTime';
import { isValid, parse, parseISO } from 'date-fns';
import { isEmpty, isEqual, isNil, uniqWith } from 'lodash';
import { getRowPermissions } from '../common/auth/api';
import { toBryntumCalendarIntervals, getCalendars } from './SchedulerContext';
import { Activity, Product, Resource, Production } from 'sr-types/lib/search/v1/graphql';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import { constants } from '../common/constants';
import { roundUpByDurationUnit } from '../supply/booking/ScheduleCalculator';
import { PLANNING, SUPPLIER } from '../common/nav/apps';
import { ActivityEntityFormType } from '../production/helpers/activityUtils';

export const DURATION_UNIT_MAPPING = new Map([
    ['hour', 'h'],
    ['day', 'd'],
    ['week', 'w'],
    ['month', 'M'],
    ['year', 'y']
]);

export const SR_TYPES = {
    Activity: 'Activity',
    Reservation: 'Reservation',
    Resource: 'Resource',
    Production: 'Production'
};

export const SYSTEM_CALENDAR = '7 Day Working';

const getEventAttributes = (event) => {
    const attributes = {
        name: '',
        resourceLabel: '',
        contact: '',
        organization: '',
        opportunityId: undefined,
        showTime: false,
        opportunityName: '',
        reservationForName: '',
        lineItemId: '',
        production: '',
        organizationName: '',
        status: '',
        opportunityType: '',
        reservationGuestsAvailable: false,
        isChild: true,
        primary: false,
        ownerOrganizationName: ''
    };
    attributes.production = event.production?.name;
    attributes.organizationName = event.organization?.name;
    attributes.resourceLabel = event.status;

    return attributes;
};

export const getAttributesFromOpportunity = (opportunity) => {
    let attributes = getEventAttributes(opportunity);
    const orgName =
        opportunity.organization && opportunity.organization.label
            ? opportunity.organization.label
            : opportunity.organization?.organizationReference?.label;
    const prodName =
        opportunity.production && opportunity.production.label
            ? opportunity.production.label
            : opportunity.production?.productionReference?.label;
    attributes.status = opportunity.status;
    attributes.opportunityName = opportunity.name;
    attributes.opportunityType = opportunity.opportunityType;
    attributes.organization = orgName || '';
    attributes.production = prodName || '';
    attributes.opportunityId = opportunity.identity.id;
    attributes.reservationGuestsAvailable = opportunity.guests?.length > 0 && opportunity.primary;
    attributes.ownerOrganizationName = opportunity.ownerOrganizationName;

    if (opportunity.contacts && opportunity.contacts.length) {
        const c = opportunity.contacts[0];
        if (c.role) {
            attributes.contact = `${c.name.firstName} ${c.name.lastName} - ${c.role}`;
        } else {
            attributes.contact = `${c.name.firstName} ${c.name.lastName}`;
        }
    }
    return attributes;
};

export const toSchedulerLineItemsFromBookingLineItems = (opportunity) => {
    let oppAttributes = getAttributesFromOpportunity(opportunity);
    return opportunity.lineItems.map((li) => {
        const attributes = { ...oppAttributes };
        const startTime: Date = parse(li.dateRange.startTime, API_TIME_FORMAT, new Date());
        const endTime: Date = parse(li.dateRange.endTime, API_TIME_FORMAT, new Date());
        if (isValid(startTime) && isValid(endTime)) {
            attributes.showTime = true;
        }
        attributes.isChild = !li.needsDetailedSchedule;
        attributes.primary = li.primary;
        attributes.reservationForName = li.product?.label;
        attributes.lineItemId = li.identity.id;
        const assignedResources = li.associatedItems
            ? li.associatedItems.map((r) => {
                  return { id: r?.reference?.id, name: r?.label };
              })
            : [];
        const resources = [...li.associatedItems?.map((ai) => ai?.reference?.id), li.product?.reference?.id];
        return {
            id: li.identity.id,
            attributes: attributes,
            dateRange: li.dateRange,
            primary: li.primary,
            assignedResources: assignedResources,
            resources: resources,
            name: li.name,
            status: attributes.status,
            ownerOrganizationName: attributes.ownerOrganizationName,
            permissions: opportunity.permissions,
            calendar: li.calendar && li.calendar.id
        };
    });
};

export const toSchedulerActivityItemsFromProduction = (activity: Activity | ActivityEntityFormType) => {
    if (activity.activityType === 'Milestone') {
        activity.dateRange.durationSpecified = true;
        activity.dateRange.dateTimeDuration = { value: 1, unit: 'day' };
    }
    const startTime: Date = parse(activity.dateRange.startTime, API_TIME_FORMAT, new Date());
    const endTime: Date = parse(activity.dateRange.endTime, API_TIME_FORMAT, new Date());
    const id = activity.id || activity.identity.id;
    var showTime = false;
    if (isValid(startTime) && isValid(endTime)) {
        showTime = true;
    }
    return {
        ...activity,
        showTime,
        id,
        products: activity.products,
        'sr-type': SR_TYPES.Activity,
        resources: activity.resourceReferences?.map((ar) => ar.id)
    };
};

export const toSchedulerLineItemFromBryntumEvent = (events: EventModel[]) => {
    return events.map((e) => {
        const isHourly = e.durationUnit === 'hour';
        const start = e.startDate ? toISOFromJSDate(e.startDate) : undefined;
        const end = e.endDate
            ? isHourly
                ? toISOFromJSDate(e.endDate)
                : toISOFromJSDate(DateHelper.add(e.endDate, -1, 'd'))
            : undefined;
        const startTime = isHourly && e.startDate ? toSimpleTimeFromJSDate(e.startDate) : undefined;
        const endTime = isHourly && e.endDate ? toSimpleTimeFromJSDate(e.endDate) : undefined;
        const duration = roundUpByDurationUnit(e.durationUnit, e.duration);
        return {
            id: e.id,
            dateRange: {
                start,
                end,
                startTime,
                endTime,
                duration
            }
        };
    });
};

const isManuallyScheduled = (hasDependencies: boolean, isFirstChild: boolean) => {
    if (hasDependencies) {
        return isFirstChild;
    }
    return true;
};

export const toBryntumEventsAndAssignments = (schedulerLineItems: SchedulerLineItem[], hasDependencies = false) => {
    const events = [];
    const assignmentData = new Set();

    let firstChildFound = false;
    for (let idx = 0; idx < schedulerLineItems.length; idx++) {
        const sli = schedulerLineItems[idx];
        if (hasDependencies && !firstChildFound && sli.attributes.isChild) {
            schedulerLineItems[idx].attributes.isFirstChild = true;
            firstChildFound = true;
        }

        const { mergedStart, mergedEnd } = getMergedStartEnd(sli.dateRange);
        const startValid = isValid(mergedStart);
        const endValid = isValid(mergedEnd);
        events.push({
            ...sli,
            attributes: sli.attributes,
            id: sli.id,
            opportunityId: sli.attributes.opportunityId,
            opportunityType: sli.attributes.opportunityType,
            reservationGuestsAvailable: sli.attributes.reservationGuestsAvailable,
            isChild: sli.attributes.isChild,
            assignedResources: sli.assignedResources,
            manuallyScheduled: isManuallyScheduled(hasDependencies, sli.attributes.isFirstChild),
            startDate: startValid ? toDateTimeISOWithoutOffset(mergedStart) : undefined,
            endDate: endValid ? toDateTimeISOWithoutOffset(mergedEnd) : undefined,
            duration: sli.dateRange.dateTimeDuration?.value,
            reservationForName: sli.attributes.reservationForName,
            durationUnit: DURATION_UNIT_MAPPING.get(sli.dateRange.dateTimeDuration?.unit),
            lineItemId: sli.attributes.lineItemId,
            calendar: sli.calendar,
            'sr-type': SR_TYPES.Reservation
        });
        sli.resources?.forEach((res) => {
            // Using a Set to prevent duplicates during insertion
            assignmentData.add(
                JSON.stringify({
                    resourceId: res,
                    eventId: sli.id,
                    lineItemId: sli.attributes.lineItemId
                })
            );
        });
    }
    // Convert the set back to an array
    const assignments = Array.from(assignmentData).map((item) => JSON.parse(item));
    return { events, assignments };
};

export const toBryntumEventsAndAssignmentsForActivity = (lineItems, application) => {
    const events = [];
    const assignments = [];

    lineItems.forEach((lineItem) => {
        const { mergedStart, mergedEnd } = getMergedStartEnd(lineItem.dateRange);
        const startValid = isValid(mergedStart);
        const endValid = isValid(mergedEnd);
        events.push({
            ...lineItem,
            startDate: startValid ? toDateTimeISOWithoutOffset(mergedStart) : '',
            endDate: endValid ? toDateTimeISOWithoutOffset(mergedEnd) : '',
            startTime: lineItem.dateRange?.startTime,
            endTime: lineItem.dateRange?.endTime,
            type: lineItem.activityType,
            manuallyScheduled: true,
            duration: lineItem.dateRange.dateTimeDuration?.value,
            durationUnit: DURATION_UNIT_MAPPING.get(lineItem.dateRange.dateTimeDuration?.unit),
            locationCity: lineItem.location ? lineItem.location.address?.locality : '',
            locationCountry: lineItem.location ? lineItem.location.address?.countryRegion : '',
            'sr-type': SR_TYPES.Activity
        });
        if (application?.name === PLANNING.name) {
            lineItem.resources?.forEach((res) => {
                assignments.push({
                    resourceId: res,
                    eventId: lineItem.id
                });
            });
            if (lineItem.production.id) {
                assignments.push({
                    resourceId: lineItem.production.id,
                    eventId: lineItem.id
                });
            }
        } else if (application?.name === SUPPLIER.name) {
            lineItem.productReferences?.forEach((res) => {
                assignments.push({
                    resourceId: res.id,
                    eventId: lineItem.id
                });
            });
        }
    });

    return { events, assignments };
};

export const toBryntumDependenciesForActivity = (lineItems) => {
    const dependencies = [];
    lineItems.forEach((lineItem) => {
        if (lineItem.dependencies?.length) {
            lineItem.dependencies.forEach((dependency) => {
                const { fromActivity, toActivity, lag, lagUnit, type } = dependency;
                dependencies.push({
                    from: fromActivity,
                    to: toActivity,
                    lag: lag,
                    lagUnit: lagUnit,
                    type: type
                });
            });
        }
    });
    return { dependencies };
};

export const toBryntumDependencies = (schedulerLineItems: SchedulerLineItem[]) => {
    const dependencies = [];
    schedulerLineItems.forEach((sli, idx) => {
        if (idx < schedulerLineItems.length - 1) {
            dependencies.push({
                from: sli.id,
                to: schedulerLineItems[idx + 1].id,
                lag: 0,
                lagUnit: 'd',
                type: 2
            });
        }
    });
    return dependencies;
};

export const productToBryntumResourcesAndCalendars = (products) => {
    const resourceCalById = new Map<string, CalendarModel>();
    const resources = products?.map((r: Product) => {
        return {
            id: r.id,
            name: r.name,
            calendar: r.calendar ? r.calendar.id : undefined,
            type: r.type,
            subType: r.subType,
            locations: r.availableLocations,
            ownerOrganizationName: r.ownerOrganizationName,
            packageType: r.packagingType,
            offerType: r.offerTypes,
            category: r.categories?.map((c: any) => c.value),
            reference: r.reference
        };
    });

    return { resources, calendars: Array.from(resourceCalById.values()) };
};

export const planningResourceToBryntumResourcesAndCalendars = (planningResources: [Resource]) => {
    const resourceCalById = new Map<string, CalendarModel>();
    const resources = planningResources
        .filter((resource: Resource) => resource.production && !isEmpty(resource.production))
        .map((r: Resource) => {
            return {
                id: r.id,
                name: r.name,
                type: r.type,
                resourceCategory: r.resourceCategory,
                budgetCategory: r.budgetCategory,
                status: r.status,
                location: r.location,
                locationCity: r.location ? r.location.address?.locality : '',
                locationCountry: r.location ? r.location?.address?.countryRegion : '',
                production: r.production,
                'sr-type': SR_TYPES.Resource
            };
        });

    return { resources, calendars: Array.from(resourceCalById.values()) };
};

export const productionDataToBryntumFormat = (productionData) => {
    const production = [];
    const productionItem = {
        ...productionData,
        id: productionData.identity.id,
        'sr-type': SR_TYPES.Production
    };
    // deleting fields which are not required for bryntum store data
    delete productionItem.identity;
    delete productionItem.alternateIds;
    delete productionItem.associatedOrgs;
    delete productionItem.associatedPeople;
    delete productionItem.budget;
    delete productionItem.keyActivities;
    delete productionItem.keyPeople;
    delete productionItem.productionDates;
    production.push(productionItem);
    return { production };
};

export const toBryntumResourceProduction = (productionData: [Production]) => {
    const production = productionData.map((production: Production) => {
        return {
            ...production,
            'sr-type': SR_TYPES.Production
        };
    });
    return { production };
};

export const toBryntumResourcesAndCalendars = (schedulerLineItems: SchedulerLineItem[]) => {
    const resourceIds = new Set<string>();
    const resourceNames = new Map<string, String>();
    const resourceCalById = new Map<string, CalendarModel>();
    schedulerLineItems.forEach((sli, idx) => {
        sli.assignedResources.forEach((ar) => {
            resourceNames[ar.id] = ar.name;
        });
        sli.resources.forEach((resId) => {
            if (sli.dateRange.end != '') {
                resourceIds.add(resId);
            }
        });
    });
    const resources = Array.from(resourceIds).map((resId) => {
        return {
            id: resId,
            name: resourceNames[resId],
            calendar: `${resId}-cal`
        };
    });
    return { resources, calendars: Array.from(resourceCalById.values()) };
};

export const toLineItemsFromProductionActivity = (activities: [Activity], application: string) => {
    const shouldFilterByProduction = application === PLANNING.name;
    return activities
        .filter(
            (activity: Activity) => activity.dateRange && (!shouldFilterByProduction || !isEmpty(activity.production))
        )
        .map(toSchedulerActivityItemsFromProduction);
};

export const toSchedulerLineItemsFromReservations = (reservations) => {
    return reservations.map((res) => {
        // TODO: generalize the object structure for RM and production planning timeline views. attributes are currently used in RM views only.
        const attributes = getEventAttributes(res);
        attributes.status = res.status;
        attributes.primary = res.primary;
        attributes.isChild = !res.hasDetailedSchedule;
        attributes.reservationGuestsAvailable = res.reservationGuestsAvailable;
        attributes.ownerOrganizationName = res.ownerOrganizationName;
        attributes.lineItemId = res.lineItemId;
        let assignedResources = [];
        const startTime: Date = parse(res.dateRange.startTime, API_TIME_FORMAT, new Date());
        const endTime: Date = parse(res.dateRange.endTime, API_TIME_FORMAT, new Date());
        res.associatedResources.push({ reference: res.reservationFor });
        assignedResources = res.associatedResources.map((r) => {
            return { id: r.reference.id, name: r.label };
        });
        attributes.resourceLabel = res.status;
        if (isValid(startTime) && isValid(endTime)) {
            attributes.showTime = true;
        }

        const permissions = getRowPermissions(res.security);
        attributes.reservationForName = res.reservationForName;
        if (res.opportunity) {
            attributes.opportunityName = res.opportunity.name;
            attributes.opportunityType = res.opportunity.OpportunityType;
            attributes.production = res.opportunity.production?.name;
            attributes.organizationName = res.opportunity.organization?.name;
            if (res.opportunity.contacts && res.opportunity.contacts.length) {
                const c = res.opportunity.contacts[0];
                if (c.role) {
                    attributes.contact = `${c.name.firstName} ${c.name.lastName} - ${c.role}`;
                } else {
                    attributes.contact = `${c.name.firstName} ${c.name.lastName}`;
                }
            }
            if (res.opportunity.organization && res.opportunity.organization.name !== '') {
                attributes.organization = res.opportunity.organization.name;
            }
            if (res.opportunity.id) {
                attributes.opportunityId = res.opportunity.id;
            }
        }
        return {
            ...res,
            id: res.id,
            permissions: permissions,
            dateRange: res.dateRange,
            resources: res.associatedResources?.map((ar) => ar.reference.id),
            attributes,
            assignedResources,
            calendar: res.calendar ? res.calendar.id : undefined,
            organization: res.opportunity.organization?.name,
            production: res.opportunity.production?.name,
            opportunityName: res.opportunity.name
        };
    });
};

export const getMergedStartEnd = (dateRange: DateRange) => {
    // if(dateRange.end == '') dateRange.end = dateRange.start
    const start: Date = parseISO(dateRange.start);
    const startTime: Date = parse(dateRange.startTime, API_TIME_FORMAT, new Date());
    const end: Date = parseISO(dateRange.end);
    const endTime: Date = parse(dateRange.endTime, API_TIME_FORMAT, new Date());
    const mergedStart = mergeDateAndTime(start, startTime);
    const mergedEnd = mergeDateAndTime(end, endTime);
    return { mergedStart: mergedStart, mergedEnd: mergedEnd };
};

export const toBryntumCalendars = (calendarData) => {
    const bryntumCalendars = calendarData.map((calendar) => {
        return toBryntumCalendar(calendar);
    });
    return bryntumCalendars;
};

export const toBryntumCalendar = (calendar) => {
    const calendarsIntervals = toBryntumCalendarIntervals(calendar);
    return {
        id: calendar.id,
        name: calendar.name,
        unspecifiedTimeIsWorking: calendar.unspecifiedTimeIsWorking,
        intervals: calendarsIntervals,
        public: calendar.public
    };
};

export const getProjectCalendar = (calendars, organizationAccountDetails) => {
    // returns accountCalendar or system calendar if account cal is not assigned
    const accountCalendarId =
        organizationAccountDetails && !isNil(organizationAccountDetails.calendar)
            ? organizationAccountDetails.calendar.id
            : '';

    let projectCalendar = calendars.find((cal) => cal.id == accountCalendarId);
    if (!projectCalendar) {
        projectCalendar = calendars.find((cal) => cal.name == SYSTEM_CALENDAR);
    }
    return projectCalendar;
};

export const getCalendarAndEntity = (entityName, activeOrganizationAccount) => {
    const calendarsPromise = getCalendars(activeOrganizationAccount).then((res) => res?.data?.results?.hits?.items);
    const entityPromise = searchClient
        .query({
            query: slimResultsQuery(entityName),
            variables: {
                filters: [{ identifier: 'entity', value: entityName }],
                page: {
                    from: 0,
                    size: 2000
                }
            },
            fetchPolicy: constants.apolloFetchPolicy,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
        .then((res) => res?.data?.results?.hits?.items);
    return { calendarsPromise, entityPromise };
};

export const getEventTooltipTemplateForActivity = (data, errorTitle = undefined, errorDescription = undefined) => {
    const firstLine = data.eventRecord.name;
    var secondLine = data.startText + ' - ' + data.endText;
    if (data.eventRecord.durationUnit) {
        const durationUnit = DURATION_UNIT_MAPPING.get(data.eventRecord.durationUnit);
        secondLine = secondLine + '(' + data.eventRecord.duration + durationUnit + ')';
    }
    const thirdLine = data.eventRecord.activityType || data.eventRecord['data'].activityType;
    var tooltipDisplay = `<div>${firstLine}</div>
                                <div>${secondLine}</div>
                                <div>${thirdLine}</div>`;
    if (!isEmpty(data.eventRecord.category) && !isEmpty(data.eventRecord.location)) {
        tooltipDisplay =
            tooltipDisplay +
            `<div>${data.eventRecord.category.value}</div>
                           <div>${data.eventRecord.location.label}</div>`;
    }
    if (data.eventRecord.data.hasError) {
        tooltipDisplay = tooltipDisplay + `<div>${errorTitle}. ${errorDescription}</div>`;
    }
    return tooltipDisplay;
};

export const editBryntumActivity = (schedulerInstance, event) => {
    schedulerInstance.eventStore.applyChangeset({
        updated: [
            { id: event.id, activityType: event.activityType },
            { id: event.id, dateRange: event.dateRange },
            { id: event.id, prouction: event.production },
            { id: event.id, productReferences: event.productReferences }
        ]
    });
};

export const getEventTooltipTemplateForReservation = (data) => {
    const event = data.eventRecord;
    const attributes = event.attributes || event.data.attributes;

    return event.isMilestone
        ? `<div><b>${data.eventRecord.attributes.opportunityName}</b></div>
                            <div>${DateHelper.format(data.eventRecord.startDate, 'MMM DD, YYYY')}</div>`
        : attributes.showTime
          ? `<div style="display: flex; flex-direction: column;">
                <div><b>${attributes.opportunityName}</b></div>
                <div>${attributes.status}</div>
                <div>${DateHelper.format(event.startDate, 'MMM DD, YYYY hh:mm a')} -
                     ${DateHelper.format(event.endDate, 'MMM DD, YYYY hh:mm a')}</div>
                <div>${attributes.contact}</div>
                <div>${attributes.organization}</div>
                <div>${attributes.production}</div>
           </div>`
          : `<div style="display: flex; flex-direction: column;">
                <div><b>${attributes.opportunityName}</b></div>
                <div>${attributes.status}</div>
                <div>${data.startText} - ${data.endText}</div>
                <div>${attributes.contact}</div>
                <div>${attributes.organization}</div>
                <div>${attributes.production}</div>
           </div>`;
};
