import { GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';
import { useFeaturePermissions } from '../common/auth/UserContext';
import DataGrid from '../common/list/DataGrid';
import { enqueueSnackbar } from '../common/components/Toast';
import I18n, { useI18n } from '../common/i18n/I18n';
import Confirm from '../common/components/Confirm';
import { Typography } from '@mui/material';
import ShortlistLineItemEditor from './ShortlistLineItemEditor';
import { LineItem } from 'sr-types/lib/shortlist/v1/graphql';
import { V } from '../common/Layout';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import WithSidePanel from '../common/components/layout/WithSidePanel';
import Notes from '../common/notes/Notes';
import { Icons } from '../common/icons/Icons';
import useShortlistLineItemsColDefs, { inquiryStatus } from './useShortlistLineItemsColDefs';
import { ALL_PERMISSIONS, AllPermissions } from '../common/auth/api';
import { deleteItemFromState, inquiriesInputToShortlist, lineItemInputToShortlist } from './shortlist';
import {
    defineCommonInquiryProductionData,
    defineShortlistLineItemRows,
    LineItemAdditionalFields,
    productionOtherTitlesToOptions,
    ShortlistLineItemsProps
} from './shortlistLineItem';
import InquiryButton from './form/inquiry/InquiryButton';
import { useDeleteLineItemFromShortlist } from './shortlistApi';
import {
    OrganizationInviteFormData,
    organizationValidationRules,
    ORGANIZATION_TYPE,
    PEOPLE_TYPE,
    PersonInviteFormData,
    personValidationRules,
    toOrgInquiryInput,
    toPersonInquiryInput,
    getInquiryPersonalisedMessage,
    getInquiryInviteeData
} from './form/inquiry/inquiry';
import InquiryForm from './form/inquiry/InquiryForm';
import { useGetPersonDetails } from '../common/components/person/person';
import { useProductionResourceProvider } from '../production/resource/ProductionResourceContext';
import { useGetOrganizationsDetails } from '../organization/organization';
import { RESOURCE_TYPE } from '../production/resource/common/resourceConstants';
import { useOnMobile } from '../common/hooks/breakpoints';
import { useStyling } from '../common/Theme';
import useHistory from '../common/utils/useHistory';
import SummaryPanel from '../common/summaryPanel/SummaryPanel';

export type LineItemRows = LineItem & LineItemAdditionalFields & { __reorder__: string; noteExists?: boolean };

export type LineItemExtended = LineItem & LineItemAdditionalFields;

export const handleShortlistLineItemResponse = ({
    variant,
    query
}: {
    variant: 'success' | 'error';
    query: 'save' | 'delete';
}) => {
    () => enqueueSnackbar(<I18n token={`shortlist.line.items.${query}.${variant}`} />, { variant });
};

export default ({
    shortlistId,
    viewState,
    setViewState,
    resourceData,
    productionData,
    resourceNote,
    handleItemClick,
    setIsMarketplaceOpen
}: ShortlistLineItemsProps) => {
    const [filter, setFilter] = useState([]);
    const { theme } = useStyling();

    const { deleteShortlistLineItem, isLineItemDeleting } = useDeleteLineItemFromShortlist();
    const { selectedItem, setSelectedItemLoading } = useProductionResourceProvider();
    const [selectedShortlistRowId, setSelectedShortlistRowId] = useState<string>();
    const featurePermissions = useFeaturePermissions('Shortlist');
    const canInvite = useFeaturePermissions('Assess Avails')?.[AllPermissions.Manage];
    const entityPermissions = ALL_PERMISSIONS;
    const { searchParams, changeSearch } = useHistory();
    const isTypeCrewOrCast = [RESOURCE_TYPE.crew, RESOURCE_TYPE.cast].includes(resourceData?.type);
    const { inviteeSearchData, isSearchLoading } = getInquiryInviteeData(isTypeCrewOrCast, filter);
    const [isOpen, setIsOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [lineItemIndex, setLineItemIndex] = useState(undefined);
    const [lineitemToDelete, setLineItemToDelete] = useState(undefined);
    const [notesEntityReference, setNotesEntityReference] = useState<Reference>();
    const [panelTitle, setPanelTitle] = useState<string>();
    const [showInviteButton, setShowInviteButton] = useState<boolean>(false);
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [summaryEntityReference, setSummaryEntityReference] = useState<Reference>();
    const isMobile = useOnMobile();
    const [expandView, setExpandView] = useState<boolean>(false);
    const isMarketplace = searchParams?.get('marketplaceActive');

    const { personDetails, personDetailsLoading, personDetailsRefetch } = useGetPersonDetails(
        selectedShortlistRowId,
        !selectedShortlistRowId
    );
    const { organizationDetails, organizationDetailsLoading, organizationDetailsRefetch } = useGetOrganizationsDetails(
        selectedShortlistRowId,
        !selectedShortlistRowId
    );

    const personalizedMessage = getInquiryPersonalisedMessage(isTypeCrewOrCast, resourceData?.resourceCategory);

    const commonProps = {
        isModalOpen: openInviteModal,
        onCloseModal: () => setOpenInviteModal(false),
        onSendInquirySuccess: (inquiriesInput) => sendingInquiryOnSuccess(inquiriesInput),
        filter
    };

    const productionOptions = useMemo(
        () => productionOtherTitlesToOptions(productionData?.otherTitles),
        [productionData?.otherTitles]
    );
    const commonInquiryProductionData = useMemo(() => {
        return (
            resourceData &&
            productionData &&
            defineCommonInquiryProductionData(resourceData, productionData, productionOptions, personalizedMessage)
        );
    }, [resourceData]);

    useEffect(() => {
        setSelectedItemLoading(personDetailsLoading || organizationDetailsLoading);

        if (personDetails && isTypeCrewOrCast) {
            handleItemClick(personDetails.person);
        } else if (organizationDetails) {
            handleItemClick(organizationDetails?.organization);
        }
    }, [personDetails, personDetailsLoading, isTypeCrewOrCast, organizationDetails, organizationDetailsLoading]);

    const panels = [
        {
            id: 'notes',
            icon: Icons.Notes,
            label: 'Notes',
            tooltip: notesEntityReference ? <I18n token="notes.close" /> : 'notes.grid',
            disabled: !notesEntityReference,
            onClick: () => {
                return new Promise<void>((resolve) => {
                    setNotesEntityReference(undefined);
                    resolve();
                });
            },
            component: (onClose) => (
                <Notes
                    id={'notes-lab'}
                    title={panelTitle}
                    entityReference={notesEntityReference}
                    onClose={() => {
                        setNotesEntityReference(undefined);
                        onClose();
                    }}
                    featurePermissions={featurePermissions}
                    entityPermissions={entityPermissions}
                />
            )
        },
        {
            id: 'quick-insights',
            icon: Icons.SummarizeOutlined,
            label: <I18n token="common.quick.insights" />,
            tooltip: <I18n token="common.quick.insights" />,
            disabled: !summaryEntityReference,
            component: (onClose) => (
                <SummaryPanel
                    entityReference={summaryEntityReference}
                    about={
                        summaryEntityReference?.id &&
                        viewState?.lineItems?.find((item) => item?.identity?.id === summaryEntityReference.id)?.summary
                            ?.about
                    }
                    featurePermissions={featurePermissions}
                    entityPermissions={entityPermissions}
                    sidePanelActions={[
                        !isMobile && {
                            icon: !expandView ? Icons.ExpandIcon : Icons.ShrinkIcon,
                            label: !expandView ? <I18n token="dialog.expand" /> : <I18n token="dialog.shrink" />,
                            onClick: () => {
                                setExpandView((prevState) => !prevState);
                            }
                        },
                        {
                            icon: Icons.Close,
                            label: <I18n token="dialog.close" />,
                            onClick: onClose
                        }
                    ]}
                    onAddNotes={() => {
                        setNotesEntityReference(summaryEntityReference);
                        setSummaryEntityReference(undefined);
                    }}
                    onClose={() => {
                        setSummaryEntityReference(undefined);
                        onClose();
                    }}
                />
            )
        }
    ];

    const columns: GridColDef[] = useShortlistLineItemsColDefs(
        (row) => {
            setSummaryEntityReference(undefined);
            setNotesEntityReference({
                deleted: false,
                eventId: '',
                id: row.identity.id,
                type: 'ShortlistLineItem',
                uriPrefix: 'lineItems'
            });
            setPanelTitle(`Notes for ${row['name']}`);
        },
        (row) => {
            setSelectedItems([{ ...row.itemReference, shortlistLineItemId: row.identity.id }]);
            setFilter([{ field: 'identity', value: { values: row.itemReference.id } }]);
            setOpenInviteModal(true);
        },
        !!(resourceData && productionData && canInvite),
        (row) => {
            setNotesEntityReference(undefined);
            setSummaryEntityReference({
                deleted: false,
                eventId: '',
                id: row.identity.id,
                type: 'ShortlistLineItem',
                uriPrefix: 'lineItems'
            });
        }
    );

    const rows: LineItemRows[] = defineShortlistLineItemRows(
        viewState,
        useI18n('lineItems.availability.availableFrom')
    );

    const isRowSelectable = React.useCallback(
        (params: GridRowParams) => {
            if (
                (!params.row?.isPerson && !params.row?.isOrganization) ||
                (params.row?.inquiryStatus && inquiryStatus[params.row?.inquiryStatus]?.disabled)
            )
                return false;
            if (rowSelectionModel.includes(params.id)) return true;
            return true;
        },
        [rowSelectionModel]
    );

    const deleteLineItem = () => {
        deleteShortlistLineItem({
            shortlistId,
            lineItemId: rows[lineitemToDelete]?.identity.id
        })
            .then((res: any) => {
                if (!res.data.deleteLineItem.errors) {
                    setViewState((prevVal) => {
                        return deleteItemFromState(rows[lineitemToDelete]?.identity.id, prevVal);
                    });
                    handleShortlistLineItemResponse({ variant: 'success', query: 'delete' });
                } else {
                    handleShortlistLineItemResponse({ variant: 'error', query: 'delete' });
                }
            })
            .finally(() => {
                setDeleteConfirmationOpen(false);
            });
    };

    const sendingInquiryOnSuccess = (inquiriesInput) => {
        setViewState((prevVal) => {
            return inquiriesInputToShortlist(inquiriesInput, prevVal);
        });

        setRowSelectionModel([]);
        setOpenInviteModal(false);
    };

    return (
        <WithSidePanel
            visibleSidePanelId={notesEntityReference ? 'notes' : summaryEntityReference ? 'quick-insights' : undefined}
            panels={panels}
            expandView={expandView}
            leftToolbar={undefined}
            hideHeader={true}
            panelBoxSx={{ border: `1px solid ${theme.palette.divider}` }}
        >
            <V sx={{ height: '100%', minWidth: '1px', position: 'relative' }}>
                <DataGrid
                    id={'shortlist-lineitems'}
                    loading={isLineItemDeleting}
                    feature={'Shortlist'}
                    addTitle={<I18n token="shortlist.line.item.add" />}
                    rowCount={rows?.length || 0}
                    rows={rows ? rows : []}
                    clickableColumn={'name'}
                    columns={columns}
                    toolbar={{
                        add: true,
                        custom:
                            resourceData && productionData && canInvite
                                ? [
                                      <InquiryButton
                                          rows={rows}
                                          rowSelectionModel={rowSelectionModel}
                                          setFilter={setFilter}
                                          setOpenInviteModal={setOpenInviteModal}
                                          showInviteButton={showInviteButton}
                                          setSelectedItems={setSelectedItems}
                                          setShowInviteButton={setShowInviteButton}
                                      />
                                  ]
                                : []
                    }}
                    onAdd={() => {
                        setIsOpen(true);
                        setLineItemIndex(undefined);
                    }}
                    onEdit={(index) => {
                        setIsOpen(true);
                        setLineItemIndex(index);
                    }}
                    onDelete={(lineItemId: string) => {
                        setLineItemToDelete(lineItemId);
                        setDeleteConfirmationOpen(true);
                    }}
                    onDetails={(item: string, val) => {
                        if (!isMarketplace) {
                            setIsMarketplaceOpen((prev) => {
                                const newState = !prev ? 'marketplace' : null;
                                changeSearch({ marketplaceActive: newState });
                                return newState;
                            });
                        }
                        if (selectedItem === val.itemReference?.id && isTypeCrewOrCast) {
                            personDetailsRefetch();
                        } else {
                            organizationDetailsRefetch();
                        }
                        setSelectedShortlistRowId(val.itemReference?.id);
                    }}
                    disablePagination
                    checkboxSelection={!!(resourceData && productionData && canInvite)}
                    disableRowSelectionOnClick
                    isRowSelectable={isRowSelectable}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={setRowSelectionModel}
                    asIsColumns={['icons']}
                />
            </V>

            {isOpen && (
                <ShortlistLineItemEditor
                    shortlistId={shortlistId}
                    isEditorOpen={true}
                    setIsEditorOpen={setIsOpen}
                    lineItem={rows?.[lineItemIndex]}
                    viewState={viewState}
                    resourceData={resourceData}
                    onSuccess={(lineItemEditState, lineItemId, note) => {
                        setViewState((prevVal) => {
                            return lineItemInputToShortlist(lineItemEditState, lineItemId, prevVal, note);
                        });
                        setIsOpen(false);
                    }}
                />
            )}

            {openInviteModal && canInvite && isTypeCrewOrCast && (
                <>
                    <InquiryForm
                        type={PEOPLE_TYPE}
                        toInquiryInput={toPersonInquiryInput}
                        validationRules={personValidationRules}
                        entityDetails={inviteeSearchData?.search?.hits}
                        isEntityLoading={isSearchLoading}
                        data={
                            {
                                people: selectedItems,
                                role: viewState?.roles?.[0],
                                ...commonInquiryProductionData
                            } as PersonInviteFormData
                        }
                        {...commonProps}
                    />
                </>
            )}

            {openInviteModal && canInvite && !isTypeCrewOrCast && selectedItems.length && (
                <>
                    <InquiryForm
                        type={ORGANIZATION_TYPE}
                        toInquiryInput={toOrgInquiryInput}
                        validationRules={organizationValidationRules}
                        entityDetails={inviteeSearchData?.search?.hits}
                        isEntityLoading={isSearchLoading}
                        data={
                            {
                                organizations: selectedItems,
                                activities: [],
                                resourceNote: { ...resourceNote, text: '' },
                                ...commonInquiryProductionData
                            } as OrganizationInviteFormData
                        }
                        {...commonProps}
                    />
                </>
            )}

            <Confirm
                open={deleteConfirmationOpen}
                title={<I18n token="delete.confirm.title" />}
                okLabelI18n="delete.confirm.title"
                body={
                    <Typography>
                        <I18n token="delete.confirm.message" />
                    </Typography>
                }
                onConfirm={deleteLineItem}
                onCancel={() => {
                    setDeleteConfirmationOpen(false);
                }}
            />
        </WithSidePanel>
    );
};
