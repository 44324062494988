import React, { useEffect, useState } from 'react';
import { ValidationState } from '../form/Validator';
import OkCancel from '../components/OkCancel';
import { logIn, LoginRequest, respondToChallenge } from './api';
import { AuthFormHeader } from './AccountLinks';
import { useI18n } from '../i18n/I18n';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import Challenge from './Challenge';
import { enqueueSnackbar } from '../components/Toast';
import { emailObfuscation } from '../utils/commonUtils';

export default ({ username = '', setBearer }) => {
    const [challenge, setChallenge] = useState();
    const [session, setSession] = useState();
    const [customChallengeCounter, setCustomChallengeCounter] = useState(0);
    const [validationState, setValidationState] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });
    const [values, setValues] = useState<LoginRequest>({
        username: username,
        isCustom: true
    });

    const [isBusy, setIsBusy] = useState(false);

    const login = () => {
        setIsBusy(true);
        logIn(values)
            .then((res) => {
                if (res['challenge']) {
                    setChallenge(res['challenge']);
                    setSession(res['session']);
                }
                enqueueSnackbar(`Email sent`, { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(`Failed to send email: ${err.message}`, { variant: 'error' });
            })
            .finally(() => {
                setCustomChallengeCounter(0);
                setIsBusy(false);
            });
    };

    const submit = (values) => {
        setIsBusy(true);
        respondToChallenge({
            ...values,
            challenge,
            session
        })
            .then((res) => {
                if (res['challenge']) {
                    setChallenge(res['challenge']);
                    setSession(res['session']);
                    if (res['challenge'] === 'CUSTOM_CHALLENGE') {
                        setCustomChallengeCounter(customChallengeCounter + 1);
                    }
                    setIsBusy(false);
                } else {
                    setBearer(res['idToken']);
                }
            })
            .catch((err) => {
                setIsBusy(false);
                if (customChallengeCounter === 0) {
                    enqueueSnackbar(`Failed to verify identity: ${err.message}`, { variant: 'error' });
                } else {
                    setCustomChallengeCounter(customChallengeCounter + 1);
                }
            });
    };

    const cancel = () => {
        setChallenge(undefined);
        setSession(undefined);
    };

    useEffect(() => {
        if (customChallengeCounter > 0 && customChallengeCounter <= 2) {
            setValidationState({
                isValid: false,
                errors: { secretCode: `Incorrect code. Attempt (${customChallengeCounter}/3)` }
            });
        } else if (customChallengeCounter > 2) {
            setValidationState({
                isValid: false,
                errors: {
                    secretCode: useI18n('dialog.new.code')
                }
            });
        } else {
            setValidationState({
                isValid: false,
                errors: {}
            });
        }
    }, [customChallengeCounter]);

    const title = useI18n('dialog.verify.identity');
    const codeMessage = useI18n('account.email.code');
    return !challenge ? (
        <Container key="login" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Paper
                    sx={{ maxWidth: '400px', p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <FormContainer>
                        <FormItem>
                            <AuthFormHeader title={title} />
                        </FormItem>
                        <FormItem>
                            <Typography fontSize="small">{`${codeMessage} ${emailObfuscation(username)}`}</Typography>
                        </FormItem>
                        <FormItem>
                            <OkCancel
                                isBusy={isBusy}
                                okLabelI18n="dialog.send.code"
                                cancelLabelI18n="dialog.cancel"
                                onCancel={undefined}
                                onOk={login}
                                isOkDisabled={false}
                            />
                        </FormItem>
                    </FormContainer>
                </Paper>
            </Box>
        </Container>
    ) : (
        <Challenge
            challenge={challenge}
            username={values.username}
            isBusy={isBusy}
            onCancel={cancel}
            onSubmit={submit}
            onResend={() => {
                login();
            }}
            pageTitle={'Enter code'}
            validationState={validationState}
        />
    );
};
