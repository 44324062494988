import { Chip, Tooltip } from '@mui/material';
import {
    GridActionsCellItemProps,
    GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
    GridActionsCellItem
} from '@mui/x-data-grid-premium';
import React from 'react';
import { ReactElement } from 'react';
import { AllPermissions, getRowPermissions } from '../../../auth/api';
import I18n, { useI18n } from '../../../i18n/I18n';
import { Icons } from '../../../icons/Icons';
import { useStyling } from '../../../Theme';

export const getDataGridActions = ({
    params,
    featurePermissions,
    onActivateOrInactivateHandle,
    rowDetails,
    editHandlers,
    deleteHandlers,
    copyHandlers,
    customActions
}) => {
    const { isMobile } = useStyling();
    const saveLabel = useI18n('dialog.save');
    const cancelLabel = useI18n('dialog.cancel');
    const editLabel = useI18n('dialog.edit');
    const copyLabel = useI18n('dialog.copy');
    const deleteLabel = useI18n('dialog.delete');

    const isInEditMode = !!rowDetails.activeRow;
    const rowPermissions = getRowPermissions(params.row.security);
    const actions: ReactElement<GridActionsCellItemProps>[] = [];

    if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
        return [];
    }

    const addAction = (condition: boolean, props: GridActionsCellItemProps) => {
        if (condition) {
            actions.push(<GridActionsCellItem {...props} />);
        }
    };

    if (isInEditMode) {
        // If editing, only add actions for row being edited.
        addAction(params.id === rowDetails.activeRow.id, {
            showInMenu: isMobile,
            icon: <Icons.Check />,
            label: saveLabel,
            onClick: () => editHandlers.handleEditCommit(params.id),
            color: 'inherit',
            disabled:
                typeof rowDetails?.isRowValid === 'function' ? !rowDetails.isRowValid(rowDetails.activeRow.id) : false
        });
        addAction(params.id === rowDetails.activeRow.id, {
            showInMenu: isMobile,
            icon: <Icons.Close />,
            label: cancelLabel,
            className: 'textPrimary',
            onClick: () => editHandlers.handleEditCancel(params.id),
            color: 'inherit'
        });
    } else {
        // TODO inline editing vs details editing, what are we doing here?
        addAction(
            rowPermissions[AllPermissions.Edit] &&
                featurePermissions[AllPermissions.Manage] &&
                (editHandlers.onEdit || editHandlers.enableInlineEditing),
            {
                showInMenu: isMobile,
                icon: <Icons.Edit fontSize={'small'} />,
                label: editLabel,
                onClick: () =>
                    editHandlers.enableInlineEditing
                        ? editHandlers.handleEditClick(params.id)
                        : editHandlers.onEdit(params.id, params.row)
            }
        );

        addAction(
            rowPermissions[AllPermissions.Edit] && featurePermissions[AllPermissions.Manage] && copyHandlers.onCopy,
            {
                showInMenu: isMobile,
                icon: (
                    <Tooltip title={copyHandlers.copyTooltipTitle || ''}>
                        <Icons.ContentCopy fontSize={'small'} />
                    </Tooltip>
                ),
                label: copyLabel,
                onClick: () => copyHandlers.onCopy(params.row)
            }
        );

        addAction(
            rowPermissions[AllPermissions.Delete] &&
                featurePermissions[AllPermissions.Manage] &&
                (deleteHandlers.onDelete || deleteHandlers.onDeleteHandle),
            {
                showInMenu: isMobile,
                icon: <Icons.Delete fontSize={'small'} />,
                label: deleteLabel,
                onClick: () => deleteHandlers.handleDeleteClick(params.row)
            }
        );

        addAction(
            (onActivateOrInactivateHandle && rowPermissions[AllPermissions.Inactivate]) ||
                rowPermissions[AllPermissions.Activate],
            {
                showInMenu: isMobile,
                icon: (
                    <Chip
                        className="clickable"
                        size="small"
                        label={
                            <I18n
                                token={`action.${rowPermissions[AllPermissions.Inactivate] ? 'inactive' : 'active'}`}
                            />
                        }
                        variant={'outlined'}
                    />
                ),
                label: '', // mandatory prop, but we don't need the label
                sx: {
                    justifyContent: 'center',
                    '& .MuiChip-root': {
                        width: isMobile ? '110px' : '100%'
                    }
                },
                onClick: () =>
                    onActivateOrInactivateHandle(
                        params.row,
                        rowPermissions[AllPermissions.Inactivate],
                        rowPermissions[AllPermissions.Activate]
                    )
            }
        );

        customActions?.forEach((action) => {
            actions.push(action(params));
        });
    }

    return actions;
};
