import { RouteType } from "./common/nav/AppRoute";
import { AppDefinition, getApplicationById } from "./common/nav/apps";
import { FEATURE_ROUTES } from "./featureRoutes";

export const getAppRoutes = (
    currentApp: string,
    appFeatureConfig: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[]
) => {

    const app = getApplicationById(currentApp);
    const appBasePath = app.home;

    return appFeatureConfig.flatMap(({ feature, subFeatures }) => {
        const featureGroup = FEATURE_ROUTES[feature];

        return subFeatures.flatMap((subFeature) => {
            const combinedRoutes = featureGroup[subFeature];

            return combinedRoutes.map((route) => {
                const basicRouteDetails = getRouteDetailForEachItem(route, appBasePath, app, feature);
                const items = route.items?.length && routeAdditionalItems(route.items, appBasePath, app, feature);

                if (items) {
                    return {
                        ...basicRouteDetails,
                        items
                    };
                }
                return basicRouteDetails;
            });
        });
    });
};

const routeAdditionalItems = (
    items: RouteType[],
    appBasePath: string,
    app: AppDefinition,
    routeFeature: keyof typeof FEATURE_ROUTES
) => {
    return items.map((item) => getRouteDetailForEachItem(item, appBasePath, app, routeFeature));
};

const getRouteDetailForEachItem = (
    item: RouteType,
    appBasePath: string,
    app: AppDefinition,
    routeFeature: keyof typeof FEATURE_ROUTES
) => {
    const redirect = item.redirect ? `${appBasePath}` : undefined;
    const path = item.path ? `${appBasePath}${item.path}` : undefined;
    const link = item.link ? `${appBasePath}${item.link}` : undefined;
    const feature = item.feature || routeFeature;

    return {
        ...item,
        app,
        feature,
        path,
        component: item.component,
        link,
        redirect
    } as RouteType;
};
