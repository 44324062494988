import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { cloneDeep, isEmpty, isNil, omit, set } from 'lodash';
import {
    ActorInfo,
    CostLine,
    CostSummary,
    OpportunityLineItem,
    DateRange,
    Money,
    ProductBasedAdjustmentSettingInfo,
    ShareAccess
} from 'sr-types/lib/opportunity/v1/graphql';
import { ResourceInput } from 'sr-types/lib/production/v1/graphql';
import { SCHEDULE_FIELDS } from '../../admin/templates/resources/resources';
import { authLink } from '../../common/auth/api';
import { Location, LOCATION_FIELDS } from '../../common/components/location';
import { Contact, Identity, Security } from '../../common/components/types';
import { constants } from '../../common/constants';
import {
    CalculationTypeFragment,
    ContactInfoFragment,
    CostSummaryFragment,
    DateRangeFragment,
    MoneyFragment,
    ReferenceFragment
} from '../../common/list/fragments';
import { Reference } from '../../common/reference/reference';
import { PackageType } from '../product/product';
import Measurement from '../../common/form/widgets/Measurement';
import { getL10nDef } from '../../common/i18n/localization';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/opportunity/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const clientWithoutAuth = new ApolloClient({
    link: createHttpLink({
        uri: '/opportunity/v1/'
    }),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export type Opportunity = {
    id: string;
    name: string;
    start: string;
    end: string;
    startTime: string;
    endTime: string;
    durationType: string;
    status: string;
    organization: object;
    contacts: Contact[];
    production: object;
    updatedOn: string;
    opportunityType: string;
    createdBy: ActorInfo;
};

export type Measurement = {
    value: string;
    unit: string;
};

export type LineItem = OpportunityLineItem & {
    createReservation?: boolean;
    isModified?: boolean;
    dontCreateReservation?: boolean;
};

export const EMPTY_LINE_ITEM: LineItem = {
    identity: {
        // Need some kind of id to be able to find this line item. We delete this before saving.
        id: constants.newEntityId
    },
    name: '',
    groupType: 'Item',
    lineItemType: 'Opportunity',
    lineItemQuantity: 0,
    unit: undefined,
    dateRange: {
        dateTimeDuration: {
            unit: '',
            value: 0
        },
        durationSpecified: false,
        start: '',
        startTime: '',
        end: '',
        endTime: ''
    },
    offerTypes: [],
    product: {
        reference: undefined,
        label: ''
    },
    primary: false,
    packageType: PackageType.Standard,
    associatedItems: [],
    location: undefined,
    availableLocations: undefined,
    sequence: undefined
    // reservation: undefined
};

export const lineItemToState = (lineItem) => {
    return {
        identity: lineItem.identity,
        name: lineItem.name,
        dateRange: {
            dateTimeDuration: {
                unit: 'day',
                value: 1
            },
            durationSpecified: true,
            end: '',
            endTime: '',
            start: '',
            startTime: ''
        },
        primary: lineItem.primary,
        sequence: lineItem.sequence,
        product: lineItem.product,
        parent: lineItem.parent,
        lineItemType: lineItem.lineItemType,
        groupType: lineItem.groupType,
        lineItemQuantity: lineItem.lineItemQuantity,
        associatedItems: lineItem.associatedItems,
        needsDetailedSchedule: lineItem.needsDetailedSchedule,
        shouldSequenceSchedule: lineItem.shouldSequenceSchedule,
        location: lineItem.location || {},
        availableLocations: lineItem.availableLocations || [],
        allowAdditionalLocations: lineItem.allowAdditionalLocations,
        createReservation: true,
        calendar: lineItem.calendar,
        copyOf: lineItem?.copyOf,
        attributes: lineItem?.attributes,
        associatedFieldDefinitionReferences: lineItem.associatedFieldDefinitionReferences
    };
};

// export type Period = {
//     duration: string;
//     durationType: string;
//     end: string;
//     endTime: string;
//     start: string;
//     startTime: string;
// };

export type OpportunityDetailsType = {
    identity: Identity;
    templateId: string;
    name: string;
    production: {
        genre: string;
        name: string;
        productionReference: Reference;
        type: string;
    };
    lineItems: Array<LineItem>;
    primaryItem: Reference;
    opportunityPeriod: DateRange;
    organization: {
        name: string;
        organizationReference: Reference;
    };
    status: string;
    updatedOn: string;
    contacts: Contact[];
    summary: {
        about: string;
    };
    createdBy: ActorInfo;
    opportunityType: string;
    reference?: Reference;
    createdOn?: string;
    sharedWithAccounts?: ShareAccess[];
    security: Security;
    ownerOrganizationName: string;
    ownerOrganizationId: string;
    guests: Contact[];
    state: string;
    productionRequirements: (ResourceInput & { security?: Security })[];
    costSummary?: CostSummary;
    orderedTotal?: Money;
    currency: string;
    currencyIsTemp: boolean;
    pendingAmount?: Money;
    opportunityLocations?: Array<Reference>;
    discountSetting: {
        name: string;
        description: string;
        percent: number;
        amount: Money;
    };
    productBasedDiscountSetting: ProductBasedAdjustmentSettingInfo[];
    discountLevel: string;
    permissions: any;
    isOpportunityModified?: boolean;
    lineItemsAreUnsaved?: boolean;
    commitPending?: boolean;
    createdFrom?: Reference;
    copyOf?: Reference;
};

export const saveQuery: DocumentNode = gql`
    mutation SaveOpportunity($input: OpportunityInput!) {
        saveOpportunity(input: $input) {
            id
            errors {
                field
                message
            }
            reference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export const saveBookingLineItemsQuery: DocumentNode = gql`
    mutation SaveOpportunityLineItems($opportunityId: ID!, $input: [OpportunityLineItemInput]) {
        saveOpportunityLineItems(opportunityId: $opportunityId, input: $input) {
            id
            errors {
                field
                message
            }
            reference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export const saveOpportunityLineItemQuery: DocumentNode = gql`
    mutation SaveOpportunityLineItem($opportunityId: ID!, $input: OpportunityLineItemInput!) {
        saveOpportunityLineItem(opportunityId: $opportunityId, input: $input) {
            id
            errors {
                field
                message
            }
            reference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export const deleteOpportunityLineItemQuery: DocumentNode = gql`
    mutation DeleteOpportunityLineItem($opportunityId: ID!, $opportunityLineItemId: ID!) {
        deleteOpportunityLineItem(opportunityId: $opportunityId, opportunityLineItemId: $opportunityLineItemId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const markOpportunityLineItemPrimaryQuery: DocumentNode = gql`
    mutation MarkOpportunityLineItemPrimary($opportunityId: ID!, $opportunityLineItemId: ID!) {
        markOpportunityLineItemPrimary(opportunityId: $opportunityId, opportunityLineItemId: $opportunityLineItemId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteOpportunity($id: ID!) {
        deleteOpportunity(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const shareQuery: DocumentNode = gql`
    mutation Share($input: ShareRequest!) {
        share(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const MeasurementFragment = gql`
    fragment Measurement on Measurement {
        unit
        value
    }
`;

export const AttributesFragment = gql`
    fragment Attributes on Field {
        name
        fieldType
        value
        fieldDefinitionReference {
            ...Reference
        }
    }
`;

export const CalculationInfoFragment = gql`
    fragment CalculationInfo on CalculationInfo {
        autoCalculate
        baseTotal {
            ...Money
        }
        calculationType {
            ...CalculationType
        }
        itemPrice {
            ...Money
        }
        itemPercent
        itemQuantity
        leaseDuration {
            ...Measurement
        }
        attributes {
            ...Attributes
        }
        associatedFieldDefinitionReferences {
            ...Reference
        }
    }
    ${MoneyFragment}
    ${MeasurementFragment}
    ${CalculationTypeFragment}
    ${AttributesFragment}
`;

export const LineCalculationInfoFragment = gql`
    fragment LineCalculationInfo on LineCalculationInfo {
        adjustmentCategory
        adjustmentName
        calculationInfo {
            ...CalculationInfo
        }
        lineType
    }
    ${CalculationInfoFragment}
`;

export const CostLineFragment = gql`
    fragment CostLine on CostLine {
        identity {
            id
            preSave
        }
        copyOf {
            ...Reference
        }
        createdFrom {
            ...Reference
        }
        adjustments {
            ...LineCalculationInfo
        }
        entityReference {
            ...Reference
        }
        lineCalculationInfo {
            ...LineCalculationInfo
        }
        lineCurrency
        lineTotal {
            ...Money
        }
        rate {
            ...Reference
        }
        costSummary {
            ...CostSummary
        }
        rateName
    }
    ${MoneyFragment}
    ${LineCalculationInfoFragment}
    ${CostSummaryFragment}
`;

export const saveInquiryResponse: DocumentNode = gql`
    mutation SaveInquiryResponse($inquiryResponse: InquiryResponseInput!) {
        saveInquiryResponse(inquiryResponse: $inquiryResponse) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const detailsQuery: DocumentNode = gql`
    query Opportunity($id: ID!) {
        opportunity(id: $id) {
            identity {
                id
            }
            templateId
            name
            opportunityPeriod {
                ...DateRange
            }
            status
            state
            organization {
                name
                organizationReference {
                    ...Reference
                }
            }
            opportunityLocations {
                ...Reference
            }
            ownerOrganizationId
            ownerOrganizationName
            contacts {
                name {
                    firstName
                    lastName
                }
                isPrimary
                contactInfo {
                    email {
                        address
                        typeString
                        verified
                    }
                    phone {
                        number
                        typeString
                    }
                }
                role
                personReference {
                    ...Reference
                }
            }
            production {
                name
                type
                genre
                productionReference {
                    ...Reference
                }
            }
            summary {
                about
            }
            lineItems {
                identity {
                    id
                }
                name
                orderStatus
                location {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                availableLocations {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                lineItemType
                groupType
                needsDetailedSchedule
                shouldSequenceSchedule
                allowAdditionalLocations
                lineItemQuantity
                lineAllocations {
                    startDate
                    endDate
                    periodUnit
                    value
                    unit
                }
                calendar {
                    ...Reference
                }
                parent {
                    reference {
                        id
                    }
                }
                unit {
                    value
                    unit
                }
                dateRange {
                    ...DateRange
                    timezone
                }
                offerTypes
                product {
                    reference {
                        ...Reference
                    }
                    label
                }
                productType
                productSubType
                reservation {
                    reference {
                        ...Reference
                    }
                    label
                }
                showReservationGuests
                reservationGuestsAvailable
                primary
                packageType
                associatedItems {
                    reference {
                        ...Reference
                    }
                    label
                }
                costLine {
                    ...CostLine
                }
                attributes {
                    ...Attributes
                }
                associatedFieldDefinitionReferences {
                    ...Reference
                }
                copyOf {
                    ...Reference
                }
                createdFrom {
                    ...Reference
                }
            }
            reference {
                ...Reference
            }
            createdOn
            updatedOn
            createdBy {
                name
                email
            }
            opportunityType
            orderedTotal {
                ...Money
            }
            costSummary {
                ...CostSummary
            }
            pendingAmount {
                ...Money
            }
            discountSetting {
                amount {
                    ...Money
                }
                name
                description
                percent
            }
            productBasedDiscountSetting {
                category
                subType
                type
                amount {
                    ...Money
                }
                percent
            }
            discountLevel
            currency
            sharedWithAccounts {
                actor {
                    id
                    name
                    email
                }
                action
            }
            security {
                allowedActions
            }

            productionRequirements {
                identity {
                    id
                }
                security {
                    allowedActions
                }
                name
                type
                subType
                role
                status
                resourceCategory
                budgetCategory
                locations {
                    ...LocationFields
                }
                summary {
                    about
                }
                resourceSchedule {
                    ...ScheduleFields
                }
                attributes {
                    name
                    fieldType
                    value
                    fieldDefinitionReference {
                        id
                        type
                        uriPrefix
                        eventId
                        deleted
                    }
                }
                associatedFieldDefinitionReferences {
                    id
                    type
                    uriPrefix
                    eventId
                    deleted
                }
            }
            createdFrom {
                ...Reference
            }
            copyOf {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
    ${DateRangeFragment}
    ${CostLineFragment}
    ${LOCATION_FIELDS}
    ${SCHEDULE_FIELDS}
    ${MoneyFragment}
    ${AttributesFragment}
    ${CostSummaryFragment}
`;

export const copyBooking: DocumentNode = gql`
    query CopyBooking($bookingId: ID!) {
        copyBooking(bookingId: $bookingId) {
            identity {
                id
                preSave
            }
            templateId
            name
            opportunityPeriod {
                ...DateRange
            }
            status
            state
            copyOf {
                ...Reference
            }
            createdFrom {
                ...Reference
            }
            organization {
                name
                organizationReference {
                    ...Reference
                }
            }
            opportunityLocations {
                ...Reference
            }
            ownerOrganizationId
            ownerOrganizationName
            contacts {
                name {
                    firstName
                    lastName
                }
                isPrimary
                contactInfo {
                    email {
                        address
                        typeString
                        verified
                    }
                    phone {
                        number
                        typeString
                    }
                }
                role
                personReference {
                    ...Reference
                }
            }
            production {
                name
                type
                genre
                productionReference {
                    ...Reference
                }
            }
            summary {
                about
            }
            lineItems {
                identity {
                    id
                    preSave
                }
                copyOf {
                    ...Reference
                }
                createdFrom {
                    ...Reference
                }
                name
                orderStatus
                location {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                availableLocations {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                lineItemType
                groupType
                needsDetailedSchedule
                shouldSequenceSchedule
                allowAdditionalLocations
                lineItemQuantity
                lineAllocations {
                    startDate
                    endDate
                    periodUnit
                    value
                    unit
                }
                calendar {
                    ...Reference
                }
                parent {
                    reference {
                        id
                    }
                }
                unit {
                    value
                    unit
                }
                dateRange {
                    ...DateRange
                    timezone
                }
                offerTypes
                product {
                    reference {
                        ...Reference
                    }
                    label
                }
                productType
                productSubType
                reservation {
                    reference {
                        ...Reference
                    }
                    label
                }
                showReservationGuests
                reservationGuestsAvailable
                primary
                packageType
                associatedItems {
                    reference {
                        ...Reference
                    }
                    label
                }
                costLine {
                    ...CostLine
                }
                attributes {
                    ...Attributes
                }
                associatedFieldDefinitionReferences {
                    ...Reference
                }
            }
            reference {
                ...Reference
            }
            createdOn
            updatedOn
            createdBy {
                name
                email
            }
            opportunityType
            orderedTotal {
                ...Money
            }
            costSummary {
                ...CostSummary
            }
            pendingAmount {
                ...Money
            }
            discountSetting {
                amount {
                    ...Money
                }
                name
                description
                percent
            }
            productBasedDiscountSetting {
                category
                subType
                type
                amount {
                    ...Money
                }
                percent
            }
            discountLevel
            currency
            sharedWithAccounts {
                actor {
                    id
                    name
                    email
                }
                action
            }
            security {
                allowedActions
            }
            productionRequirements {
                identity {
                    id
                }
                security {
                    allowedActions
                }
                name
                type
                role
                status
                resourceCategory
                budgetCategory
                locations {
                    ...LocationFields
                }
                summary {
                    about
                }
                resourceSchedule {
                    ...ScheduleFields
                }
                attributes {
                    name
                    fieldType
                    value
                    fieldDefinitionReference {
                        id
                        type
                        uriPrefix
                        eventId
                        deleted
                    }
                }
                associatedFieldDefinitionReferences {
                    id
                    type
                    uriPrefix
                    eventId
                    deleted
                }
            }
            createdFrom {
                ...Reference
            }
        }
    }

    ${ReferenceFragment}
    ${DateRangeFragment}
    ${CostLineFragment}
    ${LOCATION_FIELDS}
    ${SCHEDULE_FIELDS}
    ${MoneyFragment}
    ${AttributesFragment}
    ${CostSummaryFragment}
`;

export const draftBookingFromLead: DocumentNode = gql`
    query DraftBookingFromLead($opportunityId: ID!) {
        draftBookingFromLead(opportunityId: $opportunityId) {
            identity {
                id
                preSave
            }
            templateId
            name
            opportunityPeriod {
                ...DateRange
            }
            status
            state
            copyOf {
                ...Reference
            }
            createdFrom {
                ...Reference
            }
            organization {
                name
                organizationReference {
                    ...Reference
                }
            }
            opportunityLocations {
                ...Reference
            }
            ownerOrganizationId
            ownerOrganizationName
            contacts {
                name {
                    firstName
                    lastName
                }
                isPrimary
                contactInfo {
                    email {
                        address
                        typeString
                        verified
                    }
                    phone {
                        number
                        typeString
                    }
                }
                role
                personReference {
                    ...Reference
                }
            }
            production {
                name
                type
                genre
                productionReference {
                    ...Reference
                }
            }
            summary {
                about
            }
            lineItems {
                identity {
                    id
                    preSave
                }
            }
            reference {
                ...Reference
            }
            createdOn
            updatedOn
            createdBy {
                name
                email
            }
            opportunityType
            orderedTotal {
                ...Money
            }
            costSummary {
                ...CostSummary
            }
            pendingAmount {
                ...Money
            }
            discountSetting {
                amount {
                    ...Money
                }
                name
                description
                percent
            }
            productBasedDiscountSetting {
                category
                subType
                type
                amount {
                    ...Money
                }
                percent
            }
            discountLevel
            currency
            sharedWithAccounts {
                actor {
                    id
                    name
                    email
                }
                action
            }
            security {
                allowedActions
            }
            productionRequirements {
                identity {
                    id
                }
                security {
                    allowedActions
                }
                name
                type
                role
                status
                resourceCategory
                budgetCategory
                locations {
                    ...LocationFields
                }
                summary {
                    about
                }
                resourceSchedule {
                    ...ScheduleFields
                }
                attributes {
                    name
                    fieldType
                    value
                    fieldDefinitionReference {
                        id
                        type
                        uriPrefix
                        eventId
                        deleted
                    }
                }
                associatedFieldDefinitionReferences {
                    id
                    type
                    uriPrefix
                    eventId
                    deleted
                }
            }
            createdFrom {
                ...Reference
            }
        }
    }

    ${ReferenceFragment}
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
    ${SCHEDULE_FIELDS}
    ${MoneyFragment}
    ${CostSummaryFragment}
`;

export const draftScenario: DocumentNode = gql`
    query DraftScenario($id: ID!) {
        draftScenario(id: $id) {
            identity {
                id
                preSave
            }
            templateId
            name
            opportunityPeriod {
                ...DateRange
            }
            status
            state
            copyOf {
                ...Reference
            }
            createdFrom {
                ...Reference
            }
            organization {
                name
                organizationReference {
                    ...Reference
                }
            }
            opportunityLocations {
                ...Reference
            }
            ownerOrganizationId
            ownerOrganizationName
            contacts {
                name {
                    firstName
                    lastName
                }
                isPrimary
                contactInfo {
                    ...ContactInfo
                }
                role
                personReference {
                    ...Reference
                }
            }
            production {
                name
                type
                genre
                productionReference {
                    ...Reference
                }
            }
            summary {
                about
            }
            lineItems {
                identity {
                    id
                    preSave
                }
                copyOf {
                    ...Reference
                }
                createdFrom {
                    ...Reference
                }
                name
                orderStatus
                location {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                availableLocations {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                lineItemType
                groupType
                needsDetailedSchedule
                shouldSequenceSchedule
                allowAdditionalLocations
                lineItemQuantity
                lineAllocations {
                    startDate
                    endDate
                    periodUnit
                    value
                    unit
                }
                calendar {
                    ...Reference
                }
                parent {
                    reference {
                        id
                    }
                }
                unit {
                    value
                    unit
                }
                dateRange {
                    ...DateRange
                    timezone
                }
                offerTypes
                product {
                    reference {
                        ...Reference
                    }
                    label
                }
                productType
                productSubType
                reservation {
                    reference {
                        ...Reference
                    }
                    label
                }
                showReservationGuests
                reservationGuestsAvailable
                primary
                packageType
                associatedItems {
                    reference {
                        ...Reference
                    }
                    label
                }
                costLine {
                    ...CostLine
                }
                attributes {
                    ...Attributes
                }
                associatedFieldDefinitionReferences {
                    ...Reference
                }
            }
            reference {
                ...Reference
            }
            createdOn
            updatedOn
            createdBy {
                name
                email
            }
            opportunityType
            orderedTotal {
                ...Money
            }
            costSummary {
                ...CostSummary
            }
            pendingAmount {
                ...Money
            }
            discountSetting {
                amount {
                    ...Money
                }
                name
                description
                percent
            }
            productBasedDiscountSetting {
                category
                subType
                type
                amount {
                    ...Money
                }
                percent
            }
            discountLevel
            currency
            sharedWithAccounts {
                actor {
                    id
                    name
                    email
                }
                action
            }
            security {
                allowedActions
            }
            productionRequirements {
                identity {
                    id
                }
                security {
                    allowedActions
                }
                name
                type
                role
                status
                resourceCategory
                budgetCategory
                locations {
                    ...LocationFields
                }
                summary {
                    about
                }
                resourceSchedule {
                    ...ScheduleFields
                }
                attributes {
                    name
                    fieldType
                    value
                    fieldDefinitionReference {
                        ...Reference
                    }
                }
                associatedFieldDefinitionReferences {
                    ...Reference
                }
            }
            createdFrom {
                ...Reference
            }
        }
    }
    ${ContactInfoFragment}
    ${ReferenceFragment}
    ${DateRangeFragment}
    ${CostLineFragment}
    ${LOCATION_FIELDS}
    ${SCHEDULE_FIELDS}
    ${MoneyFragment}
    ${AttributesFragment}
    ${CostSummaryFragment}
`;

export const bookingDetailsQuery: DocumentNode = gql`
    query DraftBookingFromTemplate($templateId: ID!) {
        draftBookingFromTemplate(templateId: $templateId) {
            draftbooking {
                identity {
                    id
                    preSave
                }
                templateId
                status
                name
                opportunityPeriod {
                    start
                    end
                    duration
                    durationType
                }
                opportunityType
            }
            draftbookingLineItems {
                identity {
                    id
                    preSave
                }
                templateId
                name
                primary
                product {
                    reference {
                        id
                    }
                    label
                }
                unit {
                    value
                    unit
                }
                dateRange {
                    ...DateRange
                }
                lineItemQuantity
                lineItemType
                packageType
                groupType
                parent {
                    reference {
                        id
                    }
                }
                associatedItems {
                    reference {
                        id
                    }
                }
                shouldSequenceSchedule
                needsDetailedSchedule
                location {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                availableLocations {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                allowAdditionalLocations
                calendar {
                    ...Reference
                }
            }
        }
    }
    ${DateRangeFragment}
    ${ReferenceFragment}
    ${LOCATION_FIELDS}
`;

export const draftbookingLineItemProductQuery: DocumentNode = gql`
    query DraftLineItemsForProduct($productId: ID!, $isPrimary: Boolean) {
        draftLineItemsForProduct(productId: $productId, isPrimary: $isPrimary) {
            identity {
                id
                preSave
            }
            name
            dateRange {
                ...DateRange
            }
            lineItemType
            groupType
            lineItemQuantity
            primary
            sequence
            packageType
            offerTypes
            product {
                reference {
                    id
                }
                label
            }
            parent {
                reference {
                    id
                }
            }
            associatedItems {
                reference {
                    id
                }
            }
            dependencies {
                from {
                    id
                }
                to {
                    id
                }
                lag
                lagUnit
                type
            }
            shouldSequenceSchedule
            needsDetailedSchedule
            location {
                ...LocationFields
                timezone {
                    id
                    name
                }
            }
            availableLocations {
                ...LocationFields
                timezone {
                    id
                    name
                }
            }
            allowAdditionalLocations
            calendar {
                ...Reference
            }
            attributes {
                ...Attributes
            }
            associatedFieldDefinitionReferences {
                ...Reference
            }
        }
    }
    ${DateRangeFragment}
    ${ReferenceFragment}
    ${LOCATION_FIELDS}
    ${AttributesFragment}
`;

export const saveBookingQuery: DocumentNode = gql`
    mutation SaveBooking($input: OpportunityInput!) {
        saveBooking(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveResourcesQuery: DocumentNode = gql`
    mutation SaveResources($opportunityId: ID!, $input: [ResourceInput]) {
        saveResources(opportunityId: $opportunityId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteResourceQuery: DocumentNode = gql`
    mutation deleteResource($opportunityId: ID!, $resourceId: ID!) {
        deleteResource(opportunityId: $opportunityId, resourceId: $resourceId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const bulkSaveQuery: DocumentNode = gql`
    mutation BulkSaveBooking($input: BulkSaveBookingRequest!) {
        bulkSaveBooking(input: $input) {
            createdCount
            updatedCount
            unprocessedCount
            results {
                id
                errors {
                    field
                    message
                }
                reference {
                    id
                }
            }
            logFile {
                id
                uriPrefix
            }
        }
    }
`;

export const confirmBookingQuery: DocumentNode = gql`
    mutation ConfirmBooking($input: BookingConfirmationInput) {
        confirmBooking(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const confirmationStatusQuery: DocumentNode = gql`
    query ConfirmationStatus($opportunityId: ID!) {
        confirmationStatus(opportunityId: $opportunityId) {
            identity {
                id
            }
            state
            workflowStatus
            confirmAction
            unconfirmedChanges
        }
    }
`;

export const inquiryStatusDetails: DocumentNode = gql`
    query InquiryStatus($id: ID!) {
        inquiryStatus(leadId: $id) {
            inquiryId
            status
            inquiryMessage {
                content
                senderInfo {
                    address
                    name
                }
            }
            inquiryFor
            requestNote
        }
    }
`;

export const rowsToBooking = (rows, colDefs) => {
    return rows.map((row) => {
        const bookingEntity = { ...row, lineItemDuration: row.lineItemDuration || 0 };
        delete bookingEntity.id;
        return bookingEntity;
    });
};

export const opportunityToState = (opportunity: OpportunityDetailsType): OpportunityDetailsType => {
    if (opportunity.production.productionReference)
        opportunity.production.productionReference.label = opportunity.production.name;
    const l10n = getL10nDef();
    opportunity.lineItems.map((li) => {
        li.createReservation = !isEmpty(li.reservation) && li.reservation.reference?.id ? true : false;
        if (li.costLine) {
            li.costLine.lineCurrency = opportunity.currency || l10n.defaultCurrency;
        }
    });
    if (opportunity.currency === '') {
        opportunity.currency = l10n.defaultCurrency;
        opportunity.currencyIsTemp = true;
    }
    if (!opportunity.discountSetting || isEmpty(opportunity.discountSetting)) {
        opportunity.discountSetting = {
            name: '',
            description: '',
            percent: undefined,
            amount: {
                currencyCode: opportunity.currency,
                nanos: undefined,
                units: undefined
            }
        };
    }
    if (isNil(opportunity.opportunityLocations)) {
        opportunity.opportunityLocations = [];
    }
    return cloneDeep(opportunity);
};

const isNumeric = (num: any) =>
    (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num as number);

export const stateToOpportunity = (state: OpportunityDetailsType): OpportunityDetailsType => {
    const organization = {
        name: state.organization.organizationReference.label,
        organizationReference: omit(state.organization.organizationReference, 'label')
    };
    const clone: OpportunityDetailsType = { ...cloneDeep(state), organization: organization };
    clone.ownerOrganizationName = state.opportunityLocations[0]?.label;
    clone.ownerOrganizationId = state.opportunityLocations[0]?.id;
    delete clone.reference;
    delete clone.createdOn;
    delete clone.updatedOn;
    delete clone.createdBy;
    delete clone.sharedWithAccounts;
    delete clone.lineItems;
    delete clone.security;
    if (clone.production.productionReference) {
        clone.production.name = clone.production.productionReference.label;
        delete clone.production.productionReference.label;
    }
    delete clone.orderedTotal;
    delete clone.costSummary;
    delete clone.currencyIsTemp;
    delete clone.primaryItem;

    if (clone.productionRequirements) {
        clone.productionRequirements.map((resource) => delete resource.security);
    }
    if (!isNumeric(clone.discountSetting?.percent)) {
        clone.discountSetting.percent = undefined;
    }
    if (!isEmpty(clone.discountSetting.amount)) {
        if (isNaN(clone.discountSetting.amount.units)) {
            clone.discountSetting.amount.units = undefined;
        }
        if (isNaN(clone.discountSetting.amount.nanos)) {
            clone.discountSetting.amount.nanos = undefined;
        }
    }
    if (clone.productBasedDiscountSetting && clone.productBasedDiscountSetting.length) {
        clone.productBasedDiscountSetting.map((productBasedDiscountSetting) => {
            if (!isNumeric(productBasedDiscountSetting.percent)) {
                productBasedDiscountSetting.percent = undefined;
            }
            if (!isEmpty(productBasedDiscountSetting.amount)) {
                if (isNaN(productBasedDiscountSetting.amount.units)) {
                    productBasedDiscountSetting.amount.units = undefined;
                }
                if (isNaN(productBasedDiscountSetting.amount.nanos)) {
                    productBasedDiscountSetting.amount.nanos = undefined;
                }
            }
        });
    }
    return clone;
};

export const saveAttachmentQuery: DocumentNode = gql`
    mutation SaveAttachments($opportunityId: ID!, $input: [AttachmentInput]!) {
        saveAttachments(input: $input, opportunityId: $opportunityId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const opportunityAttachmentDetails: DocumentNode = gql`
    query Attachments($opportunityId: String!) {
        attachments(opportunityId: $opportunityId) {
            name
            size
            type
            createdOn
            createdBy
            entityReference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export const deleteAttachmentQuery: DocumentNode = gql`
    mutation DeleteAttachments($opportunityId: ID!, $input: [AttachmentInput]) {
        deleteAttachments(opportunityId: $opportunityId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const leadsBookingDetailsQuery: DocumentNode = gql`
    query DraftBookingFromLead($opportunityId: ID!) {
        draftBookingFromLead(opportunityId: $opportunityId) {
            draftbooking {
                identity {
                    id
                    preSave
                }
                templateId
                name
                opportunityPeriod {
                    ...DateRange
                }
                status
                state
                organization {
                    name
                    organizationReference {
                        ...Reference
                    }
                }
                ownerOrganizationName
                contacts {
                    name {
                        firstName
                        lastName
                    }
                    isPrimary
                    contactInfo {
                        email {
                            address
                            typeString
                            verified
                        }
                        phone {
                            number
                            typeString
                        }
                    }
                    role
                    personReference {
                        ...Reference
                    }
                }
                production {
                    name
                    type
                    genre
                    productionReference {
                        ...Reference
                    }
                }
                summary {
                    about
                }
                lineItems {
                    identity {
                        id
                    }
                    name
                    location {
                        ...LocationFields
                        timezone {
                            id
                            name
                        }
                    }
                    availableLocations {
                        ...LocationFields
                        timezone {
                            id
                            name
                        }
                    }
                    lineItemType
                    groupType
                    needsDetailedSchedule
                    shouldSequenceSchedule
                    allowAdditionalLocations
                    lineItemQuantity
                    calendar {
                        ...Reference
                    }
                    parent {
                        reference {
                            id
                        }
                    }
                    unit {
                        value
                        unit
                    }
                    dateRange {
                        ...DateRange
                        timezone
                    }
                    offerTypes
                    product {
                        reference {
                            ...Reference
                        }
                        label
                    }
                    reservation {
                        reference {
                            ...Reference
                        }
                        label
                    }
                    showReservationGuests
                    reservationGuestsAvailable
                    primary
                    packageType
                    associatedItems {
                        reference {
                            ...Reference
                        }
                        label
                    }
                }
                reference {
                    ...Reference
                }
                createdOn
                updatedOn
                copyOf {
                    ...Reference
                }
                createdBy {
                    name
                    email
                }
                opportunityType
                sharedWithAccounts {
                    actor {
                        id
                        name
                        email
                    }
                    action
                }
                security {
                    allowedActions
                }
                productionRequirements {
                    identity {
                        id
                    }
                    name
                    type
                    role
                    status
                    resourceCategory
                    budgetCategory
                    locations {
                        ...LocationFields
                    }
                    summary {
                        about
                    }
                    attributes {
                        name
                        fieldType
                        value
                        fieldDefinitionReference {
                            id
                            type
                            uriPrefix
                            eventId
                            deleted
                        }
                    }
                }
            }
            draftbookingLineItems {
                identity {
                    id
                    preSave
                }
                templateId
                name
                primary
                product {
                    reference {
                        id
                    }
                    label
                }
                unit {
                    value
                    unit
                }
                copyOf {
                    ...Reference
                }
                dateRange {
                    ...DateRange
                }
                lineItemQuantity
                lineItemType
                packageType
                groupType
                parent {
                    reference {
                        id
                    }
                }
                associatedItems {
                    reference {
                        id
                    }
                }
                shouldSequenceSchedule
                needsDetailedSchedule
                location {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                availableLocations {
                    ...LocationFields
                    timezone {
                        id
                        name
                    }
                }
                allowAdditionalLocations
            }
        }
    }
    ${ReferenceFragment}
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
`;
