import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { authLink } from '../../../common/auth/api';
import { UserContext } from '../../../common/auth/UserContext';
import { enqueueSnackbar } from '../../../common/components/Toast';
import { constants } from '../../../common/constants';
import { useI18n } from '../../../common/i18n/I18n';
import { searchClient, slimResultsQuery } from '../../../common/list/slimQuery';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/resource/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const SCHEDULE_FIELDS = gql`
    fragment ScheduleFields on Schedule {
        autoSetDate
        dateRange {
            dateTimeDuration {
                unit
                value
            }
            duration
            durationSpecified
            durationType
            recurrenceRule {
                byDay
                byMonth
                frequency
                interval
            }
            end
            start
            endTime
            startTime
        }
    }
`;

export const saveResourceTemplateQuery: DocumentNode = gql`
    mutation SaveResourceAsTemplate($input: ResourceInput!) {
        saveResourceAsTemplate(input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const resourceTemplateDetailsQuery: DocumentNode = gql`
    query GetResourceTemplate($resourceId: ID!) {
        resourceTemplate(id: $resourceId) {
            identity {
                id
            }
            name
            type
            subType
            role
            resourceSchedule {
                ...ScheduleFields
            }
            locations {
                geoCoords {
                    lat
                    lon
                }
                address {
                    lines
                    locality
                    adminArea
                    postalCode
                    countryRegion
                    countryRegionIso2
                }
                primary
                label
            }
            status
            budgetCategory
            resourceCategory
            summary {
                headline
                about
            }
            attributes {
                name
                fieldType
                value
                fieldDefinitionReference {
                    id
                    type
                    uriPrefix
                    eventId
                    deleted
                }
            }
            associatedFieldDefinitionReferences {
                id
                type
                uriPrefix
                eventId
                deleted
            }
        }
    }
    ${SCHEDULE_FIELDS}
`;

export const resourceTemplateDeleteQuery: DocumentNode = gql`
    mutation DeleteResourceTemplate($id: ID!) {
        deleteResourceTemplate(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const useDeleteResourceTemplate = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteResourceMutation] = useMutation(resourceTemplateDeleteQuery, { client });

    const successMessage = useI18n('resource.delete.success');
    const errorMessage = useI18n('resource.delete.failure');

    const deleteResourceTemplate = (resourceId, onSuccess, onError) =>
        deleteResourceMutation({
            variables: {
                id: resourceId
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((deleteResourceResponse) => {
                if (deleteResourceResponse.data.deleteResourceTemplate.id) {
                    enqueueSnackbar(successMessage, {
                        variant: 'success'
                    });
                    onSuccess?.(deleteResourceResponse);
                } else {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    onError?.(deleteResourceResponse);
                }
            })
            .catch((err) => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error'
                });
                onError?.();
            });

    return { deleteResourceTemplate };
};

export const useResourceTemplatesQuery = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: resourceTemplatesData,
        loading: isResourceTemplatesDataLoading,
        refetch: resourceTemplatesDataRefetch
    } = useQuery(slimResultsQuery('Resource'), {
        variables: {
            filters: [
                {
                    identifier: 'template',
                    value: 'true'
                },
                {
                    identifier: 'entity',
                    value: 'Resource'
                }
            ],
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false
    });
    return { resourceTemplatesData, isResourceTemplatesDataLoading, resourceTemplatesDataRefetch };
};

export const useResourceTemplateDetails = (resourceId: string, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        loading: resourceTemplateDetailsLoading,
        data: resourceTemplateDetails,
        refetch: refetchResourceTemplateDetails
    } = useQuery(resourceTemplateDetailsQuery, {
        client: client,
        variables: { resourceId },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: skip
    });
    return { resourceTemplateDetails, resourceTemplateDetailsLoading, refetchResourceTemplateDetails };
};
