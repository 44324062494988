import { ContactInfo, PersonInput } from 'sr-types/lib/person/v1/graphql';
import { ReferenceOption } from '../../common/form/forms';
import { EMAIL_FORMAT_REGEX } from '../../common/form/Validator';
import { ReferenceField } from '../../common/reference/reference';
import { failedToastMessage, successToastMessage } from '../../common/utils/commonUtils';
import { constructContactInfo } from '../detailsPage/detailsPageUtilities/personBasicInformation';
import { PERSON_EMPTY_LAST_NAME } from '../person';

type AddOptionValue = {
    id: string;
    label: string;
    createOption: boolean;
    ref: ReferenceField;
};

type QuickAddPerson = {
    id: string;
    name: string;
    label: string;
    role: string;
    productionRole: string;
    reference: ReferenceField;
    contactInfo: ContactInfo;
};

export const getPersonOption = (person: QuickAddPerson) => {
    return {
        id: person.id,
        name: person.name,
        label: person.name,
        contactInfo: person.contactInfo,
        role: person.role,
        productionRole: person.productionRole,
        ref: { ...person.reference, label: person.name }
    };
};

const extractQuickPersonInput = (value: string) => {
    const nameParts = value?.trim().split(' ');
    const emailAddress = nameParts.find((label) => EMAIL_FORMAT_REGEX.test(label));
    const contactInfo =
        (emailAddress && constructContactInfo({ email: { address: emailAddress, typeString: '' } })) || null;
    const input: PersonInput = {
        name: {
            firstName: emailAddress || nameParts[0] || '',
            lastName: nameParts.length > 1 && !emailAddress ? nameParts[nameParts.length - 1] : PERSON_EMPTY_LAST_NAME
        },
        contactInfo
    };
    return input;
};

export const quickAddPerson = (value: AddOptionValue, save: (value) => Promise<any>): Promise<any> => {
    const input: PersonInput = extractQuickPersonInput(value.label);
    return new Promise((resolve, reject) => {
        const savedEntity = { publicTitle: value.label };
        save({
            variables: {
                input
            }
        })
            .then((res) => {
                if (res.data.autoCreatePerson?.errors) {
                    failedToastMessage('form.save.failure', { name: input.name.firstName + '' + input.name.lastName });
                } else {
                    successToastMessage('form.save.success', { name: savedEntity.publicTitle });
                    const { autoCreatePerson } = res.data;
                    const personReferenceOption = {
                        id: autoCreatePerson?.id,
                        label: autoCreatePerson?.reference?.label,
                        ref: autoCreatePerson?.reference,
                        name: autoCreatePerson?.reference?.label
                    };
                    resolve(personReferenceOption);
                }
            })
            .catch((err) => {
                failedToastMessage('form.save.failure', { name: input.name.firstName + '' + input.name.lastName });
                reject(err);
            });
    });
};
