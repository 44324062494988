import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ExpandIcon = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 20H4V13H6V18H11V20Z" fill="currentColor" fillOpacity="1" />
            <path d="M13 4H20V11H18V6H13V4Z" fill="currentColor" fillOpacity="1" />
        </svg>
    </>,
    'ExpandIcon'
);
