import React, { useContext } from 'react';
import AddProjectOwnerModal from './production/AddProjectOwnerModal';
import ProductionForm from './ProductionFormItems';
import ProductionDialog from './ProductionDialog';
import { FormContext } from '../../common/form/FormContext';
import { ProductionFormModalType } from '../helpers/productionUtils';

const ProductionFormModal = ({
    personModalState,
    setIsSaveDisabled,
    guestEditorRef,
    enableConvertingToManaged = false,
    onCreateOwner,
    isDialogOpen = false,
    setIsDialogOpen,
    setProductionId,
    tempProd,
    hiddenFields = {},
    productionFilter
}: ProductionFormModalType) => {
    const { setState } = useContext(FormContext);
    return (
        <>
            {personModalState.open ? (
                <AddProjectOwnerModal
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                />
            ) : (
                <ProductionForm
                    enableConvertingToManaged={enableConvertingToManaged}
                    onCreateHandle={onCreateOwner}
                    hiddenFields={hiddenFields}
                    productionFilter={productionFilter}
                />
            )}
            <ProductionDialog
                isDialogOpen={isDialogOpen}
                onNo={() => {
                    setState((prevVal) => {
                        return { ...prevVal, name: { id: '', label: '' } };
                    });
                    setIsDialogOpen(false);
                }}
                onYes={() => {
                    setProductionId(tempProd.id);
                    setIsDialogOpen(false);
                }}
            />
        </>
    );
};

export default ProductionFormModal;
