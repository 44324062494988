import { Alert, SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import I18n from '../../i18n/I18n';

type InfoAlertProps = {
    descriptionToken?: string;
    sx?: SxProps;
    action?: ReactNode;
    children?: ReactNode;
    title?: string;
};

export default ({ descriptionToken, sx, action, children, title }: InfoAlertProps) => {
    return (
        <Alert
            severity="info"
            sx={{
                width: '100%',
                alignItems: 'center',
                ...sx
            }}
            action={action}
        >
            {title ? (
                <Typography fontSize={20} fontWeight={'bold'}>
                    <I18n token={title} />
                </Typography>
            ) : (
                <></>
            )}
            {children || <I18n token={descriptionToken} />}
        </Alert>
    );
};
