import React, { useContext, useEffect, useMemo } from 'react';
import { InquiryMessage, InquiryPreviewInput } from 'sr-types/lib/shortlist/v1/graphql';
import { FormContext } from '../../common/form/FormContext';
import { get } from 'lodash';
import { getInquiryPreviewInput } from './inquiry/inquiry';
import Loading from '../../common/components/Loading';
import InquiryPreviewForm from './inquiry/InquiryPreviewForm';

export default ({
    type,
    entityDetails,
    getInquiryPreviewData,
    isPreviewLoading,
    previewData
}: {
    type: string;
    entityDetails: any;
    getInquiryPreviewData: (options: any) => void;
    isPreviewLoading: boolean;
    previewData: InquiryMessage;
}) => {
    const { state } = useContext(FormContext);
    const selectedItems = get(state, type);
    const itemDetails = entityDetails.items.find((item) => selectedItems[0].id === item.id);

    const inquiryPreviewInput = useMemo(() => {
        return getInquiryPreviewInput(type, state, itemDetails);
    }, []);

    useEffect(() => {
        if (inquiryPreviewInput) {
            getInquiryPreviewData({
                variables: { inquiryInput: inquiryPreviewInput }
            });
        }
    }, [inquiryPreviewInput]);

    return isPreviewLoading ? (
        <Loading />
    ) : (
        previewData && <InquiryPreviewForm type={type} previewData={previewData} state={state} />
    );
};
