import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { authLink } from './api';
import { Attachment } from '../components/types';
import { cloneDeep } from 'lodash';
import { ReferenceFragment } from '../list/fragments';
import { Reference } from 'sr-types/lib/account/v1/graphql';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/account/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const clientWithoutAuth = new ApolloClient({
    link: createHttpLink({
        uri: '/account/v1/'
    }),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export interface OrganizationAccount {
    id: string;
    organizationId: string;
    adminEmail: string;
    adminUser: {
        id: string;
        name: string;
        roles: string[];
    };
    organizationName: string;
    calendar: Reference;
}

export interface OrganizationAccountDetails {
    organizationAccount: {
        id: string;
        organizationId: string;
        organizationName: string;
        isActive: boolean;
        adminEmail: string;
        adminUser: {
            id: string;
            name: string;
            roles: string[];
        };
        organizationBranding: {
            useCustom: boolean;
            bigLogo: Attachment;
            smallLogo: Attachment;
            brandNameOrText: string;
        };
        identityProviders: IdentityProvider[];
        domains: Domain[];
        licensedApps: LicensedApps[];
    };
}

export interface OrganizationAccountInput {
    id: string;
    organizationId: string;
    isActive: boolean;
    adminEmail: string;
    adminUser: {
        id: string;
        roles: string[];
    };
    organizationBranding: {
        useCustom: boolean;
        bigLogo: Attachment;
        smallLogo: Attachment;
        brandNameOrText: string;
    };
    licensedApps: LicensedApps[];
}

interface IdentityProvider {
    identifier: string;
    userProvidedName: string;
    idpType: string;
    oidc: OIDCDetails;
    saml: SAMLDetails;
    enabled: boolean;
}

interface OIDCDetails {
    clientId: string;
    clientSecret: string;
    issuer: string;
}

interface SAMLDetails {
    metadataURL: string;
}

interface LicensedApps {
    name: string;
}

export interface Domain {
    pattern: string;
    verified: boolean;
    idpIdentifier: string;
}


export interface AppInfo {
    appName: string;
    url: string;
    disabled: boolean
}

export enum IdpType {
    SAML = 'SAML',
    OIDC = 'OIDC'
}

// export const EMPTY_STATE = {
//     id: '',
//     organizationId: '',
//     adminEmail: '',
//     adminUser: undefined
// };

export const saveAppAccessInfoDetailsQuery: DocumentNode = gql`
    mutation SaveAppAccessInfo($organizationAccountId: ID!, $appAccessInfo: AppAccessInfoInput!) {
            saveAppAccessInfo(organizationAccountId: $organizationAccountId, appAccessInfo: $appAccessInfo) {
                id
                errors {
                    field
                    key
                    message
                }
        }
  }`;

export const orgAccountApiApplicatonDetails: DocumentNode = gql`
    query OrganizationAccount($id: ID!, $organizationId: ID!) {
        organizationAccount(Id: $id, organizationId: $organizationId) {
            id
            appAccessDetails {
                appName
                disabled
                url {
                    path
                    tldomain
                    subdomain
                }
            }
        }
    }
`;

export const resourceAccessDetail: DocumentNode = gql`
     query ResourceAccessDetail($organizationAccountId: ID!, $appAccessInfo: AppAccessInfoInput!) {
        resourceAccessDetail(organizationAccountId: $organizationAccountId, appAccessInfo: $appAccessInfo) {
            accessKey
            secretKey
        }
}     
`;


export const removeAppAccessInfoQuery: DocumentNode = gql`
    mutation RemoveAppAccessDetail($organizationAccountId: ID!, $appAccessInfo: AppAccessInfoInput!) {
        removeAppAccessDetail(organizationAccountId: $organizationAccountId, appAccessInfo: $appAccessInfo) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const removeMemberQuery: DocumentNode = gql`
    mutation removeMember($orgAccId: ID!, $memberId: ID!) {
        removeMember(organizationAccountId: $orgAccId, memberId: $memberId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveDomainQuery: DocumentNode = gql`
    mutation saveDomain($organizationAccountId: ID!, $domain: DomainInput!) {
        saveDomain(organizationAccountId: $organizationAccountId, domain: $domain) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const removeDomainQuery: DocumentNode = gql`
    mutation removeDomain($organizationAccountId: ID!, $domain: DomainInput!) {
        removeDomain(organizationAccountId: $organizationAccountId, domain: $domain) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const orgAccountDetails: DocumentNode = gql`
    query OrganizationAccount($id: ID!, $organizationId: ID!) {
        organizationAccount(Id: $id, organizationId: $organizationId) {
            id
            organizationId
            organizationName
            isActive
            adminEmail
            adminUser {
                id
                name
                roles
            }
            licensedApps {
                name
            }
            organizationBranding {
                useCustom
                bigLogo {
                    id
                    uriPrefix
                    type
                }
                smallLogo {
                    id
                    uriPrefix
                    type
                }
                brandNameOrText
            }
            identityProviders {
                userProvidedName
                identifier
                idpType
                enabled
                oidc {
                    clientId
                    clientSecret
                    issuer
                }
                saml {
                    metadataURL
                }
            }
            domains {
                pattern
                verified
                idpIdentifier
            }
        }
    }
`;

export const detailsToAccountInput = (accountDetails: OrganizationAccountDetails): OrganizationAccountInput => {
    const clone = cloneDeep(accountDetails.organizationAccount);
    delete clone.domains;
    delete clone.identityProviders;
    delete clone.organizationName;
    delete clone.adminUser.name;
    return clone;
};

export const addOrganization: DocumentNode = gql`
    mutation AddOrganization($input: AddOrganizationInput!) {
        addOrganization(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveOrgAccount: DocumentNode = gql`
    mutation SaveOrganizationAccount($input: AccountInput!) {
        saveOrganizationAccount(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const userAccountQuery: DocumentNode = gql`
    query UserAccount($Id: ID, $username: String, $organizationId: ID!) {
        userAccount(Id: $Id, username: $username, organizationId: $organizationId) {
            id
            firstName
            lastName
            roles
            noPersonReference
            email {
                address
            }
            organization {
                ...Reference
                label
            }
            department
            orgSubscriptions {
                roles
                organization {
                    ...Reference
                    label
                }
                department
            }
        }
    }
    ${ReferenceFragment}
`;

export const saveMemberQuery: DocumentNode = gql`
    mutation SaveMember($id: ID!, $input: AccountInfoInput!) {
        saveMember(organizationAccountId: $id, member: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const processInvitationQuery: DocumentNode = gql`
    mutation processInvitation($invitationId: ID!, $accept: Boolean!) {
        processInvitation(invitationId: $invitationId, accept: $accept) {
            id
            errors {
                field
                message
            }
        }
    }
`;
