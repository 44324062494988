import LazyImport from './common/LazyImport';
import { Icons } from './common/icons/Icons';
import { ProductionModes } from './production/ProductionList';
import { Typography } from '@mui/material';
import Center from './common/components/Center';
import React from 'react';

// Declare the feature routes as Features and Sub-Features
const PRODUCTION_ROUTES = {
    productionDetails: [
        {
            path: `/production/:entityId`,
            component: LazyImport(() => import('./production/form/ProductionEditor'))
        },
        {
            path: `/production/:entityId/details`,
            component: LazyImport(() => import('./production/form/ProductionEditor'))
        },
        {
            label: 'Productions',
            icon: Icons.Production,
            link: `/production`,
            path: `/production`,
            redirect: true,
            query: { productionMode: ProductionModes.production },
            drawer: true,
            component: LazyImport(() => import('./production/ProductionList'))
        }
    ],
    productionWorkspace: [
        {
            label: 'Home',
            icon: Icons.Workspaces,
            component: LazyImport(() => import('./production/ProductionList'))
        },
        {
            label: 'Workspace',
            icon: Icons.Workspaces,
            link: `/workspace`,
            path: `/workspace`,
            component: LazyImport(() => import('./production/ProductionList')),
            query: { productionMode: ProductionModes.workspace },
            drawer: true
        },
        {
            path: `/workspace/:productionId`,
            query: { productionMode: ProductionModes.workspace },
            component: LazyImport(() => import('./production/form/CreateProduction'))
        },
        {
            path: `/workspace/:productionId/details`,
            query: { productionMode: ProductionModes.workspace },
            component: LazyImport(() => import('./production/ProductionDetails'))
        },
        {
            path: `/workspace/:productionId/resource/:resourceId`,
            query: { productionMode: ProductionModes.workspace },
            component: LazyImport(() => import('./production/resource/ResourceEditor'))
        }
    ]
};

const LEADS_ROUTES = {
    leadsList: [
        {
            label: 'Leads',
            icon: Icons.Leads,
            link: `/leads`,
            path: `/leads`,
            component: LazyImport(() => import('./supply/opportunity/leads/Leads')),
            drawer: true
        }
    ],
    leadsResourceDetails: [
        {
            path: `/details/:bookingId/resource/:resourceId`,
            component: LazyImport(() => import('./production/resource/ResourceEditor'))
        }
    ],
    leadsDetails: [
        {
            path: `/details/:bookingId`,
            component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
        }
    ]
};

const OPPORTUNITY_ROUTES = {
    opportunityDetails: [
        {
            path: `/details/:bookingId/lineitem/:lineItemId`,
            component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
        },
        {
            path: `/details/:bookingId`,
            component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
        },
        {
            path: `/booking/:bookingId`,
            component: LazyImport(() => import('./supply/booking/BookingEditor'))
        },
        {
            label: 'Booking',
            icon: Icons.Bookings,
            link: `/booking`,
            path: `/booking`, 
            query: {copyBooking: undefined},
            component: LazyImport(() => import('./supply/opportunity/Opportunities')),
            drawer: true
        },
        {
            path: `/new-booking`,
            component: LazyImport(() => import('./supply/booking/BookingEditor'))
        },
        {
            path: `/booking/:bookingId/scenario/:scenarioId`,
            component: LazyImport(() => import('./supply/opportunity/scenario/ScenarioDetails'))
        },
        {
            path: `/booking/:bookingId/scenario/:scenarioId/lineitem/:lineItemId`,
            component: LazyImport(() => import('./supply/opportunity/scenario/ScenarioDetails'))
        },
        {
            path: `/booking/:bookingId/lead/:leadId`,
            component: LazyImport(() => import('./supply/opportunity/leads/LeadDetails'))
        },
        {
            path: `/booking/:bookingId/lead/:leadId/lineitem/:lineItemId`,
            component: LazyImport(() => import('./supply/opportunity/leads/LeadDetails'))
        },
    ]
};

const ORDER_ROUTES = {
    orderDetails: [
        {
            path: `/:bookingId/order/:reviseOrderId`,
            component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
        },
        {
            path: `/order/:id`,
            component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
        }
    ]
};

const QUOTE_ROUTES = {
    quoteDetails: [
        {
            path: `/:bookingId/quote/:quoteId`,
            component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
        },
        {
            path: `/quote/:id`,
            component: LazyImport(() => import('./supply/opportunity/orders/OrderEditor'))
        }
    ],
    quoteSettings: [
        {
            label: 'Quotes',
            link: `/quoteSettings`,
            path: `/quoteSettings`,
            drawer: true,
            component: LazyImport(() => import('./admin/quotes/QuoteSettings'))
        }
    ]
};

const RESERVATION_ROUTES = {
    reservationDetails: [
        {
            path: `/reservations/:entityId`,
            component: LazyImport(() => import('./supply/reservation/Reservations'))
        },
        {
            label: 'Schedule',
            icon: Icons.Schedule,
            link: `/reservations`,
            path: `/reservations`,
            component: LazyImport(() => import('./supply/dashboard/Timeline/MultiLocationTimelineContainer')),
            drawer: true
        }
    ]
};

const SCHEDULER_PREVIEW_ROUTES = {
    scheduleForSupplier: [
        {
            label: 'Schedule Preview',
            icon: Icons.SchedulerPreview,
            link: `/scheduler-preview`,
            path: `/scheduler-preview`,
            component: LazyImport(() => import('./scheduler/TimelinePreviewContainer')),
            drawer: true
        }
    ],
    schedulerForProfile: [
        {
            label: 'Schedule Preview',
            icon: Icons.SchedulerPreview,
            link: `/scheduler-preview`,
            path: `/scheduler-preview`,
            component: LazyImport(() => import('./supply/dashboard/Timeline/MultiLocationTimelineContainer')),
            drawer: true
        }
    ]
};

const REPORT_ROUTES = {
    reportDetails: [
        {
            label: 'Reporting',
            icon: Icons.Report,
            link: `/reports`,
            path: `/reports`,
            drawer: true,
            component: LazyImport(() => import('./supply/report/Reports'))
        },
        {
            path: `/reports/:reportId`,
            component: LazyImport(() => import('./supply/report/details/ReportDetails'))
        }
    ]
};

const LAB_ROUTES = {
    labItems: [
        {
            label: 'Lab',
            icon: Icons.Lab,
            drawer: true,
            divider: true,
            items: [
                {
                    label: 'Forms',
                    icon: Icons.Forms,
                    link: `/lab/forms`,
                    path: `/lab/forms`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/form/LabForms'))
                },
                {
                    label: 'Money',
                    icon: Icons.Money,
                    link: `/lab/money`,
                    path: `/lab/money`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/money/LabMoney'))
                },
                {
                    label: 'Grid',
                    icon: Icons.Grid,
                    link: `/lab/grid`,
                    path: `/lab/grid`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/grid/LabGrid'))
                },
                {
                    label: 'MUI Grid Report',
                    icon: Icons.TestReport,
                    link: `/lab/muiGridReport`,
                    path: `/lab/muiGridReport`,
                    drawer: true,
                    component: LazyImport(() => import('./supply/report/MUIGridReport'))
                },
                {
                    label: 'Grid grouping',
                    icon: Icons.GridGrouping,
                    link: `/lab/grouping`,
                    path: `/lab/grouping`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/grid/LabGroupingGrid'))
                },
                {
                    label: 'Filters',
                    icon: Icons.Filters,
                    link: `/lab/filters`,
                    path: `/lab/filters`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/filters/LabFilters'))
                },
                {
                    label: 'Production Header',
                    icon: Icons.Production,
                    link: `/lab/production`,
                    path: `/lab/production`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/marketplace/LabProduction'))
                },
                {
                    label: 'Attributes',
                    icon: Icons.Attributes,
                    link: `/lab/attributes`,
                    path: `/lab/attributes`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/attributes/LabAttributes'))
                },
                {
                    label: 'Attribute Value',
                    icon: Icons.Attributes,
                    link: `/lab/attributeValue`,
                    path: `/lab/attributeValue`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/attributes/LabAttributeValue'))
                },
                {
                    label: 'Rich text',
                    icon: Icons.RichText,
                    link: `/lab/richtext`,
                    path: `/lab/richtext`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/form/LabRichText'))
                },
                {
                    label: 'Images',
                    icon: Icons.Image,
                    link: `/lab/images`,
                    path: `/lab/images`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/form/LabAttachments'))
                },
                {
                    label: 'Avatar',
                    icon: Icons.AccountCircle,
                    link: `/lab/avatar`,
                    path: `/lab/avatar`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/form/LabAvatar'))
                },
                {
                    label: 'Lifecycle',
                    icon: Icons.Cache,
                    link: `/lab/lifecycle/01HJK772FNYX8JKP8V3YCFZXEH`,
                    path: `/lab/lifecycle/:shortlistId`,
                    drawer: true,
                    component: LazyImport(() => import('./lab/lifecycle/LabModalEdit'))
                },
                {
                    label: 'Test Reporting',
                    icon: Icons.TestReport,
                    link: `/testreports`,
                    path: `/testreports`,
                    drawer: true,
                    component: LazyImport(() => import('./supply/report/TestReports'))
                }
            ]
        }
    ]
};

const CATALOG_ROUTES = {
    catalogPrev: [
        {
            path: `/catalog-prev/:entityId`,
            component: LazyImport(() => import('./supply/product/Products'))
        },
        {
            label: 'Catalog (Prev)',
            icon: Icons.CatalogPrev,
            query: { type: undefined },
            link: `/catalog-prev`,
            path: `/catalog-prev`,
            component: LazyImport(() => import('./supply/product/Products')),
            drawer: true,
            divider: true
        }
    ],
    catalog: [
        {
            label: 'Catalog',
            icon: Icons.Catalog,
            path: `/catalog`,
            component: LazyImport(() => import('./supply/product/Products')),
            drawer: true,
            items: [
                {
                    label: 'Facilities',
                    icon: Icons.Facilities,
                    link: `/catalog`,
                    query: { type: 'Facility' },
                    drawer: true
                },
                {
                    label: 'Services',
                    icon: Icons.Services,
                    link: `/catalog`,
                    query: { type: 'Service' },
                    drawer: true
                },
                {
                    label: 'Equipment',
                    icon: Icons.Equipement,
                    link: `/catalog`,
                    query: { type: 'Equipment' },
                    drawer: true
                },
                {
                    label: 'Products',
                    icon: Icons.Products,
                    link: `/catalog`,
                    query: { type: 'Product' },
                    drawer: true
                },
                {
                    label: 'Crew',
                    icon: Icons.Crew,
                    link: `/catalog`,
                    query: { type: 'Crew' },
                    drawer: true
                },
                {
                    label: 'Rates',
                    icon: Icons.Rate,
                    feature: 'Rates',
                    link: `/catalog/rates`,
                    drawer: true,
                    path: `/catalog/rates`,
                    query: { rateFor: 'Product' },
                    component: LazyImport(() => import('./supply/rate/Rates'))
                }
            ]
        }
    ],
    catalogDetails: [
        {
            path: `/catalog/:entityId`,
            component: LazyImport(() => import('./supply/product/Products'))
        },
        {
            path: `/catalog/rates/:entityId`,
            component: LazyImport(() => import('./supply/rate/Rates'))
        }
    ]
};

const ORGANIZATION_UNITS_ROUTES = {
    organization: [
        {
            label: 'Organizations Editor',
            path: `/organizations/:entityId`,
            component: LazyImport(() => import('./organization/OrganizationEditor'))
        },
        {
            label: 'Organizations',
            icon: Icons.Organization,
            link: `/organizations`,
            path: `/organizations`,
            drawer: true,
            component: LazyImport(() => import('./organization/OrganizationList'))
        },
        {
            path: `/organizations/:entityId/details`,
            component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
        }
    ],
    organizationUnits: [
        {
            path: `/organizationunits/:entityId/details`,
            component: LazyImport(() => import('./organization/detailsPage/OrganizationDetailsPage'))
        },
        {
            path: `/organizationunits/:entityId`,
            component: LazyImport(() => import('./organization/OrganizationEditor'))
        },
        {
            label: 'Organizations',
            icon: Icons.Organization,
            path: `/organizationunits`,
            link: `/organizationunits`,
            component: LazyImport(() => import('./organization/OrganizationList')),
            drawer: true
        }
    ]
};

const PEOPLE_RESOURCE_ROUTES = {
    peopleAsGuest: [
        {
            label: 'Contact Editor',
            path: `/people/:entityId`,
            component: LazyImport(() => import('./person/GuestEditor'))
        }
    ],
    personEditor: [
        {
            path: `/people/:entityId`,
            component: LazyImport(() => import('./person/PersonEditor'))
        }
    ],
    personLists: [
        {
            label: 'People',
            icon: Icons.People,
            link: `/people`,
            path: `/people`,
            redirect: true,
            drawer: true,
            divider: true,
            component: LazyImport(() => import('./person/PersonList'))
        },
        {
            path: `/people/:entityId/details`,
            component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
        }
    ],
    peopleResources: [
        {
            path: `/peopleresources/:entityId`,
            component: LazyImport(() => import('./person/PersonEditor'))
        },
        {
            label: 'People',
            icon: Icons.People,
            path: `/peopleresources`,
            link: `/peopleresources`,
            component: LazyImport(() => import('./person/PersonList')),
            drawer: true
        },
        {
            path: `/peopleresources/:entityId/details`,
            component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
        }
    ]
};

const ACCOUNT_ROUTES = {
    profileAccount: [
        {
            label: 'Profile',
            icon: Icons.Profile,
            link: `/profile`,
            path: `/profile`,
            drawer: true,
            component: LazyImport(() => import('./person/detailsPage/PersonDetailsPage'))
        }
    ],
    profileAccountSettings: [
        {
            label: 'Settings',
            icon: Icons.Settings,
            link: `/settings`,
            path: `/settings`,
            drawer: true,
            component: () => (
                <Center>
                    <Typography fontSize={20}>Personal account settings</Typography>
                </Center>
            )
        }
    ],
    adminAccount: [
        {
            label: 'Profile',
            icon: Icons.Profile,
            link: `/account/profile`,
            path: `/account/profile`,
            component: LazyImport(() => import('./admin/organization/OrganizationProfile')),
            drawer: true
        },
        {
            label: 'Settings',
            icon: Icons.Settings,
            link: `/account/settings`,
            path: `/account/settings`,
            component: LazyImport(() => import('./admin/organization/OrganizationSettings')),
            drawer: true
        }
    ]
};

const BOOKINGS_ROUTES = {
    bookingDetails: [
        {
            path: `/details/:bookingId`,
            component: LazyImport(() => import('./supply/opportunity/details/OpportunityDetails'))
        }
    ],
    bookingLists: [
        {
            label: 'Booking',
            icon: Icons.Bookings,
            link: `/booking`,
            path: `/booking`,
            component: LazyImport(() => import('./supply/opportunity/Opportunities')),
            drawer: true,
            divider: true
        }
    ]
};

const SHORTLIST_ROUTES = {
    shortlistDetails: [
        {
            label: 'Shortlists',
            icon: Icons.Shortlist,
            link: `/shortlists`,
            path: `/shortlists`,
            drawer: true,
            component: LazyImport(() => import('./shortlists/Shortlists'))
        },
        {
            label: 'Shortlists',
            icon: Icons.Shortlist,
            link: `/shortlists/:shortlistId/details`,
            path: `/shortlists/:shortlistId/details`,
            component: LazyImport(() => import('./shortlists/ShortlistDetailsView'))
        }
    ],
    shortlistResource: [
        {
            label: 'Shortlists',
            icon: Icons.Shortlist,
            link: `/workspace/:productionId/resource/:resourceId/details`,
            path: `/workspace/:productionId/resource/:resourceId/details`,
            component: LazyImport(() => import('./production/resource/ProductionResourceDetails'))
        }
    ]
};

const SCHEDULE_ROUTES = {
    scheduleDetails: [
        {
            label: 'Schedule',
            icon: Icons.Schedule,
            link: `/scheduler`,
            path: `/scheduler`,
            component: LazyImport(() => import('./production/schedulerTimeline/CrossProductionTimelineView')),
            drawer: true
        }
    ]
};

const MARKETPLACE_ROUTES = {
    marketplace: [
        {
            path: `/marketplace/:productId`,
            component: LazyImport(() => import('./marketplace/MarketplaceDetails'))
        },
        {
            label: 'Marketplace',
            icon: Icons.Marketplace,
            link: `/marketplace`,
            path: `/marketplace`,
            drawer: true,
            component: LazyImport(() => import('./marketplace/OrganizationMarketplaceList'))
        }
    ]
};

const ORGANIZATION_ROUTES = {
    organizationDetails: [
        {
            path: `/organizations/:entityId`,
            component: LazyImport(() => import('./admin/organization/OrganizationSettings'))
        },
        {
            label: 'Organizations',
            icon: Icons.Organization,
            path: `/organizations?`,
            link: `/organizations`,
            component: LazyImport(() => import('./admin/account/Organizations')),
            drawer: true
        }
    ]
};

const PEOPLE_ROUTES = {
    peopleDetails: [
        {
            path: `/people/:entityId`,
            component: LazyImport(() => import('./admin/account/Users'))
        },
        {
            label: 'People',
            icon: Icons.People,
            path: `/people`,
            link: `/people`,
            component: LazyImport(() => import('./admin/account/Users')),
            drawer: true
        }
    ]
};

const APPLICATION_SETTING_ROUTES = {
    applicationSetting: [
        {
            label: 'Fields',
            link: `/fields`,
            path: `/fields`,
            drawer: true,
            component: LazyImport(() => import('./supply/termset/Termsets'))
        }
    ]
};

const FIELD_ROUTES = {
    fieldTermset: [
        {
            path: `/fields/:fieldId`,
            component: LazyImport(() => import('./supply/termset/TermsetEditor'))
        }
    ],
    fieldResource: [
        {
            label: 'Resource Fields',
            drawer: true,
            icon: Icons.ResourceFields,
            link: `/resourceFields`,
            path: `/resourceFields`,
            component: LazyImport(() => import('./admin/resourceFields/ResourceFields'))
        }
    ]
};

const EXCHANGE_RATE_ROUTES = {
    exchangeRate: [
        {
            label: 'Currency',
            link: `/conversion`,
            path: `/conversion`,
            drawer: true,
            component: LazyImport(() => import('./supply/currency/ConversionList'))
        },
        {
            path: `/conversion/:entityId`,
            component: LazyImport(() => import('./supply/currency/ConversionList'))
        }
    ]
};

const DEVELOPER_API_ROUTES = {
    developerAPI: [
        {
            label: 'API Applications',
            link: `/apiApplication`,
            path: `/apiApplication`,
            drawer: true,
            component: LazyImport(() => import('./admin/ApiApplication/ApiApplicationList'))
        }
    ]
};

const RESOURCES_ROUTES = {
    resourceTemplate: [
        {
            label: 'Templates',
            drawer: true,
            icon: Icons.Templates,
            items: [
                {
                    label: 'Resources',
                    drawer: true,
                    link: `/templates/resources`,
                    path: `/templates/resources`,
                    component: LazyImport(() => import('./admin/templates/resources/ResourceTemplatesList'))
                }
            ]
        }
    ],
    resourceDetails: [
        {
            path: `/resource/:resourceId`,
            component: LazyImport(() => import('./production/resource/ResourceEditor'))
        },
        {
            path: `/templates/resources/:resourceId/details`,
            component: LazyImport(() => import('./admin/templates/resources/ResourceTemplateDetails'))
        }
    ]
};

const SECURITY_ROUTES = {
    adminSecurity: [
        {
            label: 'Security',
            icon: Icons.Security,
            drawer: true,
            items: [
                {
                    label: 'Authentication',
                    link: `/security/authentication`,
                    path: `/security/authentication`,
                    component: LazyImport(() => import('./admin/organization/OrganizationSecurityAuth')),
                    drawer: true
                },
                {
                    label: 'Domains',
                    link: `/security/domains`,
                    path: `/security/domains`,
                    component: LazyImport(() => import('./admin/organization/OrganizationDomains')),
                    drawer: true
                }
            ]
        }
    ]
};

// Define the feature routes - Feature Name: Feature routes
export const FEATURE_ROUTES = {
    Production: PRODUCTION_ROUTES,
    Leads: LEADS_ROUTES,
    Opportunities: OPPORTUNITY_ROUTES,
    Orders: ORDER_ROUTES,
    Quote: QUOTE_ROUTES,
    Reservations: RESERVATION_ROUTES,
    'Scheduler Preview': SCHEDULER_PREVIEW_ROUTES,
    Report: REPORT_ROUTES,
    Lab: LAB_ROUTES,
    Catalog: CATALOG_ROUTES,
    'Organization Units': ORGANIZATION_UNITS_ROUTES,
    'People Resources': PEOPLE_RESOURCE_ROUTES,
    Account: ACCOUNT_ROUTES,
    Bookings: BOOKINGS_ROUTES,
    Shortlist: SHORTLIST_ROUTES,
    Schedule: SCHEDULE_ROUTES,
    Marketplace: MARKETPLACE_ROUTES,
    Organizations: ORGANIZATION_ROUTES,
    People: PEOPLE_ROUTES,
    'Application Settings': APPLICATION_SETTING_ROUTES,
    Fields: FIELD_ROUTES,
    'Exchange Rate': EXCHANGE_RATE_ROUTES,
    'Developer API': DEVELOPER_API_ROUTES,
    Resources: RESOURCES_ROUTES,
    Security: SECURITY_ROUTES
};
