import React from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import I18n from '../../common/i18n/I18n';
import PersonOptionRenderer from './PersonOptionRenderer';
import { autoCreateQuery as personAutoCreate, client as personClient } from '../../common/components/person/person';
import InquiryProductionFormItems from './inquiry/InquiryProductionFormItems';
import InquiryDateRangeFormItem from './inquiry/InquiryDateRangeFormItem';
import { useMutationWithContext } from '../../common/hooks/useMutationWithContext';
import { quickAddPerson } from '../../person/helpers/QuickAddPerson';

export default ({
    productionName,
    productionOptions,
    productionDateRange,
    isRequestAvailsForUser = false,
    filters = undefined
}) => {
    const [savePerson] = useMutationWithContext(personAutoCreate, personClient);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        disabled={!isRequestAvailsForUser}
                        name="people"
                        label={<I18n token="form.production.invitee.candidate" />}
                        component="ReferenceAutocomplete"
                        multiple
                        entity="Person"
                        OptionRenderer={PersonOptionRenderer}
                        getOption={(item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                role: item.role,
                                label: item.name,
                                subLabel: item.contactInfo?.email?.address,
                                ref: { ...item.reference, label: item.name },
                                contactInfo: item.contactInfo,
                                professionalRoles: item.professionalRoles
                            };
                        }}
                        preload={false}
                        notOnlyRef={isRequestAvailsForUser}
                        filter={filters}
                        addOption={isRequestAvailsForUser}
                        saveMutation={savePerson}
                        onCreateHandle={quickAddPerson}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <InquiryProductionFormItems
                    productionOptions={productionOptions}
                    productionName={productionName}
                    isRequestAvailsForUser={isRequestAvailsForUser}
                />
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        name="role"
                        label={<I18n token="form.resource.role" />}
                        component="MetadataAutocomplete"
                        metadataName="Role"
                        disabled
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Location"
                        name="locations"
                        label={<I18n token="form.resource.locations" />}
                        multiple
                        limitTags={1}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="productionSummary"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.production.summary" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
            <InquiryDateRangeFormItem dateRange={productionDateRange} />
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="personalizedMessage"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.personalizedMessage" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
