import { atom } from 'recoil';

export const isSchedulerReservationRefetchNeededAtom = atom({
    key: 'isSchedulerReservationRefetchNeeded',
    default: localStorage.getItem('isSchedulerReservationRefetchNeeded') === 'true'
});

export const eventSavedFromSchedulerAtom = atom({
    key: 'eventSavedFromScheduler',
    default: undefined
});
