import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Reference } from 'sr-types/lib/shortlist/v1/graphql';
import SidePanel from '../components/layout/SidePanel';
import I18n from '../i18n/I18n';
import { ActionType } from '../components/ActionType';
import { H, ScrollableLayout, V } from '../Layout';
import { PermissionsType } from '../auth/api';
import { Icons } from '../icons/Icons';
import Notes from '../notes/Notes';

type SummaryPanelProps = {
    entityReference: Reference;
    about: string;
    featurePermissions?: PermissionsType;
    entityPermissions?: PermissionsType;
    sidePanelActions?: ActionType[];
    onClose: () => void;
    onAddNotes: () => void;
    generatedByAI?: boolean;
};

export default ({
    entityReference,
    about,
    featurePermissions,
    entityPermissions,
    sidePanelActions,
    onClose,
    onAddNotes,
    generatedByAI = false
}: SummaryPanelProps) => {
    return (
        <ScrollableLayout>
            <SidePanel id={'summarypanel-container'} title={<I18n token="common.summary" />} actions={sidePanelActions}>
                <V gap={1} sx={{ p: 1 }}>
                    <Typography variant="subtitle2" fontWeight={'500'} fontSize={14}>
                        <H gap={1}>
                            <I18n token="shortlist.about" />
                            {generatedByAI && <Icons.AutoAwesomeGradient fontSize="small" />}
                        </H>
                    </Typography>
                    <Tooltip title={about}>
                        <Typography
                            fontWeight={'400'}
                            fontSize={12}
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '3',
                                WebkitBoxOrient: 'vertical'
                            }}
                        >
                            {about}
                        </Typography>
                    </Tooltip>
                    <Typography variant="subtitle2" fontWeight={'500'} fontSize={14}>
                        <H gap={1}>
                            <I18n token="notes.title" />
                            <Icons.AddOutlined
                                className="clickable"
                                fontSize="small"
                                color="primary"
                                onClick={onAddNotes}
                            />
                        </H>
                    </Typography>
                    <V>
                        <Notes
                            id={'notes-summary'}
                            entityReference={entityReference}
                            onClose={onClose}
                            featurePermissions={featurePermissions}
                            entityPermissions={entityPermissions}
                            hideSearchAndEditor
                            renderWithoutSidePanel
                        />
                    </V>
                </V>
            </SidePanel>
        </ScrollableLayout>
    );
};
