import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { authLink } from '../common/auth/api';
import {
    InquiryInput,
    LineItem,
    LineItemInput,
    Reference,
    Shortlist,
    ShortlistInput,
    InquiryPreviewInput
} from 'sr-types/lib/shortlist/v1/graphql';
import { DateRangeFragment, ReferenceFragment } from '../common/list/fragments';
import { InquiryStatusEnum } from './useShortlistLineItemsColDefs';
import { LineItemRows } from './ShortlistLineItems';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/shortlist/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export type ReferenceWithLineItemId = Reference & { shortlistLineItemId: string };

export const saveQuery: DocumentNode = gql`
    mutation SaveShortlist($input: ShortlistInput!) {
        saveShortlist(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const detailsQuery: DocumentNode = gql`
    query Shortlist($shortlistId: ID!) {
        shortlist(id: $shortlistId) {
            identity {
                id
            }
            name
            summary {
                about
                headline
            }
            context {
                ...Reference
            }
            type
            categories
            roles
            sequence {
                id
                type
                label
                uriPrefix
            }
            lineItems(page: { from: 0, size: 100 }) {
                name
                identity {
                    id
                }
                referenceUrl {
                    path
                    tldomain
                    subdomain
                }
                itemReference {
                    ...Reference
                }
                summary {
                    about
                    headline
                }
                itemStatus
                inquiry {
                    productionName
                    role
                    synopsis
                    message
                    inquiryStatus
                    assignmentPeriod {
                        ...DateRange
                    }
                    proposedDate {
                        ...DateRange
                    }
                    locations {
                        label
                        primary
                    }
                }
                defaultNoteReference {
                    id
                }
                hasAccount
                isItemSharedInDirectory
            }
        }
    }
    ${DateRangeFragment}
    ${ReferenceFragment}
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteShortlist($id: ID!) {
        deleteShortlist(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addItemToShortlist: DocumentNode = gql`
    mutation AddItemToShortlist($id: ID!, $input: ItemInput!) {
        addItemToShortlist(id: $id, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveLineItem: DocumentNode = gql`
    mutation SaveLineItem($shortlistId: ID!, $input: LineItemInput!) {
        saveLineItem(shortlistId: $shortlistId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveLineItems: DocumentNode = gql`
    mutation SaveLineItems($shortlistId: ID!, $input: [LineItemInput]!) {
        saveLineItems(shortlistId: $shortlistId, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;


export const saveLineItemWithNote: DocumentNode = gql`
    mutation SaveLineItemWithNote($shortlistId: ID!, $input: LineItemInput!, $note: NoteInput) {
        saveLineItemWithNote(shortlistId: $shortlistId, input: $input, note: $note) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteLineItemFromShortlist: DocumentNode = gql`
    mutation deleteLineItem($shortlistId: ID!, $lineItemId: ID!) {
        deleteLineItem(shortlistId: $shortlistId, lineItemId: $lineItemId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const inquiryPreviewQuery: DocumentNode = gql`
    query InquiryPreview($inquiryInput: InquiryPreviewInput) {
        inquiryPreview(inquiryInput: $inquiryInput) {
            inquiryMessage {
                content
                senderInfo {
                    address
                    name
                }
            }
            inquiryFor
        }
    }
`;

export const EMPTY_SHORTLIST: ShortlistInput = {
    identity: { id: '' },
    name: '',
    roles: [],
    categories: [],
    summary: { about: '', headline: '' },
    type: '',
    sequence: undefined
};

export const inquiriesInputToShortlist = (inquiriesInput: InquiryInput[], prevShortlistState: Shortlist): Shortlist => {
    const { lineItems } = prevShortlistState;

    const updatedLineItems: LineItem[] = lineItems.map((lineItem) => {
        const found = inquiriesInput.find((item) => item.shortlistLineItemId === lineItem.identity.id);

        if (found) {
            return {
                ...lineItem,
                inquiry: { ...found.inquiryPayload, inquiryStatus: InquiryStatusEnum.requestSent }
            };
        }
        return lineItem;
    });

    return { ...prevShortlistState, lineItems: updatedLineItems };
};

export const deleteItemFromState = (lineItemId: string, prevShortlistState: Shortlist): Shortlist => {
    const { lineItems } = prevShortlistState;
    lineItems.splice(
        lineItems.findIndex((i) => i.identity.id === lineItemId),
        1
    );
    return {
        ...prevShortlistState,
        lineItems
    };
};

export const lineItemInputToShortlist = (
    lineItemEditState: LineItemInput,
    lineItemId: string,
    prevShortlistState: Shortlist,
    note: string
): Shortlist => {
    const { lineItems } = prevShortlistState;
    const updatedLineItems = [...lineItems];

    const found = updatedLineItems.findIndex((item) => item.identity.id === lineItemId);
    if (found !== -1) {
        updatedLineItems[found] = {
            ...updatedLineItems[found],
            ...lineItemEditState,
            identity: { id: lineItemId },
            noteExists: !!note
        } as LineItemRows;
    } else {
        updatedLineItems.push({
            ...lineItemEditState,
            identity: { id: lineItemId },
            noteExists: !!note
        } as LineItemRows);
    }

    return { ...prevShortlistState, lineItems: updatedLineItems };
};
