import React from 'react';
import OkCancel from '../components/OkCancel';
import { signUp, SignupPayload } from './api';
import { AuthFormHeader, authLinks, HaveAccount } from './AccountLinks';
import I18n, { useI18n } from '../../common/i18n/I18n';
import useHistory from '../utils/useHistory';
import { Container, Paper } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import Nav from '../nav/Nav';
import Validator from '../form/Validator';
import { useLocation } from 'react-router-dom';
import { failedToastMessage, successToastMessage } from '../utils/commonUtils';
import PolicyLink from './PolicyLink';
import { V } from '../Layout';
import { FormProvider } from '../form/FormContext';
import TermsAndServiceCheck from './TermsAndServiceCheck';

export default () => {
    const { changeRoute } = useHistory();
    const location = useLocation();
    const username = location.state?.username || '';
    const fName = location.state?.firstName || '';
    const lName = location.state?.lastName || '';
    const socialSignUp = location.state?.socialSignUp || false;
    const title = useI18n(socialSignUp ? 'dialog.create.account' : 'dialog.signup');

    const validationRules = {
        firstName: [Validator.RULES.isRequired],
        lastName: [Validator.RULES.isRequired],
        email: [Validator.RULES.isRequired, Validator.RULES.email],
        mobile: !socialSignUp && [Validator.RULES.phoneNumber],
        password: !socialSignUp && [Validator.RULES.isRequired],
        passwordConfirm: !socialSignUp && [
            Validator.Custom((prop, state) => {
                const password = state.password;
                const value = state[prop];
                return value === password;
            }, 'Needs to match password')
        ],
        acceptTermsAndPolicy: [
            {
                isRequired: true,
                isValid: (prop, state) => {
                    return !!state[prop];
                },
                resolveI18nToken: () => 'You must agree to the terms and conditions.'
            }
        ]
    };

    const [values, setValues] = React.useState<SignupPayload>({
        username: '',
        email: username || '',
        password: '',
        passwordConfirm: '',
        mobile: '',
        firstName: fName || '',
        lastName: lName || '',
        acceptTermsAndPolicy: false
    });

    const onOk = () => {
        values.username = values.email;
        signUp(values)
            .then(() => {
                successToastMessage('dialog.created');
                changeRoute(authLinks.verify.path, {}, true, {
                    username: values.username,
                    socialSignUp: values.acceptTermsAndPolicy && location.state?.socialSignUp
                });
            })
            .catch((err) => {
                failedToastMessage(`Signup failed: ${err.message}`);
            });
    };

    const onCancel = () => {
        changeRoute(authLinks.login.path);
    };

    return (
        <>
            <Nav />
            <Container key="signup" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
                <FormProvider state={values} setState={setValues} validationRules={validationRules}>
                    <V
                        fill
                        sx={{
                            gap: '8px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Paper sx={{ maxWidth: '400px', p: 4 }}>
                            <>
                                <FormContainer>
                                    <FormItem>
                                        <AuthFormHeader title={title} />
                                    </FormItem>
                                </FormContainer>
                                <FormContainer>
                                    <FormItem half>
                                        <FormWidget
                                            name="firstName"
                                            label={<I18n token="account.form.firstName" />}
                                            value={values.firstName}
                                        />
                                    </FormItem>
                                    <FormItem half>
                                        <FormWidget
                                            name="lastName"
                                            label={<I18n token="account.form.lastName" />}
                                            value={values.lastName}
                                        />
                                    </FormItem>
                                </FormContainer>
                                <FormContainer>
                                    <FormItem>
                                        <FormWidget
                                            name="email"
                                            disabled={!!username}
                                            label={<I18n token="account.form.email" />}
                                            value={values.email}
                                        />
                                    </FormItem>
                                    <FormItem hidden={socialSignUp}>
                                        <FormWidget
                                            component="PhoneNumber"
                                            name="mobile"
                                            label={<I18n token="account.form.mobileNumber" />}
                                            value={values.mobile}
                                        />
                                    </FormItem>
                                </FormContainer>
                                <FormContainer>
                                    <FormItem half hidden={socialSignUp}>
                                        <FormWidget
                                            name="password"
                                            type="password"
                                            label={<I18n token="account.form.password" />}
                                        />
                                    </FormItem>
                                    <FormItem half hidden={socialSignUp}>
                                        <FormWidget
                                            name="passwordConfirm"
                                            type="password"
                                            label={<I18n token="account.form.confirmPassword" />}
                                        />
                                    </FormItem>
                                </FormContainer>
                                <TermsAndServiceCheck />
                                <FormContainer>
                                    <FormItem>
                                        <OkCancel
                                            okLabelI18n={socialSignUp ? 'dialog.createAccount' : 'dialog.signup'}
                                            onOk={onOk}
                                            onCancel={onCancel}
                                            hideCancelButton={socialSignUp}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <HaveAccount />
                                    </FormItem>
                                </FormContainer>
                            </>
                        </Paper>
                        <PolicyLink />
                    </V>
                </FormProvider>
            </Container>
        </>
    );
};
