import React, { useContext, useEffect, useState } from 'react';
import Validator, { ValidationState } from '../form/Validator';
import OkCancel from '../components/OkCancel';
import { AuthFormHeader } from './AccountLinks';
import I18n, { I18nContext, I18nContextType, useI18n } from '../../common/i18n/I18n';
import { Box, Container, Link, Paper, Typography } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { handleAndCommitChange } from '../form/forms';
import { useLocation } from 'react-router-dom';
import { H } from '../Layout';
import { SHOWRUNNR_POLICY_LINK, SHOWRUNNR_TERMS_LINK } from './PolicyLink';

export default ({
    username,
    challenge,
    isBusy,
    onSubmit,
    onCancel,
    validationState = undefined,
    onResend = undefined,
    pageTitle = undefined
}) => {
    const i18nContext: I18nContextType = useContext(I18nContext);
    const location = useLocation();
    const email = location.state?.email;

    const validationRules =
        challenge === 'CUSTOM_CHALLENGE'
            ? {
                  secretCode: [
                      Validator.Custom((prop, state) => {
                          return !!state['secretCode']?.match('^[0-9]{6,6}$');
                      }, 'Enter valid code'),
                      Validator.RULES.isRequired
                  ]
              }
            : {
                  username: [Validator.RULES.isRequired, Validator.RULES.email],
                  password: [Validator.RULES.isRequired],
                  passwordConfirm: [
                      Validator.Custom((prop, state) => {
                          return state['password'] === state['passwordConfirm'];
                      }, 'Password does not match')
                  ],
                  consentCheckbox: [
                      {
                          isRequired: true,
                          isValid: (prop, state) => {
                              return !!state[prop];
                          },
                          resolveI18nToken: () => 'You must agree to the terms and conditions.'
                      }
                  ]
              };

    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });

    const [values, setValues] = useState({
        username: username,
        password: '',
        passwordConfirm: '',
        secretCode: undefined,
        consentCheckbox: false
    });

    const [valid, setValid] = useState<ValidationState>(
        validationState ?? {
            isValid: false,
            errors: {}
        }
    );

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };

    let formErrors = [];
    if (valid.errors.length) {
        Object.keys(valid.errors).map((key) => {
            if (Array.isArray(valid.errors[key])) {
                formErrors = formErrors.concat(valid.errors[key]);
            }
        });
        console.log('form errors:', formErrors);
    }

    useEffect(() => {
        if (validationState) {
            setValid(validationState);
        }
    }, [validationState]);

    const title = pageTitle ?? useI18n('dialog.login');
    return (
        <Container key="challange" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Paper
                    sx={{
                        maxWidth: '400px',
                        p: 4,
                        minHeight: '500px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {challenge === 'CUSTOM_CHALLENGE' ? (
                        <FormContainer>
                            <FormItem>
                                <AuthFormHeader title={title} />
                            </FormItem>

                            <FormItem>
                                <FormWidget
                                    name="secretCode"
                                    label={<I18n token="account.secret.code" />}
                                    value={values.secretCode}
                                    errors={valid.errors.secretCode}
                                    hasErrors={hasErrors('secretCode')}
                                    onChange={onChange}
                                    onKeyUp={(event) => {
                                        if (event.key === 'Enter') {
                                            onSubmit({ username: values.username, secretcode: values.secretCode });
                                        }
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <OkCancel
                                    isBusy={isBusy}
                                    okLabelI18n="dialog.submit"
                                    onOk={() => {
                                        onSubmit({ username: values.username, secretcode: values.secretCode });
                                    }}
                                    onCancel={onCancel}
                                    isOkDisabled={!valid.isValid}
                                />
                            </FormItem>
                            <FormItem>
                                {onResend && (
                                    <Typography fontSize={'small'}>
                                        <I18n token="email.sent" />{' '}
                                        <Link
                                            className="clickable"
                                            onClick={() => {
                                                onResend?.();
                                            }}
                                        >
                                            <I18n token="code.resend" />
                                        </Link>
                                    </Typography>
                                )}
                            </FormItem>
                        </FormContainer>
                    ) : (
                        <FormContainer>
                            <FormItem>
                                <AuthFormHeader title={email ? <I18n token="dialog.secure" /> : title} />
                            </FormItem>
                            <FormItem>
                                <FormWidget
                                    name="username"
                                    label={<I18n token="account.form.email" />}
                                    value={values.username}
                                    errors={valid.errors.username}
                                    hasErrors={hasErrors('username')}
                                    onChange={onChange}
                                    disabled={true}
                                />
                            </FormItem>
                            <FormItem>
                                <FormWidget
                                    name="password"
                                    type="password"
                                    label={<I18n token="account.form.newPassword" />}
                                    value={values.password}
                                    errors={valid.errors.password}
                                    hasErrors={hasErrors('password')}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem>
                                <FormWidget
                                    name="passwordConfirm"
                                    type="password"
                                    label={<I18n token="account.form.confirmNewPassword" />}
                                    value={values.passwordConfirm}
                                    errors={valid.errors.passwordConfirm}
                                    hasErrors={hasErrors('passwordConfirm')}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem>
                                <H
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 'fit-content'
                                    }}
                                    fill
                                >
                                    <FormWidget
                                        onChange={onChange}
                                        component="Checkbox"
                                        name="consentCheckbox"
                                        errors={valid.errors.consentCheckbox}
                                        hasErrors={hasErrors('consentCheckbox')}
                                        sx={{ marginRight: 0 }}
                                    />

                                    <Typography fontSize={12}>
                                        <I18n token="dialog.checkbox.create" />
                                        <Link
                                            target="_blank"
                                            href={SHOWRUNNR_TERMS_LINK}
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            <I18n token="common.privacy.terms" />
                                        </Link>
                                        <I18n token="dialog.checkbox.acknowledge" />
                                        <Link
                                            target="_blank"
                                            href={SHOWRUNNR_POLICY_LINK}
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            <I18n token="common.privacy.policy" />
                                        </Link>
                                    </Typography>
                                </H>
                            </FormItem>
                            <FormItem>
                                <OkCancel
                                    isBusy={isBusy}
                                    okLabelI18n={email ? 'dialog.login' : 'dialog.submit'}
                                    onOk={() => {
                                        onSubmit({
                                            username: values.username,
                                            password: values.password,
                                            passwordConfirm: values.passwordConfirm
                                        });
                                    }}
                                    onCancel={onCancel}
                                    isOkDisabled={!valid.isValid}
                                />
                            </FormItem>
                        </FormContainer>
                    )}
                </Paper>
            </Box>
        </Container>
    );
};
