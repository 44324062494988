import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isEmpty, set } from 'lodash';
import { useContext, useState } from 'react';
import {
    ActivityInput,
    AttachmentInput,
    ContactInput,
    LocationInput,
    ManageContactInput,
    Production,
    ProductionInput,
    ProductionOrganizationInfo,
    ResourceInput
} from 'sr-types/lib/production/v1/graphql';
import { UserContext } from '../../common/auth/UserContext';
import { enqueueSnackbar } from '../../common/components/Toast';
import { constants } from '../../common/constants';
import { Listener } from '../../common/form/FormContext';
import { useI18n } from '../../common/i18n/I18n';
import { getL10nDef } from '../../common/i18n/localization';
import { searchClient, slimResultsQuery } from '../../common/list/slimQuery';
import { toYearFromISOString } from '../../common/utils/dateTime';
import { getDateRangeListeners, recalculateProductionYears } from '../../supply/booking/ScheduleCalculator';
import {
    client,
    activityDetails,
    deleteActivity,
    detailsQuery,
    linkResourceToActivity as linkResourceActivityMutation,
    resourceDetailsQuery,
    saveActivity,
    unlinkResourceFromActivity as unlinkResourceActivityMutation,
    resourceDeleteQuery,
    keyActivities,
    markKeyActivity as markKeyActivityMutation,
    saveKeyActivities as saveKeyActivitiesMutation,
    saveKeyPeople as saveKeyPeopleMutation,
    saveQuery,
    toAssociatedOrgs,
    saveAttachmentQuery,
    productionAttachmentDetails,
    deleteAttachmentQuery,
    saveActivities,
    addPersonsToProduction,
    saveResourcesQuery
} from './production';
import { EMPTY_PRODUCTION_DATES, ProductionWithReference } from './productionUtils';
import { UserProfile } from '../../common/auth/api';
import { areAllFiltersInList, failedToastMessage, successToastMessage } from '../../common/utils/commonUtils';
import { useMutationWithContext } from '../../common/hooks/useMutationWithContext';

export const useDeleteResource = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteResourceMutation] = useMutation(resourceDeleteQuery, { client });

    const successMessage = useI18n('resource.delete.success');
    const errorMessage = useI18n('resource.delete.failure');

    const deleteResource = (resourceId, onSuccess, onError) =>
        deleteResourceMutation({
            variables: {
                id: resourceId
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((deleteResourceResponse) => {
                if (deleteResourceResponse.data.deleteResource.id) {
                    enqueueSnackbar(successMessage, {
                        variant: 'success'
                    });
                    onSuccess?.(deleteResourceResponse);
                } else {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    onError?.(deleteResourceResponse);
                }
            })
            .catch((err) => {
                enqueueSnackbar(errorMessage, {
                    variant: 'error'
                });
                onError?.();
            });

    return { deleteResource };
};

export const useProductionResourceDetailsData = (resourceId, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: productionResourceDetailsData,
        loading: productionResourceDetailsLoading,
        refetch: productionResourceDetailsRefetch
    } = useQuery(resourceDetailsQuery, {
        variables: { resourceId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip,
        notifyOnNetworkStatusChange: true
    });

    return { productionResourceDetailsData, productionResourceDetailsLoading, productionResourceDetailsRefetch };
};

export const useProductionDetailsData = (productionId, skip = false) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: productionDetailsData,
        loading: productionDetailsLoading,
        refetch: productionDetailsRefetch
    } = useQuery(detailsQuery, {
        variables: { id: productionId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false,
        skip: skip
    });

    return { productionDetailsData, productionDetailsLoading, productionDetailsRefetch };
};

export const useLazyProductionDetailsData = (productionId) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [getProductionDetailsData, { loading: productionDetailsLoading, data: productionDetailsData }] = useLazyQuery(
        detailsQuery,
        {
            variables: { id: productionId },
            client,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            },
            fetchPolicy: constants.apolloFetchPolicy,
            notifyOnNetworkStatusChange: true
        }
    );

    return { getProductionDetailsData, productionDetailsData, productionDetailsLoading };
};

export const useActivitySave = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [save, { loading: isActivitySaving }] = useMutation(saveActivity, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('production.activitySave.success');
    const errorMessage = useI18n('production.activitySave.error');
    const missingPlanMessage = useI18n('production.activitySave.plan.error');

    const activitySave = (productionId: string, planId: string, activityInput: ActivityInput, showMessage = false) => {
        return new Promise((resolve, reject) => {
            if (!planId && planId != '') {
                showMessage &&
                    enqueueSnackbar(missingPlanMessage, {
                        variant: 'error'
                    });
                const missingPlanMsg = 'Plan ID is missing.';
                reject(missingPlanMsg);
            } else {
                save({
                    variables: {
                        productionId: productionId,
                        planId: planId,
                        input: activityInput
                    }
                })
                    .then((activitySaveResponse) => {
                        if (!activitySaveResponse.data.errors) {
                            showMessage &&
                                enqueueSnackbar(successMessage, {
                                    variant: 'success'
                                });
                            resolve(activitySaveResponse);
                        } else {
                            showMessage &&
                                enqueueSnackbar(errorMessage, {
                                    variant: 'error'
                                });
                            reject(activitySaveResponse);
                        }
                    })
                    .catch((err) => {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(err);
                    });
            }
        });
    };

    return { activitySave, isActivitySaving };
};

export const useBulkActivitySave = () => {
    const [save, { loading: areActivitiesSaving }] = useMutationWithContext(saveActivities, client);

    const bulkActivitySave = (productionId: string, planId: string, input: ActivityInput[], showMessage = false) => {
        return new Promise((resolve, reject) => {
            if (!planId && planId != '') {
                showMessage && failedToastMessage('production.activitySave.plan.error');
                const missingPlanMsg = 'Plan ID is missing.';
                reject(missingPlanMsg);
            } else {
                save({
                    variables: {
                        productionId,
                        planId,
                        input
                    }
                })
                    .then((activitySaveResponse) => {
                        const savedActivities = activitySaveResponse.data.saveActivities;
                        const hasMissingId = savedActivities?.some((activity) => !activity.id);
                        if (hasMissingId) {
                            failedToastMessage('production.activitySave.error');
                            reject(activitySaveResponse);
                        } else {
                            showMessage && successToastMessage('production.activitySave.success');
                            resolve(activitySaveResponse);
                        }
                    })
                    .catch((err) => {
                        showMessage && failedToastMessage('production.activitySave.error');
                        reject(err);
                    });
            }
        });
    };

    return { bulkActivitySave, areActivitiesSaving };
};

export const useSaveProductionResources = () => {
    const [saveProductionResources] = useMutationWithContext(saveResourcesQuery, client);

    const saveResourcesToProduction = ({ input, productionId }: { input: ResourceInput[]; productionId: string }) => {
        return new Promise((resolve, reject) => {
            saveProductionResources({
                variables: {
                    input,
                    productionId
                }
            })
                .then((savedResourceResponse) => {
                    const savedResources = savedResourceResponse.data.saveResources;
                    const hasMissingId = savedResources?.some((resource) => !resource.id);
                    if (hasMissingId) {
                        failedToastMessage('resource.save.failure');
                    }
                    resolve(savedResourceResponse);
                })
                .catch((err) => {
                    failedToastMessage('resource.save.failure');
                    reject(err);
                });
        });
    };

    return { saveResourcesToProduction };
};

export const useLinkResourceToActivity = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [save] = useMutation(linkResourceActivityMutation, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('production.linkResourceToActivity.success');
    const errorMessage = useI18n('production.linkResourceToActivity.error');

    const linkResourceToActivity = (activityId: string, resourceId: string, showMessage = false) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    activityId,
                    resourceId
                }
            })
                .then((linkResourceResponse) => {
                    if (!linkResourceResponse.data?.linkResourceToActivity?.errors) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(linkResourceResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(linkResourceResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };

    return { linkResourceToActivity };
};

export const useUnlinkResourceFromActivity = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [save] = useMutation(unlinkResourceActivityMutation, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('production.unlinkResourceFromActivity.success');
    const errorMessage = useI18n('production.unlinkResourceFromActivity.error');

    const unlinkResourceFromActivity = (activityId: string, resourceId: string, showMessage = false) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    activityId,
                    resourceId
                }
            })
                .then((unlinkResourceResponse) => {
                    if (!unlinkResourceResponse.data?.unlinkResourceFromActivity?.errors) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(unlinkResourceResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(unlinkResourceResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };

    return { unlinkResourceFromActivity };
};

export const useActivityData = (activityId, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: activityData,
        loading: activityLoading,
        refetch: activityRefetch
    } = useQuery(activityDetails, {
        variables: { activityId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: skip
    });

    return { activityData, activityLoading, activityRefetch };
};

export const useKeyActivitiesData = (productionId, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: keyActivityData,
        loading: keyActivityLoading,
        refetch: keyActivityRefetch
    } = useQuery(keyActivities, {
        variables: { productionId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: skip
    });

    return { keyActivityData, keyActivityLoading, keyActivityRefetch };
};

export const useDeleteActivity = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteActivityMutation] = useMutation(deleteActivity, { client });

    const successMessage = useI18n('production.activity.delete.success');
    const errorMessage = useI18n('production.activity.delete.failure');

    const activityDelete = (activityId, showMessage = false) => {
        return new Promise((resolve, reject) => {
            deleteActivityMutation({
                variables: {
                    id: activityId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((deleteActivityResponse) => {
                    if (deleteActivityResponse?.data?.deleteActivity?.id) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(deleteActivityResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(deleteActivityResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { activityDelete };
};

export const useMarkKeyActivity = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [markActivityAsKey] = useMutation(markKeyActivityMutation, { client });

    const successMessage = useI18n('production.activity.markAsKey.success');
    const errorMessage = useI18n('production.activity.markAsKey.failure');

    const markKeyActivity = (activityId, showMessage = false) => {
        return new Promise((resolve, reject) => {
            markActivityAsKey({
                variables: {
                    activityId: activityId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((markActivityResponse) => {
                    if (markActivityResponse?.data?.markKeyActivity?.id) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(markActivityResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(markActivityResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { markKeyActivity };
};

export const useSaveKeyActivities = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [save, { loading: isKeyActivitySaving }] = useMutation(saveKeyActivitiesMutation, { client });

    const successMessage = useI18n('production.activity.saveKey.success');
    const errorMessage = useI18n('production.activity.saveKey.failure');

    const saveKeyActivities = (planId, keyActivitiesRef, showMessage = false) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    planId: planId,
                    input: keyActivitiesRef
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((markActivityResponse) => {
                    if (markActivityResponse?.data?.saveKeyActivities?.id) {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(markActivityResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(markActivityResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { saveKeyActivities, isKeyActivitySaving };
};

export const useActiviesData = (productionId: string = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const filters = [
        {
            identifier: 'entity',
            value: 'Activity'
        }
    ];

    productionId &&
        filters.push({
            identifier: 'production',
            value: productionId
        });
    const { data: activitiesData, loading: activitiesLoading } = useQuery(slimResultsQuery('Activity'), {
        variables: {
            filters: filters,
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true
    });

    return { activitiesData, activitiesLoading };
};

export const useResourcesData = (productionId: string = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const filters = [
        {
            identifier: 'entity',
            value: 'Resource'
        }
    ];

    productionId &&
        filters.push({
            identifier: 'production',
            value: productionId
        });

    const { data: resourcesData, loading: resourcesLoading } = useQuery(slimResultsQuery('Activity'), {
        variables: {
            filters: filters,
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy
    });

    return { resourcesData, resourcesLoading };
};

export const useBulkAssociatePeople = () => {
    const [save] = useMutationWithContext(addPersonsToProduction, client);

    const bulkAssociatePeople = (productionId: string, input: ManageContactInput[]) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    productionId,
                    input
                }
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(err));
        });
    };

    return { bulkAssociatePeople };
};

export const useSaveKeyPeople = () => {
    const [save, { loading: isKeyPeopleSaving }] = useMutationWithContext(saveKeyPeopleMutation, client);

    const saveKeyPeople = (productionId: string, contactInput: ContactInput[], showMessage: boolean = false) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    productionId: productionId,
                    input: contactInput
                }
            })
                .then((saveKeyPeopleResponse) => {
                    if (saveKeyPeopleResponse?.data?.saveKeyPeople?.id) {
                        showMessage && successToastMessage('production.person.saveKey.success');
                        resolve(saveKeyPeopleResponse);
                    } else {
                        showMessage && failedToastMessage('production.person.saveKey.failure');
                        reject(saveKeyPeopleResponse);
                    }
                })
                .catch((err) => {
                    showMessage && failedToastMessage('production.person.saveKey.failure');
                    reject(err);
                });
        });
    };
    return { saveKeyPeople, isKeyPeopleSaving };
};

const l10n = getL10nDef();

export const useReadWriteGql = (entityId: string) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const create = entityId === constants.createId;

    const {
        loading: isLoading,
        data,
        error: loadError
    } = useQuery(detailsQuery, {
        variables: { id: entityId },
        client,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        skip: create
    });

    const [saveMutation, { loading: isSaving, error: saveError }] = useMutation(saveQuery, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    return { isLoading, isSaving, loadError, saveError, data, saveMutation };
};

export const useSaveAttachment = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [save, { loading: isSaving }] = useMutation(saveAttachmentQuery, {
        client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('attachment.save.success');
    const errorMessage = useI18n('attachment.save.error');

    const attachmentSave = (productionId: string, input: AttachmentInput[]) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    productionId,
                    input
                }
            })
                .then((activitySaveResponse) => {
                    if (!activitySaveResponse.data.errors) {
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                        resolve(activitySaveResponse);
                    } else {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                        reject(activitySaveResponse);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    reject(err);
                })
                .finally(() =>
                    setTimeout(() => {
                        client.refetchQueries({
                            include: [productionAttachmentDetails]
                        });
                    }, 300)
                );
        });
    };

    return { attachmentSave };
};

export const useGetAttachments = (productionId: string, skip: boolean) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { data, loading, refetch } = useQuery(productionAttachmentDetails, {
        variables: {
            productionId: productionId
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip
    });
    return { data, loading, refetch };
};

export const useDeleteAttachments = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteAttachmentMutation] = useMutation(deleteAttachmentQuery, { client });

    const successMessage = useI18n('attachment.delete.success');
    const errorMessage = useI18n('attachment.delete.error');

    const deleteAttachment = (productionId: string, input: AttachmentInput) => {
        return new Promise((resolve, reject) => {
            deleteAttachmentMutation({
                variables: {
                    productionId,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((deleteAttachmentResponse) => {
                    if (deleteAttachmentResponse.data.deleteAttachments.errors) {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    } else {
                        setTimeout(() => {
                            client.refetchQueries({
                                include: [productionAttachmentDetails]
                            });
                        }, 300);
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                });
        });
    };

    return { deleteAttachment };
};

export const useRefetchTasks = () => {
    const [isRefetching, setIsRefetching] = useState(false);
    const refetchTasks = () => {
        setIsRefetching(true);
        setTimeout(() => {
            searchClient
                .refetchQueries({
                    include: ['slimResultsQuery'],
                    onQueryUpdated(observableQuery) {
                        return areAllFiltersInList(observableQuery.options.variables.filters, [
                            {
                                identifier: 'entity',
                                value: 'Activity'
                            }
                        ]);
                    }
                })
                .finally(() => {
                    setIsRefetching(false);
                });
        }, 500);
    };
    return {
        isRefetching,
        refetchTasks
    };
};

export const toInput = (
    id: string,
    entity: Production & ProductionInput,
    associatedOrgsRaw?: boolean,
    userProfile?: UserProfile
): ProductionInput => {
    const {
        otherTitles,
        productionType,
        about,
        headline,
        budget,
        productionGroup,
        genre,
        productionStatus,
        productionYears,
        name,
        associatedPeople,
        alternateIds,
        productionDates,
        locations,
        managedProduction,
        releaseDate,
        showAttributes,
        keyPeople,
        projectStatus,
        summary,
        publicTitle
        // projectOwner
    } = entity;

    let associatedOrganizations = [];
    // const owner = projectOwner || getDefaultOwner(userProfile); /* We may use it later on */

    entity.associatedOrgs?.map((orgs) => {
        associatedOrganizations.push({
            organizationReference: orgs.organizationReference,
            organization: orgs.name,
            organizationTypes: orgs.organizationTypes,
            organizationCategories: orgs.organizationCategories
        });
    });

    return {
        identity: {
            id: id === constants.createId ? '' : id
        },
        publicTitle: name || publicTitle,
        productionType: productionType || '',
        summary: about
            ? {
                  about: about || '',
                  headline: headline || ''
              }
            : summary,
        budget: {
            currencyCode: budget && (budget.currencyCode || l10n.defaultCurrency),
            nanos: budget && budget.nanos,
            units: budget && budget.units ? budget.units : 0
        },
        otherTitles: Array.isArray(otherTitles) ? otherTitles.filter((item) => item.value) : [],
        productionGroup: productionGroup?.productionReference ? { ...productionGroup } : null,
        genre: genre || [],
        productionStatus: productionStatus,
        productionYears: productionYears,
        alternateIds: alternateIds || [],
        associatedOrgs: associatedOrgsRaw ? associatedOrganizations : toAssociatedOrgs(associatedOrganizations),
        associatedPeople: (associatedPeople as ContactInput[]) || [],
        productionDates: productionDates?.dateRange?.start
            ? productionDates
            : { ...EMPTY_PRODUCTION_DATES.productionDates },
        locations: locations as LocationInput[],
        managedProduction: managedProduction,
        releaseDate,
        showAttributes: Array.isArray(showAttributes) ? showAttributes.filter((attr) => attr?.key && attr?.value) : [],
        keyPeople: (keyPeople as ContactInput[]) || [],
        projectStatus
        // projectOwner: owner
    };
};

export const fromInput = (id: string, entityInput: ProductionInput): Production => {
    return {
        about: entityInput.summary?.about || '',
        alternateIds: entityInput.alternateIds,
        associatedOrgs: entityInput.associatedOrgs as ProductionOrganizationInfo[],
        associatedPeople: entityInput.associatedPeople,
        budget: entityInput.budget,
        genre: entityInput.genre,
        headline: entityInput.summary?.headline || '',
        identity: { id: id },
        name: entityInput.publicTitle,
        otherTitles: entityInput.otherTitles,
        productionDates: entityInput.productionDates,
        productionGroup: entityInput.productionGroup,
        productionStatus: entityInput.productionStatus,
        productionType: entityInput.productionType,
        productionYears: entityInput.productionYears,
        publicTitle: entityInput.publicTitle,
        releaseDate: entityInput.releaseDate,
        releaseYear: toYearFromISOString(entityInput.releaseDate),
        locations: entityInput.locations,

        showAttributes: entityInput.showAttributes
    };
};

export const productionListeners: Listener[] = [
    ...getDateRangeListeners('productionDates.dateRange'),
    {
        prop: 'productionGroup.productionReference',
        func: (name, newValue, newState) => {
            set(newState, 'productionGroup.label', newValue.label);
        }
    },
    {
        prop: [
            `productionDates.dateRange.start`,
            `productionDates.dateRange.value`,
            `productionDates.dateRange.dateTimeDuration.unit`,
            `productionDates.dateRange.dateTimeDuration.value`,
            `$productionDates.dateRange.end`
        ],
        func: (_name, newValue, newState) => {
            recalculateProductionYears('productionDates.dateRange', newValue, newState);
        }
    }
];

export type ProductionTypeSuperset = Production & ProductionInput;

// ToDo: this is almost same as available in CreateBlankProductionForm, so refactor this with CreateBlankProductionForm refactoring
export const productionFormInput = (values: ProductionWithReference) => {
    const productionGroup =
        {
            label: values.productionGroup?.label,
            productionReference: values?.productionGroup
        } || {};
    const savedEntity: ProductionInput = {
        publicTitle: values.name.label,
        summary: {
            headline: values.headline || '',
            about: values.about || ''
        },
        budget: {
            currencyCode: values.budget.currencyCode || l10n.defaultCurrency,
            nanos: values.budget.nanos || undefined,
            units: values.budget.units || undefined
        },
        productionType: values.productionType || '',
        productionGroup,
        projectStatus: values.projectStatus || ''
    };
    savedEntity.identity = {
        id: values?.identity?.id
    };
    savedEntity.managedProduction = true;
    if (values.releaseDate) {
        savedEntity.releaseDate = values.releaseDate;
    }
    if (values.productionYears) {
        savedEntity.productionYears = values.productionYears;
    }
    if (values.otherTitles && values.otherTitles.length) {
        savedEntity['otherTitles'] = values.otherTitles.filter((item) => item.value);
    }
    if (values?.genre?.length) {
        savedEntity.genre = values.genre;
    }
    if (typeof values.productionStatus === 'string' && values.productionStatus) {
        savedEntity.productionStatus = values.productionStatus;
    }
    if (values.locations && values.locations.length) {
        const locs = values.locations.map((location) => {
            return { ...location, primary: location.primary || false };
        });
        savedEntity.locations = locs as LocationInput[];
    }
    if (!isEmpty(values.alternateIds)) {
        savedEntity.alternateIds = values.alternateIds;
    }
    if (values.associatedOrgs && values.associatedOrgs.length) {
        savedEntity.associatedOrgs = toAssociatedOrgs(values.associatedOrgs);
    }
    if (values.associatedPeople && values.associatedPeople.length) {
        savedEntity.associatedPeople = toAssociatedOrgs(values.associatedPeople);
    }
    if (values?.productionDates?.dateRange.start) {
        savedEntity.productionDates = values.productionDates;
    }
    if (values.keyPeople && values.keyPeople.length) {
        savedEntity.keyPeople = values.keyPeople as ContactInput[];
    }
    //we have to clear empty attribute values because we have validation on the backend for that.
    if (Array.isArray(values?.showAttributes) && values.showAttributes.length > 0) {
        savedEntity.showAttributes = values.showAttributes.filter((attr) => attr?.key && attr?.value);
    }

    return { ...savedEntity };
};
