import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ShrinkIcon = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 11H13V4H15V9H20V11Z" fill="currentColor" fillOpacity="1" />
            <path d="M4 13H11V20H9V15H4V13Z" fill="currentColor" fillOpacity="1" />
        </svg>
    </>,
    'ShrinkIcon'
);
