import { useContext, useMemo } from 'react';
import {
    MARKETPLACE,
    PLANNING,
    PRODUCTION_PROFESSIONAL,
    PROFILES,
    RESERVATION_PROFESSIONAL,
    SUPPLIER
} from '../../common/nav/apps';
import { UserContext } from '../../common/auth/UserContext';
import { toLocaleDateString } from '../../common/utils/dateTime';
import { H } from '../../common/Layout';
import { Icons } from '../../common/icons/Icons';
import React from 'react';
import { useI18n } from '../../common/i18n/I18n';
import CustomAvatar from '../../common/components/CustomAvatar';

export default (isProductionMode: boolean) => {
    const { application } = useContext(UserContext);

    const headerNames = {
        title: useI18n('grid.header.title'),
        aka: useI18n('grid.header.AKA'),
        type: useI18n('grid.header.type'),
        status: useI18n('grid.header.status'),
        projectStatus: useI18n('grid.header.project.status'),
        associatedTitle: useI18n('grid.header.associated.title'),
        releaseDate: useI18n('grid.header.release.date'),
        releaseYear: useI18n('grid.header.release.year'),
        productionYears: useI18n('grid.header.production.years'),
        season: useI18n('grid.header.season'),
        createdBy: useI18n('grid.header.createdBy'),
        createdOn: useI18n('grid.header.createdOn'),
        updatedOn: useI18n('grid.header.updatedOn')
    };

    const columnsAll = useMemo(() => {
        return {
            managed: {
                field: 'managed',
                headerName: '',
                minWidth: 50,
                width: 50,
                resizable: false,
                disableReorder: true,
                hide: !isProductionMode,
                renderCell: (params) => {
                    return (
                        params.row.managedProduction && (
                            <H sx={{ gap: 0.5, justifyContent: 'center' }}>
                                <Icons.Workspaces color={'success'} fontSize={'small'} />
                            </H>
                        )
                    );
                }
            },
            avatar: {
                field: 'avatar',
                headerName: '',
                minWidth: 50,
                width: 50,
                resizable: false,
                disableReorder: true,
                renderCell: (params) => {
                    return (
                        <H sx={{ gap: 0.5, justifyContent: 'center' }}>
                            <CustomAvatar label={params.row.name} />
                        </H>
                    );
                }
            },
            name: { field: 'publictitle', headerName: headerNames.title, flex: 2, minWidth: 100 },
            aka: { field: 'otherTitles', headerName: headerNames.aka, flex: 1, minWidth: 100 },
            type: { field: 'productiontype', headerName: headerNames.type, flex: 1, minWidth: 100 },
            productionStatus: {
                field: 'productionStatus',
                headerName: headerNames.status,
                flex: 1,
                minWidth: 100
            },
            projectStatus: {
                field: 'projectStatus',
                headerName: headerNames.projectStatus,
                hide: isProductionMode,
                flex: 1,
                minWidth: 100
            },
            productionGroup: {
                field: 'productionGroup',
                headerName: headerNames.associatedTitle,
                flex: 1,
                hide: true,
                minWidth: 100
            },
            releaseDate: {
                field: 'releaseDate',
                headerName: headerNames.releaseDate,
                flex: 1,
                hide: true,
                minWidth: 100,
                type: 'date',
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            releaseYear: {
                field: 'releaseYear',
                headerName: headerNames.releaseYear,
                flex: 1,
                hide: true,
                minWidth: 100
            },
            productionYears: {
                field: 'productionYears',
                headerName: headerNames.productionYears,
                flex: 1,
                minWidth: 100
            },
            season: {
                field: 'seasonNumber',
                headerName: headerNames.season,

                flex: 1,
                minWidth: 100
            },
            createdOn: {
                field: 'createdOn',
                headerName: headerNames.createdOn,
                flex: 1,
                type: 'date',
                minWidth: 100,
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            updatedOn: {
                field: 'updatedOn',
                headerName: headerNames.updatedOn,
                type: 'date',
                flex: 1,
                minWidth: 100,
                valueFormatter: (params) => toLocaleDateString(params?.value)
            },
            createdByName: {
                field: 'createdByName',
                headerName: headerNames.createdBy,
                flex: 1,
                minWidth: 100
            }
        };
    }, []);

    const columnsByApp = useMemo(() => {
        return {
            [MARKETPLACE.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [SUPPLIER.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [PLANNING.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.projectStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [PROFILES.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [PRODUCTION_PROFESSIONAL.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ],
            [RESERVATION_PROFESSIONAL.id]: [
                columnsAll.managed,
                columnsAll.avatar,
                columnsAll.name,
                columnsAll.aka,
                columnsAll.type,
                columnsAll.productionStatus,
                columnsAll.productionGroup,
                columnsAll.releaseDate,
                columnsAll.releaseYear,
                columnsAll.productionYears,
                columnsAll.season,
                columnsAll.createdOn,
                columnsAll.updatedOn,
                columnsAll.createdByName
            ]
        };
    }, []);

    return columnsByApp[application.id];
};
