import { Location, LOCATION_FIELDS } from '../location';
import { Contact, ContactInfo, Name } from '../types';
import {
    ApolloClient,
    createHttpLink,
    DocumentNode,
    gql,
    InMemoryCache,
    useLazyQuery,
    useMutation,
    useQuery
} from '@apollo/client';
import { authLink } from '../../auth/api';
import { cloneDeep, isEmpty, set } from 'lodash';
import { DateRange } from '../../utils/dateTime';
import { Reference } from '../../reference/reference';
import { ContactInfoFragment, NameFragment, ReferenceFragment } from '../../list/fragments';
import { useContext } from 'react';
import { UserContext } from '../../auth/UserContext';
import { constants } from '../../constants';
import { useMutationWithContext } from '../../hooks/useMutationWithContext';
import {
    CreativeCreditInput,
    DateRangeInput,
    LocationInput,
    Identity,
    PersonInput
} from 'sr-types/lib/person/v1/graphql';
import { failedToastMessage, successToastMessage } from '../../utils/commonUtils';

import { searchClient, slimResultsQuery } from '../../list/slimQuery';
import { ProductionInput } from 'sr-types/lib/production/v1/graphql';

export const PROFESSIONAL_ROLES_ID = 'roles';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/person/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export interface Person {
    identity?: Identity;
    name: Name;
    contactInfo: ContactInfo;
    creativeCredits?: CreativeCredit[];
    experiences?: Experience[];
    reference?: Reference;
}

export type PersonOption = {
    identity?: Identity;
    name: Name;
    contactInfo: ContactInfo;
    personReference: Reference;
    organization: string;
    organizationReference: Reference;
    productionReference: Reference;
    productionDepartment: string;
    role: string;
    title: string;
    department: string;
    production: string;
    productionRole: string;
    createOption?: boolean;
};

export interface Experience {
    experienceName: string;
    jobType?: string;
    dateRange?: DateRange;
    professionalRoles: string[];
    location?: Location;
    keyExperience?: boolean;
    organizationReference?: Reference;
    title?: string;
    department?: string;
}

export interface CreativeCredit {
    showName: string;
    showReference: Reference;
    showType?: string;
    dateRange?: DateRange;
    professionalRoles: string[];
    location?: Location;
    keyCredit?: boolean;
    department?: string;
}

export const EMPTY_PERSON: PersonInput = {
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        phone: {
            number: '',
            typeString: 'Mobile'
        },
        email: {
            address: '',
            typeString: 'Business',
            verified: false
        }
    },
    creativeCredits: null,
    experiences: null
};

export const EMPTY_PERSON_FOR_ORG: PersonInput = {
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        phone: {
            number: '',
            typeString: 'Mobile'
        },
        email: {
            address: '',
            typeString: 'Business',
            verified: false
        }
    },
    creativeCredits: null,
    experiences: null
};

export const optionToContact = (contact: PersonOption) => {
    const newContact: PersonInput = { ...EMPTY_PERSON };
    newContact.identity = contact.identity;
    newContact.name = contact.name;
    newContact.contactInfo = {
        phone: contact?.contactInfo?.phone
            ? {
                  number: contact?.contactInfo?.phone?.number || '',
                  typeString: 'Mobile'
              }
            : undefined,
        email: {
            address: contact?.contactInfo?.email?.address || '',
            typeString: 'Business',
            verified: false
        }
    };
    newContact.experiences = [
        {
            experienceName: contact?.organization,
            professionalRoles: isEmpty(contact?.role) ? [] : [contact.role],
            title: contact?.title,
            department: contact?.department,
            organizationReference: contact?.organizationReference
        }
    ];
    newContact.creativeCredits = [
        {
            showName: contact?.production,
            professionalRoles: isEmpty(contact?.productionRole) ? [] : [contact.productionRole],
            showReference: contact?.productionReference
        }
    ];
    return newContact;
};

export const rowsToContact = (rows, colDefs) => {
    return rows.map((row) => {
        const contactEntity = {
            name: {
                firstName: row.firstName,
                lastName: row.lastName
            },
            contactInfo: {
                phone: row.phone
                    ? {
                          number: row.phone || '',
                          typeString: 'Mobile'
                      }
                    : undefined,
                email: {
                    address: row.email || '',
                    typeString: 'Business',
                    verified: false
                }
            },
            summary: {
                about: row.about || '',
                headline: row.headline || ''
            },
            experiences: [
                {
                    experienceName: row.organization,
                    professionalRoles: isEmpty(row.organizationRole) ? [] : [row.organizationRole],
                    title: row.title,
                    department: row.department
                }
            ],
            creativeCredits: [
                {
                    showName: row.production,
                    professionalRoles: isEmpty(row.productionRole) ? [] : [row.productionRole]
                }
            ]
        };
        if (row.ID) set(contactEntity, 'identity', { id: row.ID });
        return contactEntity;
    });
};

export const getPersonOption = (person) => {
    return {
        id: person.id,
        name: person.name,
        label: person.name,
        contactInfo: person.contactInfo,
        role: person.role,
        productionRole: person.productionRole,
        ref: { ...person.reference, label: person.name }
    };
};

export const detailsQuery: DocumentNode = gql`
    query Person($id: ID!) {
        person(id: $id) {
            identity {
                id
            }
            name {
                ...Name
            }
            alternateIds {
                label
                value
                url
            }
            deiInfo {
                birthYear
                disabilityStatus
                ethnicity
                gender
                socioEconomicStatus
            }
            customFields {
                name
                fieldType
                value
                fieldDefinitionReference {
                    ...Reference
                }
            }
            associatedFieldDefinitionReferences {
                ...Reference
            }
            memberships {
                organizationReference {
                    ...Reference
                }
            }
            resumeReference {
                ...Reference
            }
            profileImage
            profileImageReference {
                id
                label
            }
            summary {
                headline
                about
            }
            contactInfo {
                ...ContactInfo
            }
            professionalRoles
            isSharedInDirectory
            location {
                ...LocationFields
            }
            creativeCredits {
                location {
                    ...LocationFields
                }
                showName
                showType
                showReference {
                    ...Reference
                    label
                }
                dateRange {
                    start
                    end
                }
                keyCredit
                professionalRoles
                department
            }
            experiences {
                location {
                    ...LocationFields
                }
                experienceName
                organizationReference {
                    id
                    label
                    type
                    uriPrefix
                    eventId
                    deleted
                }
                jobType
                dateRange {
                    start
                    end
                }
                keyExperience
                professionalRoles
                title
                department
            }
            reference {
                ...Reference
            }
            security {
                allowedActions
            }
            businessContacts {
                name {
                    firstName
                    lastName
                }
                personReference {
                    ...Reference
                }
                role
                contactInfo {
                    ...ContactInfo
                }
                isPrimary
                profileImageReference {
                    ...Reference
                }
            }
        }
    }
    ${LOCATION_FIELDS}
    ${ReferenceFragment}
    ${ContactInfoFragment}
    ${NameFragment}
`;

export const experienceAndCreativeCreditsQuery: DocumentNode = gql`
    query Person($id: ID!) {
        person(id: $id) {
            creativeCredits {
                location {
                    ...LocationFields
                }
                showName
                showType
                showReference {
                    ...Reference
                }
                dateRange {
                    start
                    end
                }
                keyCredit
                professionalRoles
                department
            }
            experiences {
                location {
                    ...LocationFields
                }
                experienceName
                organizationReference {
                    ...Reference
                }
                jobType
                dateRange {
                    start
                    end
                }
                keyExperience
                professionalRoles
                title
                department
            }
        }
    }
    ${LOCATION_FIELDS}
    ${ReferenceFragment}
`;

export const personContactDetailsQuery: DocumentNode = gql`
    query Person($id: ID!) {
        person(id: $id) {
            name {
                ...Name
            }
            contactInfo {
                ...ContactInfo
            }
            profileImage
        }
    }
    ${NameFragment}
    ${ContactInfoFragment}
`;

export const bulkAutoCreateQuery: DocumentNode = gql`
    mutation BulkAutoCreatePerson($input: [PersonInput]) {
        bulkAutoCreatePerson(input: $input) {
            id
            reference {
                id
                uriPrefix
                type
            }
            errors {
                field
                message
            }
        }
    }
`;

export const autoCreateQuery: DocumentNode = gql`
    mutation AutoCreatePerson($input: PersonInput!) {
        autoCreatePerson(input: $input) {
            id
            reference {
                ...Reference
            }
            errors {
                field
                message
            }
        }
    }
    ${ReferenceFragment}
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeletePerson($id: ID!) {
        deletePerson(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveQuery: DocumentNode = gql`
    mutation SavePerson($input: PersonInput!) {
        savePerson(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const bulkSaveQuery: DocumentNode = gql`
    mutation BulkSavePerson($input: BulkSavePersonRequest!) {
        bulkSavePerson(input: $input) {
            createdCount
            updatedCount
            unprocessedCount
            results {
                id
                errors {
                    field
                    message
                }
                reference {
                    id
                }
            }
            logFile {
                id
                uriPrefix
            }
        }
    }
`;

export const savePersonWithBasicInfo: DocumentNode = gql`
    mutation SavePersonWithBasicProfile($input: PersonBasicProfileInput!) {
        savePersonWithBasicProfile(input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const saveCreativeCreditToPerson: DocumentNode = gql`
    mutation saveCreativeCreditToPerson($id: ID!, $input: SaveCreativeCreditInput!) {
        saveCreativeCreditToPerson(id: $id, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const removeCreativeCreditsFromPerson: DocumentNode = gql`
    mutation RemoveCreativeCreditFromPerson($id: ID!, $input: CreativeCreditInput) {
        removeCreativeCreditFromPerson(id: $id, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveProfessionalExperienceToPerson: DocumentNode = gql`
    mutation SaveExperienceToPerson($id: ID!, $input: SaveExperienceInput!) {
        saveExperienceToPerson(id: $id, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const removeProfessionalExperienceFromPerson: DocumentNode = gql`
    mutation RemoveExperienceFromPerson($id: ID!, $input: ExperienceInput!) {
        removeExperienceFromPerson(id: $id, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const saveResumeMutation: DocumentNode = gql`
    mutation SaveResume($personId: ID!, $input: ReferenceInput, $piiConsent: Boolean) {
        saveResume(personId: $personId, input: $input, piiConsent: $piiConsent) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const saveCreativeCreditsMutation: DocumentNode = gql`
    mutation SaveCreativeCredits($id: ID!, $input: SaveCreativeCreditsInput) {
        saveCreativeCredits(id: $id, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const removeResumeMutation: DocumentNode = gql`
    mutation RemoveResume($personId: ID!) {
        removeResume(personId: $personId) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const mergeAnnotationMutation: DocumentNode = gql`
    mutation MergeAnnotation($personId: ID!, $annotationId: ID!) {
        mergeAnnotation(personId: $personId, annotationId: $annotationId) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const useSavePerson = (ownerId: string) => {
    const [save, { loading: isPersonSaving }] = useMutation(saveQuery, {
        client,
        context: {
            headers: {
                ownerId
            }
        }
    });

    const savePerson = (input: PersonInput) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    input
                }
            })
                .then((res) => resolve(res))
                .catch((err) => reject(err));
        });
    };
    return { savePerson, isPersonSaving };
};

export const useUpdatePersonCreativeCredit = () => {
    const [save, { loading: isCreativeCreditSaving }] = useMutationWithContext(saveCreativeCreditToPerson, client);

    const updatePersonCreativeCredit = ({ id, input }) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    id,
                    input
                }
            })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                });
        });
    };
    return { updatePersonCreativeCredit, isCreativeCreditSaving };
};

export const useRemoveCreativeCreditFromPerson = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [removeCreativeCredit, { loading: isRemoving }] = useMutation(removeCreativeCreditsFromPerson, { client });

    const removeCreativeCreditFromPerson = ({ id, input }) => {
        return new Promise<void>((resolve, reject) => {
            removeCreativeCredit({
                variables: {
                    id,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then(() => {
                    successToastMessage('person.remove.creative.credit.success');
                    resolve();
                })
                .catch(() => {
                    failedToastMessage('person.remove.creative.credit.error');
                    reject();
                })
                .finally(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                });
        });
    };

    return { removeCreativeCreditFromPerson, isRemoving };
};

export const useUpdateProfessionalExperiences = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [updateProfessionalExperiences, { loading: isSaving }] = useMutationWithContext(
        saveProfessionalExperienceToPerson,
        client
    );

    const updatePersonExperience = ({ id, input }) => {
        return new Promise<void>((resolve, reject) => {
            updateProfessionalExperiences({
                variables: {
                    id,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    failedToastMessage('person.professional.experience.form.add.error');
                    reject();
                })
                .finally(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                });
        });
    };

    return { updatePersonExperience, isSaving };
};

export const useRemovePersonExperience = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [removeExperience, { loading: isRemoving }] = useMutation(removeProfessionalExperienceFromPerson, { client });

    const removePersonExperience = ({ id, input }) => {
        return new Promise<void>((resolve, reject) => {
            removeExperience({
                variables: {
                    id,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then(() => {
                    successToastMessage('person.professional.experience.form.remove.success');
                    resolve();
                })
                .catch(() => {
                    failedToastMessage('person.professional.experience.form.remove.error');
                    reject();
                })
                .finally(() => {
                    client.refetchQueries({
                        include: [detailsQuery]
                    });
                });
        });
    };

    return { removePersonExperience, isRemoving };
};

export const stateToPerson = (state: PersonInput): PersonInput => {
    const creativeCredit = state.creativeCredits && createCreativeCreditInputObject(state.creativeCredits);
    const experience = state.experiences && createProfessionalExpriencesInputObject(state.experiences);
    const cloneState: PersonInput = cloneDeep({
        ...state,
        experiences: [experience],
        creativeCredits: [creativeCredit]
    });
    if (!state?.contactInfo?.phone?.number) {
        delete cloneState?.contactInfo?.phone;
    }
    if (!state?.contactInfo?.email?.address) {
        delete cloneState?.contactInfo?.email;
    }
    return cloneState;
};

export const contactToPerson = (contact: Contact): Person => {
    return {
        name: {
            firstName: contact.name.firstName,
            lastName: contact.name.lastName
        },
        contactInfo: {
            email:
                contact.contactInfo && contact.contactInfo?.email?.address
                    ? {
                          address: contact.contactInfo.email.address,
                          typeString: contact.contactInfo.email.typeString,
                          verified: contact.contactInfo.email.verified
                      }
                    : undefined
            // phone: {
            //     typeString: contact.contactInfo.phone.typeString,
            //     number: contact.contactInfo.phone.number
            // }
        },
        reference: contact.personReference
    };
};

export const personToContact = (person: Person): Contact => {
    return {
        name: {
            firstName: person.name.firstName,
            lastName: person.name.lastName
        },
        contactInfo: {
            email: {
                address: person.contactInfo.email.address,
                typeString: person.contactInfo.email.typeString,
                verified: person.contactInfo.email.verified
            }
            // phone: {
            //     typeString: contact.contactInfo.phone.typeString,
            //     number: contact.contactInfo.phone.number
            // }
        },
        personReference: person.reference
    };
};

export const useGetPersonDetails = (personId: string, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: personDetails,
        loading: personDetailsLoading,
        refetch: personDetailsRefetch
    } = useQuery(detailsQuery, {
        variables: { id: personId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: skip,
        notifyOnNetworkStatusChange: true
    });

    return { personDetails, personDetailsLoading, personDetailsRefetch };
};

export const useGetCreativeCreditsAndExperiences = (personId: string, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: creditsAndExperienceDetails,
        loading: creditsAndExperienceDetailsLoading,
        refetch: creditsAndExperienceDetailsRefetch
    } = useQuery(experienceAndCreativeCreditsQuery, {
        variables: { id: personId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip,
        notifyOnNetworkStatusChange: true
    });

    return { creditsAndExperienceDetails, creditsAndExperienceDetailsLoading, creditsAndExperienceDetailsRefetch };
};

export const useGetPersonContactInfo = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [getPersonContactInfo, { loading: contactInfoLoading }] = useLazyQuery(personContactDetailsQuery, {
        client,
        context: { headers: { ownerId: activeOrganizationAccount } },
        fetchPolicy: constants.apolloFetchPolicy
    });

    const getContactInfo = (id: string) => {
        return new Promise((resolve, reject) => {
            getPersonContactInfo({
                variables: {
                    id
                }
            })
                .then((response) =>
                    !response.data.errors ? resolve(response.data.person) : reject(response.data.errors)
                )
                .catch((err) => reject(err));
        });
    };
    return { getContactInfo, contactInfoLoading };
};

export const creativeCreditToInput = (creativeCredit) => {
    const { location, dateRange, ...state } = creativeCredit;
    delete state.__typename;

    const stateLocationDestructured: LocationInput = location
        ? {
              geoCoords: location.geoCoords
                  ? {
                        lat: location.geoCoords?.lat,
                        lon: location.geoCoords?.lon
                    }
                  : null,
              address: location.address,
              label: location.label,
              primary: location.primary
          }
        : null;

    const dateRangeDestructured: DateRangeInput = dateRange.start !== '' && dateRange.end !== '' ? dateRange : null;

    const createState: CreativeCreditInput = {
        location: stateLocationDestructured,
        dateRange: dateRangeDestructured,
        ...state
    };
    return createState;
};

export const createCreativeCreditInputObject = (creativeCredit) => {
    return {
        ...creativeCredit,
        department: creativeCredit.department ? creativeCredit.department : '',
        dateRange:
            creativeCredit.dateRange && creativeCredit.dateRange.start && creativeCredit.dateRange.end
                ? creativeCredit.dateRange
                : null,
        showName:
            creativeCredit.showReference && creativeCredit.showReference.label
                ? creativeCredit.showReference.label
                : creativeCredit.showName || '',

        showType: creativeCredit.showType ? creativeCredit.showType : ''
    };
};

export const createProfessionalExpriencesInputObject = (experience) => {
    return {
        ...experience,
        dateRange:
            experience.dateRange && experience.dateRange.start && experience.dateRange.end
                ? experience.dateRange
                : null,
        experienceName: experience.organizationReference?.label || '',
        department: experience.department || ''
    };
};

export const getConflictedUserDetails = (variables, ownerId) => {
    return searchClient.query({
        query: slimResultsQuery('Person'),
        variables,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId
            }
        }
    });
};

export const updateProduction = (saveMutation, modified: ProductionInput) => {
    saveMutation({
        variables: {
            input: modified
        }
    })
        .then(() => {
            successToastMessage('person.update.creative.credits.production.type.success');
        })
        .catch(() => {
            failedToastMessage('person.update.creative.credits.production.type.error');
        });
};
