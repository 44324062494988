import { createSvgIcon } from '@mui/material';
import React from 'react';

export const InquiryIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 13H3V11H5V13Z" fill="currentColor" fillOpacity="0.56" />
        <path d="M5 17H3V15H5V17Z" fill="currentColor" fillOpacity="0.56" />
        <path d="M5 9H3V7H5V9Z" fill="currentColor" fillOpacity="0.56" />
        <path d="M21 13H7V11H21V13Z" fill="currentColor" fillOpacity="0.56" />
        <path
            d="M14.8027 17H7V15H16.6822C15.9121 15.512 15.2671 16.1972 14.8027 17Z"
            fill="currentColor"
            fillOpacity="0.56"
        />
        <path d="M7 9V7H21V9H7Z" fill="currentColor" fillOpacity="0.56" />
        <path
            d="M24 20.5714H20.5714V24H19.4286V20.5714H16V19.4286H19.4286V16H20.5714V19.4286H24V20.5714Z"
            fill="currentColor"
            fillOpacity="0.56"
        />
    </svg>,
    'InquiryIcon'
);
