import { arrayToSelectOptions } from '../../../common/utils/arrays';
import { budgetCategories, resourceHiredState, resourceStatus } from '../../../person/mockData';

export const BUDGET_CATEGORIES = arrayToSelectOptions(budgetCategories);
export const RESOURCE_STATUS = arrayToSelectOptions(resourceStatus);
export const RESOURCE_STATE = arrayToSelectOptions(resourceHiredState);
export const TEMPLATE_ID = 'templateId';

export const RESOURCE_FIELD_NAMES = {
    label: 'label',
    type: 'type',
    resourceCategory: 'resourceCategory',
    role: 'role',
    budgetCategory: 'budgetCategory',
    status: 'status',
    locations: 'locations',
    summaryAbout: 'summary.about',
    resourceScheduleDateRangeDurationSpecified: 'resourceSchedule.dateRange.durationSpecified',
    resourceScheduleDateRange: 'resourceSchedule.dateRange',
    scheduleSection: 'schedule.section',
    subType: 'subType',
    hiringInfoSection: 'hiring.info.section',
    acquisitionLead: 'acquisitionLead',
    hiringTeamMembers: 'hiringTeamMembers',
    hiredPerson: 'hiredPerson',
    hiredOrganization: 'hiredOrganization',
    state: 'state'
};

export const RESOURCE_TYPE = {
    crew: 'Crew',
    cast: 'Cast',
    hiring: 'hiring',
    procurement: 'procurement'
};
