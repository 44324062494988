import React, { useContext, useMemo, useRef } from 'react';
import { Alert, AlertTitle, Button, Divider, IconButton, Typography } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid-premium';
import { cloneDeep } from 'lodash';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import ScheduleWidget from '../../common/form/widgets/ScheduleWidget';
import { Icons } from '../../common/icons/Icons';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { useStyling } from '../../common/Theme';
import { ActivityOrder, Activity_Field_Names, EMPTY_DEPENDENCIES } from '../helpers/activityUtils';
import PersonOptionRenderer from '../../shortlists/form/PersonOptionRenderer';
import { FormContext } from '../../common/form/FormContext';
import { ProductionOptionRenderer } from '../../supply/booking/ProductionOptionRenderer';
import { quickAddProduction } from '../helpers/QuickAddProduction';
import { useMutationWithContext } from '../../common/hooks/useMutationWithContext';
import { autoCreateQuery as autoCreateProductionQuery, client as productionClient } from '../helpers/production';

export type ActivityFormItemType = {
    showBasic: boolean;
    isResourceForm: boolean;
    onCreateOwner?: any;
    resourceOptions?: any;
    activitiesLoading?: boolean;
    activityOptions?: any;
    unitOptions?: any;
    unitsLoading?: boolean;
    disabledFields?: Record<string, boolean>;
    hiddenFields?: Record<string, boolean>;
    hasError?: boolean;
};

export const ActivityFormItems = ({
    showBasic,
    isResourceForm,
    onCreateOwner,
    resourceOptions,
    activitiesLoading,
    activityOptions,
    unitOptions,
    unitsLoading,
    disabledFields = {},
    hiddenFields = {},
    hasError = false
}: ActivityFormItemType) => {
    const { setState, state } = useContext(FormContext);
    const { theme } = useStyling();
    const usedOptions = useMemo(() => {
        if (state?.dependencies) return Object.keys(state.dependencies);
    }, [state?.dependencies]);
    const lastElement = useRef(null);
    const [saveProduction] = useMutationWithContext(autoCreateProductionQuery, productionClient);
    const scrollToBottom = () => {
        lastElement.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const locationsLabel = useI18n('production.activityForm.locations');
    const categoryLabel = useI18n('production.activityForm.category');
    const associateWithResourceLabel = useI18n('production.activityForm.associateWithResource');
    const productsLabel = useI18n('common.label.products');
    const activeOrganizationOwnerLabel = useI18n('production.activityForm.activityOrganizationOwner');
    const activityErrorTitleLabel = useI18n('form.activity.error.title');
    const activityErrorDescriptionLabel = useI18n('form.activity.error.description');

    return (
        <FormPanel>
            <FormContainer>
                {hasError && (
                    <FormItem>
                        <Alert severity="error" sx={{ width: '100%' }}>
                            <AlertTitle>{activityErrorTitleLabel}</AlertTitle>
                            {activityErrorDescriptionLabel}
                        </Alert>
                    </FormItem>
                )}
                <FormItem hidden={hiddenFields[Activity_Field_Names.basicSection]}>
                    <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                        <I18n token="production.activityForm.basic" />
                    </Typography>
                </FormItem>
                <FormItem half hidden={hiddenFields[Activity_Field_Names.name]}>
                    <FormWidget
                        name={Activity_Field_Names.name}
                        label={useI18n('production.activityForm.name')}
                        disabled={disabledFields[Activity_Field_Names.name]}
                    />
                </FormItem>
                <FormItem half hidden={hiddenFields[Activity_Field_Names.activityType]}>
                    <FormWidget
                        name={Activity_Field_Names.activityType}
                        component="MetadataAutocomplete"
                        label={useI18n('production.activityForm.type')}
                        metadataName={'ActivityType'}
                        disabled={disabledFields[Activity_Field_Names.activityType]}
                    />
                </FormItem>
                <FormItem hidden={hiddenFields[Activity_Field_Names.basicSection]}>
                    <Divider sx={{ width: '100%' }} />
                </FormItem>
                <FormItem hidden={hiddenFields[Activity_Field_Names.scheduleSection]}>
                    <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                        <I18n token="production.activityForm.schedule" />
                    </Typography>
                </FormItem>
                <FormItem
                    span={12}
                    sx={{ mb: 2 }}
                    hidden={
                        state?.activityType === 'Milestone' ||
                        hiddenFields[Activity_Field_Names['dateRange.durationSpecified']]
                    }
                >
                    <FormWidget
                        hideHelperText
                        component={'Switch'}
                        name={Activity_Field_Names['dateRange.durationSpecified']}
                        label={useI18n('production.activityForm.specifyDuration')}
                        disabled={disabledFields[Activity_Field_Names['dateRange.durationSpecified']]}
                    />
                </FormItem>
                <ScheduleWidget
                    showFullSchedule={true}
                    name={Activity_Field_Names['dateRange']}
                    allowedUnits={['hour', 'day', 'week']}
                    isMilestone={state?.activityType === 'Milestone'}
                />
                {!showBasic && (
                    <>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.scheduleSection]}>
                            <Divider sx={{ width: '100%' }} />
                        </FormItem>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.detailsSection]}>
                            <Typography sx={{ color: theme.palette.primary.main, my: 1 }}>
                                <I18n token="production.activityForm.details" />
                            </Typography>
                        </FormItem>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.productionReference]}>
                            <FormWidget
                                name={Activity_Field_Names.productionReference}
                                addOption
                                component="ReferenceAutocomplete"
                                entity="Production"
                                label={<I18n token="production.form.field.name" />}
                                OptionRenderer={ProductionOptionRenderer}
                                onCreateHandle={quickAddProduction}
                                saveMutation={saveProduction}
                                disabled={disabledFields[Activity_Field_Names.productionReference]}
                                preload={false}
                            />
                        </FormItem>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.productReferences]}>
                            <FormWidget
                                component="ReferenceAutocomplete"
                                name={Activity_Field_Names.productReferences}
                                entity="Product"
                                label={productsLabel}
                                multiple
                                disabled={disabledFields[Activity_Field_Names.productReferences]}
                                preload={false}
                            />
                        </FormItem>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.organizationReference]}>
                            <FormWidget
                                name={Activity_Field_Names.organizationReference}
                                component="ReferenceAutocomplete"
                                entity="Organization"
                                label={activeOrganizationOwnerLabel}
                                disabled={disabledFields[Activity_Field_Names.organizationReference]}
                                preload={false}
                            />
                        </FormItem>
                        <FormItem half hidden={hiddenFields[Activity_Field_Names.locations]}>
                            <FormWidget
                                component="Location"
                                name={Activity_Field_Names.locations}
                                label={locationsLabel}
                                multiple
                                disabled={disabledFields[Activity_Field_Names.locations]}
                            />
                        </FormItem>
                        <FormItem half hidden={hiddenFields[Activity_Field_Names.category]}>
                            <FormWidget
                                component="MetadataAutocomplete"
                                metadataName="Category"
                                name={Activity_Field_Names.category}
                                label={categoryLabel}
                                disabled={disabledFields[Activity_Field_Names.category]}
                            />
                        </FormItem>
                        {!isResourceForm && (
                            <FormItem hidden={hiddenFields[Activity_Field_Names.associateWithResource]}>
                                <FormWidget
                                    component="Autocomplete"
                                    name={Activity_Field_Names.associateWithResource}
                                    label={associateWithResourceLabel}
                                    options={resourceOptions}
                                    multiple
                                    disabled={disabledFields[Activity_Field_Names.associateWithResource]}
                                />
                            </FormItem>
                        )}
                        <FormItem hidden={hiddenFields[Activity_Field_Names.trackOwnerStatusSection]}>
                            <Divider sx={{ width: '100%' }} />
                        </FormItem>
                        <FormItem hidden={hiddenFields[Activity_Field_Names.trackOwnerStatusSection]}>
                            <Typography sx={{ color: theme.palette.primary.main, my: 1, textTransform: 'uppercase' }}>
                                <I18n token="production.form.title.track" />
                            </Typography>
                        </FormItem>
                        <FormItem half hidden={hiddenFields[Activity_Field_Names.activityOwner]}>
                            <FormWidget
                                component="ReferenceAutocomplete"
                                entity="Person"
                                name={Activity_Field_Names.activityOwner}
                                label={<I18n token="production.form.field.owner" />}
                                addOption
                                OptionRenderer={PersonOptionRenderer}
                                getOption={(item) => {
                                    return {
                                        id: item.id,
                                        name: item.name,
                                        role: item.role,
                                        label: item.name,
                                        ref: { ...item.reference, label: item.name },
                                        professionalRoles: item.professionalRoles
                                    };
                                }}
                                onCreateHandle={onCreateOwner}
                                preload={false}
                                disabled={disabledFields[Activity_Field_Names.activityOwner]}
                            />
                        </FormItem>
                        <FormItem half hidden={hiddenFields[Activity_Field_Names.activityStatus]}>
                            <FormWidget
                                component="MetadataAutocomplete"
                                metadataName="Termset"
                                filters={[{ identifier: 'name', value: 'ActivityStatus' }]}
                                termset="ActivityStatus"
                                name={Activity_Field_Names.activityStatus}
                                label={<I18n token="production.form.field.status" />}
                                allowCreate
                                disabled={disabledFields[Activity_Field_Names.activityStatus]}
                            />
                        </FormItem>
                        {Object.keys(state.dependencies)
                            .filter((key) => key !== 'lastDependency')
                            .map((item) => (
                                <ActivityDependencyFormItems
                                    activitiesLoading={activitiesLoading}
                                    activityOptions={activityOptions}
                                    unitOptions={unitOptions}
                                    unitsLoading={unitsLoading}
                                    hidden={hiddenFields[Activity_Field_Names.activityDependency]}
                                    id={item}
                                    key={item}
                                    onDelete={(id) => {
                                        setState((prevVal) => {
                                            const { [id]: removedDependency, ...otherDependencies } =
                                                prevVal.dependencies;
                                            return { ...prevVal, dependencies: { ...otherDependencies } };
                                        });
                                    }}
                                />
                            ))}

                        <ActivityDependencyFormItems
                            activitiesLoading={activitiesLoading}
                            activityOptions={
                                activityOptions
                                    ? activityOptions.filter((option) => !usedOptions.includes(option.id))
                                    : []
                            }
                            unitOptions={unitOptions}
                            unitsLoading={unitsLoading}
                            hidden={hiddenFields[Activity_Field_Names.activityDependency]}
                            id={'lastDependency'}
                            onDelete={() => {
                                setState((prevVal) => {
                                    return {
                                        ...prevVal,
                                        dependencies: {
                                            ...prevVal.dependencies,
                                            ...cloneDeep(EMPTY_DEPENDENCIES)
                                        }
                                    };
                                });
                            }}
                        />
                        <FormItem hidden={hiddenFields[Activity_Field_Names.addActivityButton]}>
                            <Button
                                ref={lastElement}
                                sx={{ alignSelf: 'start' }}
                                disabled={
                                    activityOptions
                                        ? activityOptions.filter((option) => !usedOptions.includes(option.id)).length <=
                                              1 || !state.dependencies?.lastDependency?.activityRefId
                                        : true
                                }
                                onClick={() => {
                                    setState((prevVal) => {
                                        return {
                                            ...prevVal,
                                            dependencies: {
                                                ...prevVal.dependencies,
                                                ...cloneDeep(EMPTY_DEPENDENCIES),
                                                [prevVal.dependencies.lastDependency.activityRefId]: {
                                                    ...prevVal.dependencies.lastDependency
                                                }
                                            }
                                        };
                                    });
                                    setTimeout(() => {
                                        scrollToBottom();
                                    }, 300);
                                }}
                            >
                                <Icons.AddButton /> <I18n token={'production.activity'} />
                            </Button>
                        </FormItem>
                    </>
                )}
            </FormContainer>
        </FormPanel>
    );
};

const ActivityDependencyFormItems = ({
    id,
    onDelete,
    activityOptions,
    activitiesLoading,
    unitOptions,
    unitsLoading,
    hidden = false
}) => {
    const { handleChange, state } = useContext(FormContext);

    return hidden ? (
        <React.Fragment />
    ) : (
        <>
            <FormItem>
                <Divider sx={{ width: '100%', mb: 2 }} />
            </FormItem>
            <FormItem>
                <FormWidget
                    component="ToggleButtonGroup"
                    color="primary"
                    value={state?.dependencies?.[id]?.order}
                    exclusive
                    sx={{ mb: 2 }}
                    onChange={(e, val) => {
                        handleChange(`dependencies.${id}.order`, val);
                    }}
                    options={[
                        { id: ActivityOrder.predecessor, label: useI18n('form.activity.predecessor') },
                        { id: ActivityOrder.successor, label: useI18n('form.activity.successor') }
                    ]}
                ></FormWidget>
            </FormItem>
            <FormItem>
                <FormWidget
                    label={useI18n('production.activity')}
                    name={`dependencies.${id}.activityRefId`}
                    component="Autocomplete"
                    loading={activitiesLoading}
                    options={activityOptions}
                    disableClearable
                    disabled={id !== 'lastDependency'}
                />
                <IconButton
                    size="small"
                    color="inherit"
                    sx={{ height: 'fit-content' }}
                    onClick={() => {
                        onDelete(id);
                    }}
                >
                    <GridCloseIcon />
                </IconButton>
            </FormItem>
            <FormItem half>
                <FormWidget
                    component={'Number'}
                    name={`dependencies.${id}.lag`}
                    label={useI18n('production.activityForm.dependencyFollowBy')}
                    forcePositive={true}
                />
            </FormItem>
            <FormItem half>
                <FormWidget
                    label={useI18n('production.activityForm.dependencyUnit')}
                    name={`dependencies.${id}.lagUnit`}
                    component="Autocomplete"
                    loading={unitsLoading}
                    options={unitOptions}
                    disableClearable
                />
            </FormItem>
            <FormItem>
                <FormWidget
                    component="MetadataAutocomplete"
                    name={`dependencies.${id}.type`}
                    metadataName="DependencyType"
                    label={useI18n('production.activityForm.dependencyType')}
                    identifier="termset"
                />
            </FormItem>
        </>
    );
};
