export const categories = [
    'Catering',
    'Sound Stages',
    'Insurance',
    'Travel',
    'Camera Equipment',
    'Digital Storage',
    'Color Grading',
    'Transportation',
    'Virtual Production',
    'Hairdressing Supplies',
    'Stands and Hardware',
    'Character Animation',
    'Color Correction',
    'Dailies',
    'Editing Equipment',
    'Face Rigging',
    'IMF',
    'LED Panels',
    'Location Scanning',
    'Media Asset Mgt',
    'Motion Capture',
    'Previsualization',
    'Quality Control (QC)',
    'Transcoding',
    'Video Production',
    'Virtual Prop Design',
    'Virtual Set Design',
    'Visual Effects (VFX)'
];

export const budgetCategories = [
    'Director & Staff',
    'Story & Rights',
    'Producer & Staff',
    'Camera',
    'Art Dept',
    'Set Dressing',
    'Set Operations',
    'Electrical',
    'Props',
    'Wardrobe',
    'Talent (Cast)',
    'Extras',
    'Makeup and Hair',
    'Sound',
    'Locations',
    'Editorial',
    'Music',
    'Visual Effects',
    'Facilities',
    'Finance'
];

export const resourceTypes = [
    'Crew',
    'Cast',
    'Equipment',
    'Product',
    'Service',
    'Prop',
    'Animal',
    'Wardrobe',
    'Vehichle',
    'Location'
];

export const resourceStatus = [
    'Open',
    'Consideration',
    'Request for Proposal',
    'Proposal Review',
    'Negotiating',
    'Booked',
    'Closed'
];

export const resourceHiredState = ['Open', 'Confirmed', 'Proposed'];

export const roles = [
    'Director',
    'Writer',
    'Actor',
    'Executive Producer',
    'Supervising Producer',
    'Line Producer',
    'Post-production Producer',
    'Production Manager',
    'First Assistant Director',
    'Location Manager',
    'Director of Photography',
    'Camera Operator',
    'Production Designer',
    'Art Director',
    'Set Decorator / Set Designer',
    'Key Grip',
    'Electrician',
    'Prop Manager / Prop Master / Property Manager / Property Master',
    'Costume Designer',
    'Casting Director',
    'Extra Casting',
    'Makeup Artist',
    'Sound Mixer',
    'Editor',
    'Composer',
    'Music Supervisor / Coordinator',
    'Visual Effects Producer',
    'Production Accountant',
    'Visual Effects',
    'Art Department',
    'Sound Department',
    'Producer'
];

export const activityTypes = ['Task', 'Milestone', 'Non-Working', 'Scene'];

export const showTypes = [
    'Feature',
    'Short',
    'Series',
    'Season',
    'Volume',
    'Episode',
    'News',
    'Sports',
    'Ad',
    'Event',
    'Theatrical',
    'TV',
    'Streaming',
    'Series',
    'Sports',
    'Segment',
    'TV',
    'DR',
    'Online',
    'PublicService',
    'Documentary'
];

export const dependencyTypes = ['Start to Start', 'Start to Finish', 'Finish to Start', 'Finish to Finish'];

export const jobTypes = [
    'Full Time',
    'Part Time',
    'Freelance',
    'Apprenticeship',
    'Internship',
    'Temporary',
    'Hourly',
    'Day Pay'
];

export const organizationTypes = [
    'Manufacturer',
    'Service Provider',
    'Supplier',
    'Retailer',
    'Creative Agency',
    'Talent Agency',
    'Production Company'
];
