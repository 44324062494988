import { isEmpty, set } from 'lodash';
import { CreativeCreditInput, ExperienceInput } from 'sr-types/lib/person/v1/graphql';
import { NameInput } from 'sr-types/lib/search/v1/graphql';
import Validator, { EMAIL_FORMAT_REGEX } from '../common/form/Validator';
import { ValidationRulesType } from '../production/helpers/productionUtils';

export const PERSON_EDITOR_TABS_ID = 'person-form-tabs';
export const PERSON_EMPTY_LAST_NAME = '[last name]';

export const EMPTY_STATE = {
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        email: {
            address: '',
            typeString: 'Business',
            verified: false
        },
        phone: ''
    },
    summary: {
        headline: '',
        about: ''
    },
    location: undefined,
    professionalRoles: []
};
export const EMPTY_ABOUT = {
    summary: {
        about: ''
    }
};
export const EMPTY_CREDIT = {
    creativeCredits: []
};
export const EMPTY_EXPERIENCE = {
    experiences: []
};
export const defaultValidationRules: ValidationRulesType = {
    'name.firstName': [Validator.RULES.isRequired],
    'name.lastName': [Validator.RULES.isRequired],
    'contactInfo.email.address': [Validator.RULES.email],
    professionalRoles: [Validator.RULES.isRequired],
    location: [Validator.RULES.isRequired]
};

export const defaultBasicInfoValidationRules: ValidationRulesType = {
    'contactInfo.email.address': [Validator.RULES.email],
    'contactInfo.phone.number': [Validator.RULES.phoneNumber],
    'name.firstName': [Validator.RULES.isRequired],
    'name.lastName': [Validator.RULES.isRequired]
};

export const personBusinessContactsValidationRules: ValidationRulesType = {
    personReference: [Validator.RULES.isRequired],
    role: [Validator.RULES.isRequired]
};

export const creativeCreditsValidationRules = {
    showReference: [Validator.RULES.isRequired]
};

export const creativeCreditsValidationRulesForAnnotations = {
    showName: [Validator.RULES.isRequired]
};

export const professionalExperiencesValidationRules = {
    organizationReference: [Validator.RULES.isRequired]
};

export const validateDateRange = (validation, startDate, endDate, startProp, endProp, keys = []) => {
    const isStartDateEmpty = isEmpty(startDate) && !isEmpty(endDate);
    const isEndDateEmpty = isEmpty(endDate) && !isEmpty(startDate);

    if (isStartDateEmpty) {
        modifyValidation(validation, startProp, keys);
    } else if (isEndDateEmpty) {
        modifyValidation(validation, endProp, keys);
    }
};

const modifyValidation = (validation, prop, keys) => {
    validation[prop] = [Validator.RULES.isRequired];

    if (keys.length > 0) {
        keys.push(prop);
    }
};

export const addListener = (listeners, prop, fun) => {
    listeners.push({ prop, fun });
};

export const EMPTY_CREATIVE_CREDIT: CreativeCreditInput = {
    dateRange: undefined,
    department: undefined,
    keyCredit: undefined,
    location: undefined,
    professionalRoles: undefined,
    showName: undefined,
    showReference: undefined,
    showType: undefined
};

export const EMPTY_PROFESSIONAL_EXPERIENCES: ExperienceInput = {
    organizationReference: undefined,
    experienceName: undefined,
    jobType: undefined,
    title: undefined,
    department: undefined,
    professionalRoles: undefined,
    location: undefined,
    dateRange: undefined,
    keyExperience: undefined
};

export const getPersonRefrenceListener = (prop) => [
    {
        prop: [`${prop}`],
        func: (name, newValue, newState) => set(newState, `showType`, undefined)
    }
];

export const personDirectoryFiltersReferenceAutocomplete = {
    expressions: [{ field: 'public', value: { values: ['false'] } }]
};

export const personNameToUiPreview = ({
    name,
    showHyphen = false
}: {
    name: NameInput;
    showHyphen?: boolean;
}): NameInput => {
    const isEmail = EMAIL_FORMAT_REGEX.test(name.firstName);
    return {
        firstName: isEmail ? name?.firstName.split('@')[0] : name?.firstName,
        lastName: name?.lastName === PERSON_EMPTY_LAST_NAME ? (showHyphen ? '-' : '') : name?.lastName
    };
};
