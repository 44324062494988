import React, {
    Dispatch,
    forwardRef,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
    useContext
} from 'react';
import {
    useActivityData,
    useActivitySave,
    useLinkResourceToActivity,
    useResourcesData,
    useUnlinkResourceFromActivity
} from '../helpers/productionApi';
import { ActivityInput } from 'sr-types/lib/production/v1/graphql';
import { cloneDeep } from 'lodash';
import { searchClient } from '../../common/list/slimQuery';
import { areAllFiltersInList } from '../../common/utils/commonUtils';
import {
    Activity_Field_Names,
    ActivityFormType,
    EMPTY_ACTIVITY,
    EMPTY_DEPENDENCIES,
    getActivityListeners,
    getActivityValidators,
    getDependencyArrayFromState,
    handleActivitySaveInput,
    reverseDependencyArrayToObject
} from '../helpers/activityUtils';
import { FormProvider } from '../../common/form/FormContext';
import ActivityEditor from './ActivityEditor';
import { UserContext } from '../../common/auth/UserContext';
import { updateSchedulerProductionData } from '../../scheduler/scheduler';
import { toSchedulerActivityItemsFromProduction } from '../../scheduler/schedulerUtil';
import { isSchedulerProductionRefetchNeededAtom } from '../schedulerTimeline/ProductionSchedulerRefetchAtom';
import { useRecoilState } from 'recoil';
import { client, detailsQuery } from '../helpers/production';

interface ActivityFormProps {
    open?: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    onClose?: () => void;
    planId: string;
    productionId: string;
    isCreate?: boolean;
    activityId?: string;
    setActivityId?: (id: string) => void;
    prepopulatedActivity?: ActivityFormType;
    setPrepopulatedActivity?: (activity: ActivityFormType) => void;
    showBasic?: boolean;
    isInModal?: boolean;
    setIsSaveDisabled?: Dispatch<SetStateAction<boolean>>;
    isResourceForm?: boolean;
}

const refetchActivities = (
    activityId,
    isInModal,
    isResourceForm,
    activeOrganizationAccount,
    application,
    setIsSchedulerProductionRefetchNeeded,
    isSchedulerProductionRefetchNeeded
) => {
    setTimeout(() => {
        searchClient
            .refetchQueries({
                include: ['slimResultsQuery'],
                onQueryUpdated(observableQuery) {
                    return areAllFiltersInList(observableQuery.options.variables.filters, [
                        {
                            identifier: 'entity',
                            value: 'Activity'
                        }
                    ]);
                }
            })
            .then((response) => {
                if ((isInModal || isResourceForm) && response?.[0]?.data?.results?.hits?.items && activityId) {
                    const activityItem = response[0].data.results.hits.items.find((item) => item.id === activityId);
                    if (activityItem) {
                        updateSchedulerProductionData(
                            toSchedulerActivityItemsFromProduction(activityItem),
                            activeOrganizationAccount,
                            application,
                            setIsSchedulerProductionRefetchNeeded,
                            isSchedulerProductionRefetchNeeded
                        );
                    }
                }
            });
    }, 1000);
};

const ActivityForm = forwardRef((props: ActivityFormProps, ref) => {
    const {
        open = false,
        setIsOpen = undefined,
        onClose,
        planId,
        productionId,
        isCreate = false,
        activityId = undefined,
        setActivityId,
        prepopulatedActivity = undefined,
        showBasic = false,
        isInModal = true,
        setIsSaveDisabled = undefined,
        isResourceForm = false
    } = props;
    const { activeOrganizationAccount, application, userProfile } = useContext(UserContext);
    const { activitySave, isActivitySaving } = useActivitySave();
    const { activityData } = useActivityData(activityId, !activityId || isCreate);
    const { linkResourceToActivity } = useLinkResourceToActivity();
    const { unlinkResourceFromActivity } = useUnlinkResourceFromActivity();
    const { resourcesData } = useResourcesData(productionId);
    const [isSchedulerProductionRefetchNeeded, setIsSchedulerProductionRefetchNeeded] = useRecoilState(
        isSchedulerProductionRefetchNeededAtom
    );
    const [state, setState] = useState<ActivityFormType>({
        ...cloneDeep(EMPTY_ACTIVITY),
        activityOwner: {
            ...userProfile.personReference,
            label: userProfile.name.firstName + ' ' + userProfile.name.lastName
        }
    });

    const keysToHide = ['productionReference', 'productReferences', 'organizationReference'];

    const hiddenFields = keysToHide.reduce(
        (acc, key) => {
            if (Activity_Field_Names[key]) {
                acc[Activity_Field_Names[key]] = true;
            }
            return acc;
        },
        {} as Record<string, boolean>
    );

    const resourceOptions = useMemo(() => {
        if (resourcesData?.results?.hits?.items) {
            const items = resourcesData.results.hits.items;
            return items.map((item) => {
                return { id: item.id, label: item.name };
            });
        }
        return [];
    }, [resourcesData]);

    const handleSave = (onSucess = undefined) => {
        return new Promise((resolve, reject) => {
            const { associateWithResource, dependencies } = state;
            const activityInput: ActivityInput = handleActivitySaveInput(state, activityId);

            activitySave(productionId, planId, activityInput, !!activityId)
                .then((response: any) => {
                    if (response?.data?.saveActivity?.id) {
                        if (setActivityId) {
                            setActivityId('');
                        }
                        const actId = response.data.saveActivity.id;

                        const { linkedResources } = activityData?.activity || {};
                        const alreadyLinkedIds =
                            Array.isArray(linkedResources) && linkedResources.length
                                ? linkedResources.map((item) => item.identity.id)
                                : [];
                        const associateWithResourceId =
                            Array.isArray(associateWithResource) && associateWithResource.length
                                ? associateWithResource.map((item) => item.id)
                                : [];
                        const toUnlink = alreadyLinkedIds.filter((id) => !associateWithResourceId.includes(id));
                        const toLink = associateWithResourceId.filter((id) => !alreadyLinkedIds.includes(id));
                        if (toLink.length) {
                            toLink.forEach((id) => {
                                linkResourceToActivity(actId, id);
                            });
                        }
                        if (toUnlink.length) {
                            toUnlink.forEach((id) => {
                                unlinkResourceFromActivity(actId, id);
                            });
                        }
                        if (!activityId) {
                            activityInput.activityDependencies = getDependencyArrayFromState(dependencies, actId);
                            activitySave(
                                productionId,
                                planId,
                                { ...activityInput, identity: { id: actId } },
                                true
                            ).then((res: any) => {
                                if (res?.data?.saveActivity?.id) {
                                    resolve(actId);
                                    setIsOpen?.(false);
                                }
                            });
                        } else {
                            resolve(actId);
                            setIsOpen?.(false);
                        }
                        refetchActivities(
                            response.data.saveActivity.id,
                            isInModal,
                            isResourceForm,
                            activeOrganizationAccount,
                            application,
                            setIsSchedulerProductionRefetchNeeded,
                            isSchedulerProductionRefetchNeeded
                        );
                        onSucess?.({ ...activityInput, identity: { id: actId } });
                    } else {
                        reject();
                    }
                })

                .catch((err) => {
                    reject(err);
                });
        }).finally(() => {
            client.refetchQueries({
                include: [detailsQuery]
            });
        });
    };

    useImperativeHandle(ref, () => ({
        handleSave
    }));

    useEffect(() => {
        if (prepopulatedActivity) {
            setState((prevVal) => {
                return {
                    ...prevVal,
                    ...prepopulatedActivity
                };
            });
        }
    }, [prepopulatedActivity]);

    useEffect(() => {
        if (activityData?.activity) {
            const {
                name,
                activityType,
                category,
                dateRange,
                durationSpecified,
                identity,
                locations,
                linkedResources,
                dependencies,
                activityOwner,
                activityStatus
            } = activityData.activity;

            const owner = activityOwner || {
                ...userProfile.personReference,
                label: userProfile.name.firstName + ' ' + userProfile.name.lastName
            };
            const associateWithResource =
                Array.isArray(linkedResources) && linkedResources.length
                    ? linkedResources.map((item) => {
                          return {
                              id: item.identity.id,
                              label: item.name
                          };
                      })
                    : [];

            setState((prevVal) => {
                return {
                    ...prevVal,
                    name,
                    activityType,
                    category,
                    dateRange,
                    durationSpecified,
                    identity,
                    locations,
                    associateWithResource,
                    activityOwner: owner,
                    activityStatus,
                    dependencies: {
                        ...reverseDependencyArrayToObject(dependencies, activityId),
                        ...cloneDeep(EMPTY_DEPENDENCIES)
                    }
                };
            });
        }
    }, [activityData, activityId, userProfile]);

    return (
        <FormProvider
            state={state}
            setState={setState}
            validationRules={{ ...getActivityValidators() }}
            listeners={[...getActivityListeners()]}
        >
            <ActivityEditor
                isInModal={isInModal}
                showBasic={showBasic}
                isOpen={open}
                setIsOpen={setIsOpen}
                onClose={onClose}
                handleSave={handleSave}
                isLoading={activityId && !activityData?.activity}
                resourceOptions={resourceOptions}
                productionId={productionId}
                activityId={activityId}
                isSaving={isActivitySaving}
                setIsSaveDisabled={setIsSaveDisabled}
                isResourceForm={isResourceForm}
                hiddenFields={hiddenFields}
            />
        </FormProvider>
    );
});

export default ActivityForm;
