import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUnitsData } from '../../attributes/attributesApi';
import Loading from '../../common/components/Loading';
import { FormContext } from '../../common/form/FormContext';
import FormModal from '../../common/form/FormModal';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { useActiviesData } from '../helpers/productionApi';
import { EMPTY_PERSON_MODAL_STATE } from '../../shortlists/ShortlistLineItemEditor';
import AddProjectOwnerModal, { doSavePerson } from '../form/production/AddProjectOwnerModal';
import { extractNames } from '../helpers/peopleUtils';
import { ActivityFormItems } from './ActivityFormItems';
import { EMPTY_ACTIVITY } from '../helpers/activityUtils';
import { cloneDeep } from 'lodash';

const VALID_UNITS = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];

export default ({
    isOpen,
    setIsOpen = undefined,
    onClose,
    handleSave,
    isLoading,
    isSaving,
    resourceOptions = undefined,
    productionId = undefined,
    activityId = undefined,
    showBasic,
    isInModal,
    setIsSaveDisabled,
    isResourceForm,
    isFromRM = false,
    hiddenFields = undefined,
    hasError = false
}) => {
    const { validation, state, setState } = useContext(FormContext);
    const { activitiesData, activitiesLoading } = useActiviesData(productionId);
    const [personModalState, setPersonModalState] = useState(EMPTY_PERSON_MODAL_STATE);
    const guestEditorRef = useRef(null);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const { unitsData, unitsLoading } = useUnitsData('Time');

    const unitOptions = useMemo(() => {
        if (unitsData?.results?.hits?.items) {
            return unitsData.results.hits.items
                .filter((item) => VALID_UNITS.includes(item.label))
                .map((item) => {
                    return { id: item.label, label: item.label };
                });
        }
        return [];
    }, [unitsData]);

    const activityOptions = useMemo(() => {
        if (activitiesData?.results?.hits?.items) {
            return activitiesData.results.hits.items
                .filter((item) => item.id !== activityId)
                .map((item) => {
                    return { id: item.id, label: item.name };
                });
        }
    }, [activitiesData?.results?.hits?.items, activityId]);

    useEffect(() => {
        setIsSaveDisabled?.((validation && !validation.isValid) || isSaving);
    }, [isSaving, setIsSaveDisabled, validation]);

    const onCloseActivityEditor = () => {
        setState({ ...cloneDeep(EMPTY_ACTIVITY) });
        onClose();
        setIsOpen?.(false);
    };

    const onSave = () => {
        if (personModalState.open)
            doSavePerson(
                setIsFormSaving,
                (input) => {
                    setState({ ...state, activityOwner: input });
                },
                guestEditorRef,
                setPersonModalState
            );
        else {
            handleSave();
            onCloseActivityEditor();
        }
    };

    const onCreateOwner = (e) => {
        setPersonModalState({
            open: true,
            prepopulate: { name: extractNames(e.label) }
        });
    };

    const additonalLeftsideActions = personModalState.open
        ? [
              {
                  label: useI18n('person.import.contacts.uploadfile.modal.back'),
                  onClick: () => {
                      setPersonModalState(EMPTY_PERSON_MODAL_STATE);
                  }
              }
          ]
        : [];

    return isInModal ? (
        <FormModal
            isOpen={isOpen}
            width="700px"
            onClose={onCloseActivityEditor}
            title={
                personModalState.open ? (
                    <I18n token="form.shortlist.guestEditor" />
                ) : activityId ? (
                    <I18n token="production.activity.edit" />
                ) : (
                    <I18n token="production.activity.new" />
                )
            }
            id={`production-schedule`}
            isSaving={isFormSaving}
            hideDefaultButtons={true}
            additionalActions={[
                !personModalState.open && {
                    label: useI18n('dialog.cancel'),
                    onClick: () => {
                        onCloseActivityEditor();
                    },
                    variant: 'text'
                },
                {
                    label: useI18n('dialog.save'),
                    onClick: () => {
                        onSave();
                    },
                    variant: 'contained',
                    disabled: (validation && !validation.isValid) || isSaving
                }
            ]}
            additonalLeftsideActions={additonalLeftsideActions}
        >
            {isLoading ? (
                <Loading />
            ) : personModalState.open ? (
                <AddProjectOwnerModal
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                />
            ) : isFromRM ? (
                <ActivityFormItems
                    showBasic={showBasic}
                    activitiesLoading={activitiesLoading}
                    activityOptions={activityOptions}
                    unitOptions={unitOptions}
                    unitsLoading={unitsLoading}
                    isResourceForm={isResourceForm}
                    hiddenFields={hiddenFields}
                    hasError={hasError}
                />
            ) : (
                <ActivityFormItems
                    showBasic={showBasic}
                    resourceOptions={resourceOptions}
                    activitiesLoading={activitiesLoading}
                    activityOptions={activityOptions}
                    unitOptions={unitOptions}
                    unitsLoading={unitsLoading}
                    isResourceForm={isResourceForm}
                    onCreateOwner={onCreateOwner}
                    hiddenFields={hiddenFields}
                />
            )}
        </FormModal>
    ) : (
        <>
            {isLoading ? (
                <Loading />
            ) : personModalState.open ? (
                <AddProjectOwnerModal
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                />
            ) : (
                <ActivityFormItems
                    showBasic={showBasic}
                    resourceOptions={resourceOptions}
                    activitiesLoading={activitiesLoading}
                    activityOptions={activityOptions}
                    unitOptions={unitOptions}
                    unitsLoading={unitsLoading}
                    isResourceForm={isResourceForm}
                    onCreateOwner={onCreateOwner}
                    hiddenFields={hiddenFields}
                />
            )}
        </>
    );
};
