import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { H } from '../common/Layout';
import { IconButton, Link, SvgIconTypeMap } from '@mui/material';
import { Icons } from '../common/icons/Icons';
import { useI18n } from '../common/i18n/I18n';
import React from 'react';
import { LineItemExtended } from './ShortlistLineItems';
import { RequestAvailability } from '../common/icons/RequestAvailability';
import { RequestSent } from '../common/icons/RequestSent';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ResponseReceived } from '../common/icons/ResponseReceived';

const ICON_SIZE = 20;
const ICON_PADDING = 0.25;

export enum InquiryStatusEnum {
    default = 'default',
    empty = '-',
    available = 'Available',
    notAvailable = 'Not Available',
    requestSent = 'Request Sent',
    alternateProposed = 'Alternate Proposed',
    informationRequested = 'Information Requested'
}

export type InquiryStatus = (typeof InquiryStatusEnum)[keyof typeof InquiryStatusEnum];

type Type = {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    disabled: boolean;
};

type InquiryStatusType = {
    [key in InquiryStatus]: Type;
};

export const inquiryStatus: InquiryStatusType = {
    [InquiryStatusEnum.default]: { icon: RequestAvailability, disabled: true },
    [InquiryStatusEnum.empty]: { icon: RequestAvailability, disabled: false },
    [InquiryStatusEnum.available]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.notAvailable]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.requestSent]: { icon: RequestSent, disabled: true },
    [InquiryStatusEnum.alternateProposed]: { icon: ResponseReceived, disabled: true },
    [InquiryStatusEnum.informationRequested]: { icon: ResponseReceived, disabled: true }
};

const StyledIconButton = ({ onClick = undefined, Icon, isDisabled = false, color = 'inherit' }) => {
    return (
        <IconButton sx={{ padding: ICON_PADDING }} component="span" onClick={onClick} disabled={isDisabled}>
            <Icon sx={{ fontSize: ICON_SIZE }} color={color} />
        </IconButton>
    );
};

export default (onNotesIconClick, invitePerson, inviteFeature = false, onSummarizeIconClick): GridColDef[] => {
    const icons: GridColDef = {
        field: 'icons',
        headerName: '',
        minWidth: 130,
        width: 130,
        resizable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams<LineItemExtended>) => {
            const Icon = inquiryStatus[params.row.inquiryStatus]?.icon || inquiryStatus[InquiryStatusEnum.default].icon;
            let inquiryDisabled = inquiryStatus[params.row.inquiryStatus]?.disabled;

            return (
                <H gap={0.5} sx={{ px: 1, py: 0.75 }}>
                    <StyledIconButton
                        onClick={() => onNotesIconClick(params.row)}
                        Icon={Icons.Notes}
                        color={'success'}
                    />
                    {inviteFeature && (params.row.isPerson || params.row.isOrganization) ? (
                        <StyledIconButton
                            isDisabled={inquiryDisabled}
                            onClick={
                                inquiryStatus[params.row.inquiryStatus]?.disabled
                                    ? () => {}
                                    : () => invitePerson(params.row)
                            }
                            Icon={Icon}
                            color={inquiryDisabled ? 'disabled' : 'primary'}
                        />
                    ) : (
                        <></>
                    )}
                    {params.row.referenceUrl?.path && (
                        <Link href={params.row.referenceUrl.path} target="_blank" rel="noreferrer">
                            <StyledIconButton Icon={Icons.Link} />
                        </Link>
                    )}
                    <StyledIconButton onClick={() => onSummarizeIconClick(params.row)} Icon={Icons.SummarizeOutlined} />
                </H>
            );
        }
    };
    const name: GridColDef = {
        field: 'name',
        headerName: useI18n('shortlist.name'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };
    const itemStatus: GridColDef = {
        field: 'itemStatus',
        headerName: useI18n('shortlist.line.item.status'),
        flex: 1,

        minWidth: 100,
        sortable: true
    };
    const about: GridColDef = {
        field: 'about',
        valueGetter: ({ row }) => row.summary?.about,
        headerName: useI18n('shortlist.about'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };
    const availability: GridColDef = {
        field: 'availability',
        headerName: useI18n('shortlist.line.item.availability'),
        flex: 1,
        minWidth: 100,
        sortable: true
    };

    return inviteFeature ? [icons, name, itemStatus, availability, about] : [icons, name, itemStatus, about];
};
