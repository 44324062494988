import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ProductionResourceContext {
    selectedItem: any;
    setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
    selectedItemLoading: boolean;
    setSelectedItemLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductionResourceContext = createContext<ProductionResourceContext | null>(null);

export const ProductionResourceProvider = ({ children }: { children: ReactNode }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemLoading, setSelectedItemLoading] = useState(false);

    return (
        <ProductionResourceContext.Provider
            value={{
                selectedItem,
                setSelectedItem,
                selectedItemLoading,
                setSelectedItemLoading
            }}
        >
            {children}
        </ProductionResourceContext.Provider>
    );
};

export const useProductionResourceProvider = () => {
    const context = useContext(ProductionResourceContext);
    if (!context) throw new Error('useProductionResource must be used within a ProductionResource');
    return context;
};
