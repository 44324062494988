import React from 'react';
import InfoAlert from '../components/alert/InfoAlert';
import { V } from '../Layout';

export default ({ descriptionToken, title }: { descriptionToken: string; title?: string }) => {
    return (
        <V sx={{ pt: 1, pr: 1, pl: 2 }}>
            <InfoAlert title={title} descriptionToken={descriptionToken} />
        </V>
    );
};
