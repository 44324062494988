import { createSvgIcon } from '@mui/material';
import React from 'react';

export const BackButton = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                fill="currentColor"
                fillOpacity="1"
            />
        </svg>
    </>,
    'BackButton'
);
