import React from 'react';
import I18n from '../../common/i18n/I18n';
import { Typography } from '@mui/material';
import FormWidget from '../form/FormWidget';
import { SHOWRUNNR_POLICY_LINK, SHOWRUNNR_TERMS_LINK } from './PolicyLink';
import { H } from '../Layout';
import { Link } from '@mui/material';

export default () => {
    return (
        <>
            <H
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'fit-content'
                }}
                fill
            >
                <FormWidget component="Checkbox" name="acceptTermsAndPolicy" sx={{ marginRight: 0 }} />
                <Typography fontSize={12}>
                    <I18n token="dialog.checkbox.create" />
                    <Link target="_blank" href={SHOWRUNNR_TERMS_LINK} sx={{ textDecoration: 'none' }}>
                        <I18n token="common.privacy.terms" />
                    </Link>
                    <I18n token="dialog.checkbox.acknowledge" />
                    <Link target="_blank" href={SHOWRUNNR_POLICY_LINK} sx={{ textDecoration: 'none' }}>
                        <I18n token="common.privacy.policy" />
                    </Link>
                </Typography>
            </H>
        </>
    );
};
