import React from 'react';
import { V, H } from '../../../common/Layout';
import { useStyling } from '../../../common/Theme';
import { PEOPLE_TYPE } from './inquiry';
import { Icons } from '../../../common/icons/Icons';
import { Typography } from '@mui/material';
import I18n from '../../../common/i18n/I18n';
import MultipleChipPreview from '../../../common/components/MultipleChipPreview';
import { InquiryMessage } from 'sr-types/lib/shortlist/v1/graphql';
import OpportunityEmailPreview from '../../../supply/opportunity/details/OpportunityEmailPreview';

export default ({ type, previewData, state }: { type: string; previewData: InquiryMessage; state: any }) => {
    const { theme, isDarkMode } = useStyling();
    const content = previewData?.inquiryMessage?.content;
    const inquiryFor = previewData?.inquiryFor;
    const inviteeName = previewData?.inquiryMessage?.senderInfo?.name;

    return (
        <V sx={{ p: 2, gap: 2 }}>
            <V sx={{ gap: 1 }}>
                <H sx={{ color: theme.palette.grey[isDarkMode ? 100 : 700] }}>
                    {type == PEOPLE_TYPE ? (
                        <Icons.People fontSize={'medium'} />
                    ) : (
                        <Icons.Organization fontSize={'medium'} />
                    )}
                    <Typography fontSize={'medium'}>
                        <I18n token="shortlist.lineItem.person.invitee" />
                    </Typography>
                </H>
                <MultipleChipPreview data={state[type]} />
            </V>
            <OpportunityEmailPreview content={content} inquiryFor={inquiryFor} isPreview />
            <Typography fontSize={'small'} color={theme.palette.text.primary}>
                <I18n token={'form.respond.note'} values={{ name: inviteeName }} />
            </Typography>
        </V>
    );
};
