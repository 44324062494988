import { Box, Tooltip, Typography } from '@mui/material';
import React, { Fragment, useContext } from 'react';
import { ProductionOrganizationInfo } from 'sr-types/lib/production/v1/graphql';
import { useStyling } from '../../common/Theme';
import { UserContext } from '../../common/auth/UserContext';
import useHistory from '../../common/utils/useHistory';

export default ({ orgs }: { orgs: ProductionOrganizationInfo[] }) => {
    const { application } = useContext(UserContext);
    const { changeRoute } = useHistory();
    const { theme } = useStyling();

    return orgs && orgs.length ? (
        <>
            {orgs.map((org: ProductionOrganizationInfo, index: number) => {
                return (
                    <Tooltip key={index} title={<Typography fontSize="small">{org.name}</Typography>}>
                        <Typography
                            className="clickable ellipsis"
                            sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
                            onClick={() => {
                                changeRoute(`${application.home}/organizations/${org.organizationReference.id}`);
                            }}
                        >
                            {org.name}
                            {index + 1 < orgs.length ? ', ' : ''}
                        </Typography>
                    </Tooltip>
                );
            })}
        </>
    ) : (
        <></>
    );
};
