import {
    MARKETPLACE,
    PLANNING,
    PRODUCTION_PROFESSIONAL,
    PROFILES,
    RESERVATION_PROFESSIONAL,
    SUPPLIER
} from '../../common/nav/apps';
import { useContext, useMemo } from 'react';
import { UserContext } from '../../common/auth/UserContext';

export default () => {
    const { application } = useContext(UserContext);

    const preFilterByApp = useMemo(() => {
        return {
            [MARKETPLACE.id]: [{ identifier: 'public', value: 'false' }],
            [SUPPLIER.id]: [
                { identifier: 'public', value: 'false' },
                { identifier: 'origin', value: 'noop' }
            ],
            [PLANNING.id]: [{ identifier: 'public', value: 'false' }],
            [PROFILES.id]: [{ identifier: 'public', value: 'false' }],
            [PRODUCTION_PROFESSIONAL.id]: [{ identifier: 'public', value: 'false' }],
            [RESERVATION_PROFESSIONAL.id]: [
                { identifier: 'public', value: 'false' },
                { identifier: 'origin', value: 'noop' }
            ]
        };
    }, []);

    return preFilterByApp[application.id];
};
