import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Scenarios = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3V9.66667H13.4V7.16667H11.8V13.8333H13.4V11.3333H19V15.1C18.4246 15.2168 17.8851 15.4324 17.4 15.7283V13H15V16.3333H16.6006C16.0994 16.7983 15.6936 17.3647 15.416 18H13.4V15.5H10.2V7.16667H8.6V9.66667H3V3H8.6V5.5H13.4V3H19ZM4.6 8H7V4.66667H4.6V8ZM17.4 4.66667H15V8H17.4V4.66667Z"
            fill="inherit"
        />
        <path
            d="M24 20.8571H20.8571V24H19.1429V20.8571H16V19.1429H19.1429V16H20.8571V19.1429H24V20.8571Z"
            fill="inherit"
        />
    </>,
    'Scenarios'
);
