import _ from 'lodash';
import * as React from 'react';
import I18n from '../i18n/I18n';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { useLocation } from 'react-router-dom';
import { C, H, V } from '../Layout';
import { buildGoogleSocialSigninURI } from './ssoAuth';
import { useStyling } from '../Theme';
import { Icons } from '../icons/Icons';

export default ({ valid, email, setEmail, hasErrors, keyboardEventHandler, setValid, validator }) => {
    const location = useLocation();
    const presetEmail = location.state?.email ?? location.state?.username;
    const { theme } = useStyling();
    const emailRef = React.useRef<HTMLInputElement>(null);

    const handleGoogleLogin = () => {
        const googleSigninURI = buildGoogleSocialSigninURI();
        window.location.href = googleSigninURI;
    };

    const allAuthOptions = {
        contacts: {
            google: {
                iconComponent: <Icons.GoogleLogo sx={{ width: '32px', height: '32px' }} />,
                label: <I18n token="dialog.google.login" />,
                handleLogin: handleGoogleLogin
            }
        }
    };

    return (
        <FormItem>
            <V gap={2} fill>
                <H fill gap={2} sx={{ justifyContent: 'center' }}>
                    {Object.keys(allAuthOptions?.contacts).map((key) => {
                        const { iconComponent: Icon, handleLogin } = allAuthOptions.contacts[key];
                        return (
                            <H
                                key={key}
                                onClick={handleLogin}
                                sx={{
                                    padding: 1,
                                    display: 'flex',
                                    cursor: 'pointer',
                                    borderRadius: 0.5,
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    justifyContent: 'center',
                                    border: `1px solid ${theme.palette.divider}`
                                }}
                            >
                                {Icon}
                            </H>
                        );
                    })}
                </H>
                <H fill>
                    <C sx={{ flex: 1, borderBottom: `1px solid ${theme.palette.divider}` }} />
                    <C sx={{ margin: '0 8px', fontWeight: 'bold' }}>
                        <I18n token="dialog.or" />
                    </C>
                    <C sx={{ flex: 1, borderBottom: `1px solid ${theme.palette.divider}` }} />
                </H>

                <FormWidget
                    name="username"
                    label={<I18n token="account.form.email" />}
                    value={presetEmail ?? email}
                    errors={valid?.errors?.username}
                    hasErrors={hasErrors('username')}
                    onChange={React.useCallback(
                        (field, value) => {
                            const validation = validator.validate({
                                username: emailRef.current?.value
                            });
                            setValid({ errors: validation.errors, isValid: validation.isValid });
                            setEmail(emailRef.current ? emailRef.current?.value : '');
                        },
                        [setEmail]
                    )}
                    onKeyUp={(e) => keyboardEventHandler(e, email)}
                    disabled={!!presetEmail}
                    ref={emailRef}
                />
            </V>
        </FormItem>
    );
};
