import { Breadcrumbs, Chip, Link, SvgIcon, SxProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useOnMobile } from '../hooks/breakpoints';
import useHistory from '../utils/useHistory';
import { Icons } from '../icons/Icons';
import { KeyValueMap } from '../KeyValueMap';

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export type CustomBreadCrumb = {
    uri?: string;
    onClick?: Function;
    label: string | ReactElement;
    icon?: typeof SvgIcon;
};

type BreadCrumbsProps = {
    uri?: string;
    sx?: SxProps;
    grow?: boolean;
    entityName?: string;
    custom?: CustomBreadCrumb[];
    styleAsChip?: boolean;
    searchParams?: KeyValueMap<string, string>;
};

const getBreadCrumbs = (uri, entityName, changeRoute, styleAsChip, searchParams) => {
    const c = [];
    if (uri.startsWith('/')) {
        uri = uri.substring(1);
    }
    const paths = uri.split('/');
    if (entityName) {
        paths.push(entityName);
    }
    const homePath = paths.length > 0 ? `/${paths[0]}` : '/';
    const crumbPaths = paths.length > 0 ? paths.slice(1) : [];
    c.push(
        <Link
            key={'home'}
            sx={{ display: 'flex', alignItems: 'center' }}
            className="clickable"
            underline="hover"
            color="inherit"
            onClick={(e) => {
                e.preventDefault();
                changeRoute(homePath, searchParams);
            }}
            href={homePath}
        >
            <Icons.Home sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
        </Link>
    );

    let currentPath = homePath;
    crumbPaths.forEach((path, index) => {
        let newPath = `${currentPath}/${path}`;
        if (index !== crumbPaths.length - 1) {
            c.push(
                <Link
                    key={path}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className="clickable"
                    underline="hover"
                    color="inherit"
                    onClick={(e) => {
                        e.preventDefault();
                        changeRoute(newPath, searchParams);
                    }}
                >
                    {capitalize(path)}
                </Link>
            );
        } else {
            c.push(
                styleAsChip ? (
                    <Chip key={path} sx={{ display: 'flex', alignItems: 'center' }} label={capitalize(path)} />
                ) : (
                    <Typography key={path} sx={{ display: 'flex', alignItems: 'center' }}>
                        {capitalize(path)}
                    </Typography>
                )
            );
        }
        currentPath = `${currentPath}/${path}`;
    });
    return c;
};

const getCustomBreadCrumbs = (customBreadCrumbs, changeRoute, searchParams) => {
    const crumbs = [];

    customBreadCrumbs.forEach((crumb, index) => {
        if (crumb.uri || crumb.onClick) {
            crumbs.push(
                <Link
                    key={crumb.uri || index}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className="clickable"
                    underline="hover"
                    color="inherit"
                    onClick={(e) => {
                        e.preventDefault();
                        if (crumb.uri) {
                            changeRoute(crumb.uri, searchParams);
                        } else if (crumb.onClick) {
                            crumb.onClick();
                        } else {
                            console.log('uri or onClick must be provided');
                        }
                    }}
                >
                    {crumb.icon && <crumb.icon sx={{ mr: 0.5 }} fontSize="inherit" />}
                    {crumb.label}
                </Link>
            );
        } else {
            crumbs.push(
                <Typography key={crumb.label} sx={{ display: 'flex', alignItems: 'center' }}>
                    {crumb.icon && <crumb.icon sx={{ mr: 0.5 }} fontSize="inherit" />}
                    {crumb.label}
                </Typography>
            );
        }
    });

    return crumbs;
};

export default ({
    uri,
    sx = undefined,
    grow = false,
    entityName = undefined,
    custom = undefined,
    styleAsChip = false,
    searchParams = undefined
}: BreadCrumbsProps) => {
    const isMobile = useOnMobile();
    const { changeRoute } = useHistory();

    const crumbs = custom
        ? getCustomBreadCrumbs(custom, changeRoute, searchParams)
        : getBreadCrumbs(uri, entityName, changeRoute, styleAsChip, searchParams);
    return (
        <Breadcrumbs sx={{ flexGrow: grow ? 2 : 0, ...sx }} maxItems={isMobile ? 2 : 5} aria-label="breadcrumb">
            {crumbs}
        </Breadcrumbs>
    );
};
