import { Box, Slide, SvgIcon, SxProps, Tooltip, Typography } from '@mui/material';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { H, V } from '../../Layout';
import { useStyling } from '../../Theme';
import { BreakpointSize } from '../../hooks/breakpoints';
import { useCurrentBreakpoint } from '../../hooks/useCurrentBreakpoint';
import { useIsWithinBreakpoints } from '../../hooks/useIsWithinBreakpoints';
import { ActionType } from '../ActionType';

const SIDE_PANEL_WIDTH = '395px';
const SIDE_PANEL_EXPANDED = '40vw';

type SidePanelSx = {
    [key in BreakpointSize]: SxProps;
};
const SIDE_PANEL_SX = (expandView: boolean): SidePanelSx => ({
    xs: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1
    },
    s: {
        display: 'flex',
        height: '100%',
        width: '100%',
        zIndex: 1
    },
    m: {
        display: 'flex',
        height: '100%',
        width: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        minWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        maxWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        zIndex: 1
    },
    l: {
        display: 'flex',
        height: '100%',
        width: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        minWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        maxWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        zIndex: 1
    },
    xl: {
        display: 'flex',
        height: '100%',
        width: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        minWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        maxWidth: expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH,
        zIndex: 1
    }
});

export const useSidePanelSx = (expandView: boolean) => {
    const breakpoint = useCurrentBreakpoint();
    const sidePanelWidth = expandView ? SIDE_PANEL_EXPANDED : SIDE_PANEL_WIDTH;

    return {
        ...SIDE_PANEL_SX[breakpoint],
        width: sidePanelWidth,
        minWidth: sidePanelWidth,
        maxWidth: sidePanelWidth
    };
};

type SidePanelToolbarProps = {
    actions: ActionType[];
};

export const SidePanelToolbar = (props: SidePanelToolbarProps) => {
    const { actions } = props;
    const { theme, isMobile } = useStyling();
    return (
        <>
            {actions.map((action: ActionType, index) => {
                const color = action.disabled
                    ? theme.palette.text.disabled
                    : action.selected
                      ? theme.palette.primary.main
                      : theme.palette.text.primary;
                return (
                    <H
                        key={index}
                        className={'clickable'}
                        onClick={(event) => {
                            if (typeof action.onClick === 'function' && !action.disabled) {
                                action.onClick(event);
                            }
                        }}
                    >
                        <Tooltip title={action.tooltip}>
                            <action.icon fontSize={'small'} sx={{ color: color }} />
                        </Tooltip>
                        {isMobile ? (
                            <></>
                        ) : (
                            <Tooltip title={action.tooltip}>
                                <Typography
                                    sx={{
                                        color: color,
                                        whiteSpace: 'nowrap',
                                        pl: 0.5,
                                        display: { sm: 'none', md: 'inherit' }
                                    }}
                                >
                                    {action.label}
                                </Typography>
                            </Tooltip>
                        )}
                    </H>
                );
            })}
        </>
    );
};

export type SidePanelDef = {
    id: string;
    label?: string | ReactElement;
    icon: typeof SvgIcon;
    component: (onClose: () => void) => React.ReactElement;
    disabled?: boolean;
    tooltip?: string | ReactElement;
    onClick?: () => Promise<any> | void;
};

export type WithSidePanelProps = {
    panels: SidePanelDef[];
    leftToolbar: React.ReactElement;
    children: any;
    visibleSidePanelId?: string;
    hideHeader?: boolean;
    panelBoxSx?: any;
    rightToolbar?: React.ReactElement;
    expandView?: boolean;
};

export default (props: WithSidePanelProps) => {
    const {
        panels,
        leftToolbar,
        children,
        visibleSidePanelId,
        hideHeader,
        panelBoxSx = {},
        rightToolbar,
        expandView
    } = props;
    const { theme } = useStyling();
    const sidePanelSx: SxProps = useSidePanelSx(expandView);
    const isSmallScreen = useIsWithinBreakpoints(['xs', 's']);
    const [visibleSidePanel, setVisibleSidePanel] = useState<SidePanelDef | undefined>();

    useEffect(() => {
        if (panels && visibleSidePanelId) {
            const panel = panels.find((p) => p.id === visibleSidePanelId);
            if (panel) {
                setVisibleSidePanel(panel);
            }
        }
    }, [panels, visibleSidePanelId]);

    return (
        <V className="withSidePanel" fill sx={{ gap: 1, overflow: 'hidden' }}>
            {hideHeader ? (
                <Fragment />
            ) : (
                <H className="withSidePanel_top">
                    <H grow>{leftToolbar}</H>
                    <H sx={{ pr: 1, gap: 2, alignItems: 'center', justifyContent: 'flex-end', width: 'unset' }}>
                        <H>{rightToolbar}</H>
                        <SidePanelToolbar
                            actions={(panels || []).map((panel): ActionType => {
                                const callback = () =>
                                    setVisibleSidePanel(
                                        visibleSidePanel && visibleSidePanel.id === panel.id ? undefined : panel
                                    );
                                return {
                                    icon: panel.icon,
                                    label: panel.label,
                                    selected: visibleSidePanel && visibleSidePanel.id === panel.id,
                                    tooltip: panel.tooltip,
                                    disabled: panel.disabled,
                                    onClick: () => {
                                        if (typeof panel.onClick === 'function') {
                                            const promise = panel.onClick();
                                            if (promise && typeof promise.then === 'function') {
                                                promise.then(callback);
                                            } else {
                                                callback();
                                            }
                                        } else {
                                            callback();
                                        }
                                    }
                                };
                            })}
                        />
                    </H>
                </H>
            )}
            <H className="withSidePanel_bottom" grow sx={{ gap: 1, overflow: 'hidden' }}>
                {visibleSidePanel && isSmallScreen ? <></> : children}
                <Slide direction={'left'} in={!!visibleSidePanel} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            height: '100%',
                            overflow: 'hidden',
                            bgcolor: theme.palette.background.paper,
                            borderRadius: 1,
                            ...sidePanelSx,
                            ...panelBoxSx
                        }}
                    >
                        {visibleSidePanel &&
                            visibleSidePanel.component(() => {
                                setVisibleSidePanel(undefined);
                            })}
                    </Box>
                </Slide>
            </H>
        </V>
    );
};
