import { gql, TypedDocumentNode } from '@apollo/client';
import { search } from 'sr-types';
import { LOCATION_FIELDS } from '../components/location';

/**
 * These are fragments with individual entities as exists in elastic search service.
 * NOTE: Any additional fragments a particular entity needs should be added to that entity's fragment.
 * For example Reservation also needs DateRange.
 */
export const AccountFragment: TypedDocumentNode<search.Account> = gql`
    fragment Account on Account {
        personName
        organizationName
        adminEmail
        email
        memberships {
            id
            roles
        }
        createdOn
        updatedOn
    }
`;

export const MoneyFragment: TypedDocumentNode<search.Money> = gql`
    fragment Money on Money {
        currencyCode
        units
        nanos
    }
`;

export const ReferenceFragment: TypedDocumentNode<search.Reference> = gql`
    fragment Reference on Reference {
        id
        label
        type
        uriPrefix
        eventId
        deleted
    }
`;

export const NameFragment: TypedDocumentNode<search.Name> = gql`
    fragment Name on Name {
        firstName
        lastName
    }
`;

export const ContactInfoFragment: TypedDocumentNode<search.ContactInfo> = gql`
    fragment ContactInfo on ContactInfo {
        email {
            address
            typeString
            verified
        }
        phone {
            number
            typeString
        }
    }
`;

export const FieldFragment: TypedDocumentNode<search.Field> = gql`
    fragment Field on Field {
        fieldDefinitionReference {
            ...Reference
        }
        fieldType
        name
        value
    }
    ${ReferenceFragment}
`;

const PRODUCTION_LIMITED_FIELDS = gql`
    fragment ProductionLimitedFields on Production {
        id
        publicTitle
        otherTitles {
            key
            value
        }
        productionType
        genre
        productionStatus
        defaultPlanId
    }
`;

export const CatalogSubTypeFragment: TypedDocumentNode<search.CatalogSubType> = gql`
    fragment CatalogSubType on CatalogSubType {
        catalogTypes
        __typename
    }
`;

export const CategoryFragment: TypedDocumentNode<search.Category> = gql`
    fragment Category on Category {
        lineage {
            value
        }
        name
    }
`;

export const DateRangeFragment = gql`
    fragment DateRange on DateRange {
        start
        end
        startTime
        endTime
        durationSpecified
        durationType
        duration
        dateTimeDuration {
            value
            unit
        }
    }
`;

export const NoteFragment: TypedDocumentNode<search.Note> = gql`
    fragment Note on Note {
        title
        id
        content
        entityReference {
            ...Reference
        }
        attachments {
            reference {
                id
            }
            label
        }
        createdOn
        createdByName
        updatedOn
    }
`;

export const ActivityFragment: TypedDocumentNode<search.Activity> = gql`
    fragment Activity on Activity {
        activityType
        dateRange {
            ...DateRange
        }
        show {
            name
            id
        }
        activityStatus
        activityOwner {
            ...Reference
        }
        location {
            ...LocationFields
            timezone {
                id
                name
            }
        }
        resourceReferences {
            ...Reference
        }
        production {
            ...ProductionLimitedFields
        }
        dependencies {
            fromActivity {
                id
            }
            toActivity {
                id
            }
            lag
            lagUnit
            type
        }
        category {
            value
            lineage {
                value
            }
        }
        security {
            allowedActions
        }
    }
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
    ${PRODUCTION_LIMITED_FIELDS}
`;

export const CostSummaryFragment: TypedDocumentNode<search.CostSummary> = gql`
    fragment CostSummary on CostSummary {
        base {
            ...Money
        }
        discount {
            ...Money
        }
        discountedTotal {
            ...Money
        }
        taxes {
            ...Money
        }
        total {
            ...Money
        }                
    }
    ${MoneyFragment}
`;

export const OpportunityFragment: TypedDocumentNode<search.Opportunity> = gql`
    fragment Opportunity on Opportunity {
        status
        customer
        contactName
        productionName
        labels
        opportunityPeriod {
            start
            end
        }
        opportunityType
        opportunitySource
        updatedOn
        security {
            allowedActions
        }
        ownerOrganizationName
        updatedByName
        createdOn
        createdByName
        costSummary {
            ...CostSummary
        }
    }
    ${CostSummaryFragment}
`;

export const OrganizationFragment: TypedDocumentNode<search.Organization> = gql`
    fragment Organization on Organization {
        website
        websiteURL
        categories
        isSharedInDirectory
        contact {
            contactInfo {
                ...ContactInfo
            }
            isPrimary
            label
            name {
                firstName
                lastName
                salutation
                suffix
            }
            organization
            personReference {
                ...Reference
            }
            role
        }
        hierarchicalCategories {
            lineage {
                value
            }
            value
        }
        location
        createdOn
        branding {
            bigLogo {
                ...Reference
            }
            brandNameOrText
            smallLogo {
                ...Reference
            }

            useCustom
        }
        updatedOn
        organizationTypes
        parentOrganizationName
        parentOrganization {
            label
            ...Reference
        }
        security {
            allowedActions
        }
    }
    ${ContactInfoFragment}
`;

export const PersonFragment: TypedDocumentNode<search.Person> = gql`
    fragment Person on Person {
        contactInfo {
            ...ContactInfo
        }
        createdOn
        updatedOn
        professionalRoles
        keyExperiences
        keyCreativeCredits
        location
        resumeReference {
            ...Reference
        }
        externalData {
            organizationName
            url
        }
        title
        organization
        department
        organizationReference {
            ...Reference
        }
        role
        production
        productionDepartment
        productionReference {
            ...Reference
        }
        productionRole
        profileImage
        autoCreated
        createdBy
        security {
            allowedActions
        }
        hasAccount
        alternateIds {
            label
            value
            url
        }
        memberships {
            organizationReference {
                ...Reference
            }
        }
        customFields {
            ...Field
        }
        businessContacts {
            name {
                firstName
                lastName
            }
            personReference {
                ...Reference
            }
            role
            contactInfo {
                ...ContactInfo
            }
            isPrimary
        }
    }
    ${ContactInfoFragment},
    ${FieldFragment},
    ${ReferenceFragment}
`;

export const ProductFragment: TypedDocumentNode<search.Product> = gql`
    fragment Product on Product {
        type
        subType
        categories {
            value
            lineage {
                value
            }
        }
        createdOn
        updatedOn
        packagingType
        offerTypes

        calendar {
            ...Reference
        }
        availableLocations {
            ...LocationFields
            timezone {
                id
                name
            }
        }
        sellable
        security {
            allowedActions
        }
        ownerOrganizationId
        ownerOrganizationName
    }
    ${LOCATION_FIELDS}
`;

export const ProductionFragment: TypedDocumentNode<search.Production> = gql`
    fragment Production on Production {
        name
        otherTitles {
            key
            value
        }
        showAttributes {
            key
            value
        }
        productionType
        productionStatus
        projectOwner {
            ...Reference
        }
        projectStatus
        keyPeople {
            isPrimary
            role
            organization
            label
            name {
                firstName
                lastName
                salutation
                suffix
            }
            personReference {
                id
            }
        }
        keyActivities {
            id
            uri
            name
            headline
            about
            activityType
            activityOwnerName
            activityStatus
            createdOn
            updatedOn
            dateRange {
                ...DateRange
            }
        }
        productionGroup {
            label
            productionReference {
                id
            }
        }
        productionAttributes
        managedProduction
        releaseDate
        releaseYear
        productionYears
        genre
        createdOn
        updatedOn
        createdByName
        security {
            allowedActions
        }
    }
    ${DateRangeFragment}
`;

export const ReservationFragment: TypedDocumentNode<search.Reservation> = gql`
    fragment Reservation on Reservation {
        dateRange {
            ...DateRange
        }
        status
        durationSpecified
        reservationFor {
            ...Reference
        }
        reservationForName
        hasDetailedSchedule
        ownerOrganizationName
        associatedResources {
            label
            reference {
                ...Reference
            }
        }
        opportunity {
            id
            name
            organization {
                name
            }
            production {
                name
            }
            OpportunityType
            contacts {
                name {
                    firstName
                    lastName
                }
                role
            }
        }
        reservationGuestsAvailable
        lineItemId
        calendar {
            ...Reference
        }
        primary
        createdOn
        updatedOn
        security {
            allowedActions
        }
    }
    ${DateRangeFragment}
`;

export const ResourceFragment: TypedDocumentNode<search.Resource> = gql`
    fragment Resource on Resource {
        type
        role
        resourceCategory
        budgetCategory
        location {
            ...LocationFields
            timezone {
                id
                name
            }
        }
        production {
            ...ProductionLimitedFields
        }
        status
        updatedOn
        security {
            allowedActions
        }
    }
    ${LOCATION_FIELDS}
    ${PRODUCTION_LIMITED_FIELDS}
`;

export const ShortlistFragment: TypedDocumentNode<search.Shortlist> = gql`
    fragment Shortlist on Shortlist {
        categories
        roles
        context {
            id
        }
        type
        security {
            allowedActions
        }
        updatedOn
    }
`;

export const TermFragment: TypedDocumentNode<search.Term> = gql`
    fragment Term on Term {
        security {
            allowedActions
        }
    }
`;

export const UnitFragment: TypedDocumentNode<search.Unit> = gql`
    fragment Unit on Unit {
        label
        symbol
    }
`;

export const FieldDefinitionFragment = gql`
    fragment FieldDefinition on FieldDefinition {
        name
        fieldType
        fieldName
        about
        group
        label
        metadata
    }
`;

export const CalendarFragment = gql`
    fragment Calendar on Calendar {
        name
        Locations {
            ...LocationFields
            timezone {
                id
                name
            }
        }
        Events {
            id
            name
            isWorking
            duration {
                ...DateRange
            }
        }
        unspecifiedTimeIsWorking
        workingTime {
            id
            name
            timeIntervals {
                day
                startTime
                endTime
                isWorking
            }
        }
        public
    }
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
`;

export const OrderFragment = gql`
    fragment Order on Order {
        status
        state
        orderNumber
        orderVersion
        isExternalOMS
        customerPO
        terms
        createdByName
        createdBy
        createdOn
        updatedByName
        updatedBy
        updatedOn
        ownerOrganizationId
        ownerOrganizationName
        orderDocumentReference {
            ...Reference
        }
        security {
            allowedActions
        }
    }
    ${ReferenceFragment}
`;

export const CalculationTypeFragment = gql`
    fragment CalculationType on CalculationType {
        formula
        label
        code
        assignTo
        appliesTo {
            procurementTypes
            leaseUnit
            lineTypes
        }
    }
`;

export const RateAppliesToFragment = gql`
    fragment RateAppliesTo on RateAppliesTo {
        entityType
        entityReference {
            ...Reference
        }
        productFields {
            type
            subType
            ownerOrganizations {
                ...Reference
            }
            hierarchicalCategories {
                lineage {
                    value
                }
                value
            }
        }
        resourceFields {
            type
            resourceCategory
            budgetCategory
            role
        }
    }
`;

export const RateFragment: TypedDocumentNode<search.Rate> = gql`
    fragment Rate on Rate {
        name
        code
        lineCurrency
        pricingLeaseUnit
        createdByName
        createdBy
        createdOn
        updatedOn
        ownerOrganizationId
        ownerOrganizationName
        appliesTo {
            ...RateAppliesTo
        }
        calculationType {
            ...CalculationType
        }
        itemPrice {
            ...Money
        }
        datesValid {
            ...DateRange
        }
        geoLocations {
            ...LocationFields
        }
        organizations {
            ...Reference
        }
        security {
            allowedActions
        }
    }
    ${LOCATION_FIELDS}
    ${MoneyFragment}
    ${DateRangeFragment}
    ${ReferenceFragment}
    ${CalculationTypeFragment}
    ${RateAppliesToFragment}
`;
export const ProductOwnerOrganizationFragment: TypedDocumentNode<search.ProductOwnerOrganization> = gql`
    fragment ProductOwnerOrganization on ProductOwnerOrganization {
        id
        name
        reference {
            ...Reference
        }
    }
    ${ReferenceFragment}
`;

export const ConversionFragment: TypedDocumentNode<search.Term> = gql`
    fragment Conversion on Conversion {
        from
        to
        factor
        period {
            start
            end
        }
    }
`;

export const InquiryFragmet: TypedDocumentNode<search.Inquiry> = gql`
    fragment Inquiry on Inquiry {
        createdOn
        updatedOn
        resourceType
        resourceCategory
        resourceName
        sentBy
        sentAt
        availabilityStatus
        role
        datesRequested {
            ...DateRange
        }
        candidateName
        locations {
            ...LocationFields
            timezone {
                id
                name
            }
        }
    }
    ${DateRangeFragment}
    ${LOCATION_FIELDS}
`;

export const FRAGMENTS = {
    Account: AccountFragment,
    Activity: ActivityFragment,
    CalculationType: CalculationTypeFragment,
    Calendar: CalendarFragment,
    CatalogSubType: CatalogSubTypeFragment,
    Category: CategoryFragment,
    Conversion: ConversionFragment,
    ContactInfo: ContactInfoFragment,
    FieldDefinition: FieldDefinitionFragment,
    Inquiry: InquiryFragmet,
    Note: NoteFragment,
    Opportunity: OpportunityFragment,
    Order: OrderFragment,
    Organization: OrganizationFragment,
    Person: PersonFragment,
    Product: ProductFragment,
    Production: ProductionFragment,
    ProductOwnerOrganization: ProductOwnerOrganizationFragment,
    Rate: RateFragment,
    Reservation: ReservationFragment,
    Resource: ResourceFragment,
    Shortlist: ShortlistFragment,
    Term: TermFragment,
    Unit: UnitFragment
};
