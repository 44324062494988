import { LicenseInfo } from '@mui/x-license-pro';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './common/App';
import { Icons } from './common/icons/Icons';
import LazyImport from './common/LazyImport';
import { RouteType } from './common/nav/AppRoute';
import {
    ADMINISTRATION,
    MARKETPLACE,
    PLANNING,
    PROFILES,
    RESERVATION_PROFESSIONAL,
    SUPPLIER,
    PRODUCTION_PROFESSIONAL
} from './common/nav/apps';
import { getAppRoutes } from './features';
import { FEATURE_ROUTES } from './featureRoutes';

LicenseInfo.setLicenseKey(
    'e7136bb3a024dc9f084be336f1e18da5Tz05NTYyMyxFPTE3NTQ1MDUyNzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

/* 
    SUPPLIER APP Routes 
    Define routes in sequence required for Supplier APP in a format - 
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the Supplier APP like Home
*/
const supplierRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Leads', subFeatures: ['leadsList', 'leadsResourceDetails'] },
    { feature: 'Opportunities', subFeatures: ['opportunityDetails'] },
    { feature: 'Orders', subFeatures: ['orderDetails'] },
    { feature: 'Quote', subFeatures: ['quoteDetails'] },
    { feature: 'Reservations', subFeatures: ['reservationDetails'] },
    { feature: 'Scheduler Preview', subFeatures: ['scheduleForSupplier'] },
    { feature: 'Report', subFeatures: ['reportDetails'] },
    { feature: 'Catalog', subFeatures: ['catalog', 'catalogDetails', 'catalogPrev'] },
    { feature: 'Production', subFeatures: ['productionDetails'] },
    { feature: 'Organization Units', subFeatures: ['organization'] },
    { feature: 'People Resources', subFeatures: ['peopleAsGuest', 'personLists'] },
    { feature: 'Lab', subFeatures: ['labItems'] }
];
const additionalSupplierRoutes = getAppRoutes('supplier', supplierRoutesInSequence) as RouteType[];

const defaultSupplierRoutes: RouteType[] = [
    {
        label: 'Home',
        app: SUPPLIER,
        feature: 'Home',
        icon: Icons.Home,
        link: `${SUPPLIER.home}`,
        path: `${SUPPLIER.home}`,
        component: LazyImport(() => import('./supply/dashboard/Dashboard')),
        drawer: true
    }
];

/* 
    PROFILES APP Routes 
    Define routes in sequence required for Profile APP in a format - 
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the Profile APP like Home
*/
const profileRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Account', subFeatures: ['profileAccount'] },
    { feature: 'Leads', subFeatures: ['leadsList', 'leadsDetails'] },
    { feature: 'Bookings', subFeatures: ['bookingLists'] },
    { feature: 'Scheduler Preview', subFeatures: ['schedulerForProfile'] },
    { feature: 'Shortlist', subFeatures: ['shortlistDetails'] },
    { feature: 'Organization Units', subFeatures: ['organizationUnits'] },
    { feature: 'People Resources', subFeatures: ['personLists'] },
    { feature: 'Production', subFeatures: ['productionDetails'] },
    { feature: 'Catalog', subFeatures: ['catalog'] },
    { feature: 'Account', subFeatures: ['profileAccountSettings'] }
];
const additionalProfileRoutes = getAppRoutes('profiles', profileRoutesInSequence) as RouteType[];

const defaultProfileRoutes: RouteType[] = [
    {
        label: 'Home',
        app: PROFILES,
        feature: 'Home',
        icon: Icons.Home,
        link: `${PROFILES.home}`,
        path: `${PROFILES.home}`,
        drawer: true,
        component: LazyImport(() => import('./profile/home/Home'))
    }
];

/* 
    ADMIN APP Routes 
    Add the default routes for the Admin APP like Home
    Admin has its own groups and within that it has differnet features
    Hence, keeping the groups as default routes 
        and within that defining routes in sequence required for Admin APP in a format - 
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
        This will ensure that the routes are loaded in the sequence defined and
        Get App Routes for the feature and subFeatures defined above.
    After the groups, there are some additional routes like Resource Fields, Resource Templates etc.
    Hence, adding them as additional routes
*/
const defaultAdminRoutes: RouteType[] = [
    {
        feature: 'Account',
        app: ADMINISTRATION,
        path: `${ADMINISTRATION.home}`,
        component: LazyImport(() => import('./admin/AdminHome'))
    },
    {
        label: 'Directory',
        app: ADMINISTRATION,
        icon: Icons.Directory,
        drawer: true,
        items: [
            ...(getAppRoutes('admin', [
                { feature: 'Organization Units', subFeatures: ['organizationUnits'] },
                { feature: 'People Resources', subFeatures: ['peopleResources'] }
            ]) as RouteType[])
        ]
    },
    {
        label: 'Manage Accounts',
        app: ADMINISTRATION,
        icon: Icons.ManageAccounts,
        drawer: true,
        items: [
            ...(getAppRoutes('admin', [
                { feature: 'Organizations', subFeatures: ['organizationDetails'] },
                { feature: 'People', subFeatures: ['peopleDetails'] }
            ]) as RouteType[])
        ]
    },
    {
        label: 'Account',
        app: ADMINISTRATION,
        icon: Icons.Account,
        drawer: true,
        items: [...(getAppRoutes('admin', [{ feature: 'Account', subFeatures: ['adminAccount'] }]) as RouteType[])]
    },
    {
        label: 'Applications Settings',
        app: ADMINISTRATION,
        icon: Icons.ApplicationsSettings,
        drawer: true,
        items: [
            ...(getAppRoutes('admin', [
                { feature: 'Application Settings', subFeatures: ['applicationSetting'] },
                { feature: 'Fields', subFeatures: ['fieldTermset'] },
                { feature: 'Exchange Rate', subFeatures: ['exchangeRate'] },
                { feature: 'Developer API', subFeatures: ['developerAPI'] },
                { feature: 'Quote', subFeatures: ['quoteSettings'] }
            ]) as RouteType[])
        ]
    }
];
const adminRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Resources', subFeatures: ['resourceTemplate', 'resourceDetails'] },
    { feature: 'Security', subFeatures: ['adminSecurity'] },
    { feature: 'Fields', subFeatures: ['fieldResource'] }
];
const additionalAdminRoutes = getAppRoutes('admin', adminRoutesInSequence) as RouteType[];

/*
    MARKETPLACE APP Routes
    Define routes in sequence required for Marketplace APP in a format -
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the Marketplace APP like Home
*/
const marketplaceRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Marketplace', subFeatures: ['marketplace'] }
];
const additionalMarketplaceRoutes = getAppRoutes('marketplace', marketplaceRoutesInSequence) as RouteType[];

const defaultMarketplaceRoutes: RouteType[] = [
    {
        label: 'Home',
        feature: 'Home',
        app: MARKETPLACE,
        icon: Icons.Home,
        link: `${MARKETPLACE.home}`,
        path: `${MARKETPLACE.home}`,
        component: LazyImport(() => import('./marketplace/MarketplaceHome'))
    }
];

/*
    PLANNING APP Routes
    Define routes in sequence required for Planning APP in a format -
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the Planning APP like Home
*/
const planningRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Production', subFeatures: ['productionWorkspace'] },
    { feature: 'Schedule', subFeatures: ['scheduleDetails'] },
    { feature: 'Shortlist', subFeatures: ['shortlistDetails', 'shortlistResource'] },
    { feature: 'Bookings', subFeatures: ['bookingLists', 'bookingDetails'] },
    { feature: 'Production', subFeatures: ['productionDetails'] }, // Production appears again later
    { feature: 'Organization Units', subFeatures: ['organization'] },
    { feature: 'People Resources', subFeatures: ['personEditor', 'personLists'] }
];
const additionalPlanningRoutes = getAppRoutes('planning', planningRoutesInSequence) as RouteType[];

const defaultPlanningRoutes: RouteType[] = [
    {
        label: 'Home',
        feature: 'Production',
        app: PLANNING,
        icon: Icons.Workspaces,
        link: `${PLANNING.home}`,
        path: `${PLANNING.home}`,
        component: LazyImport(() => import('./production/ProductionList'))
    }
];

/*
    GUEST APP Routes
    Define routes in sequence required for Guest APP in a format -
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
        These feature and subFeatures are defined in the feature.tsx file.
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Guest APP has only one Route, that is of Lead, hence no need to define default routes
*/
const guestRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Leads', subFeatures: ['leadsList'] }
];
const guestRoutes = getAppRoutes('guest', guestRoutesInSequence) as RouteType[];

/* 
    RESERVATION PROFESSIONAL APP Routes 
    Define routes in sequence required for RESERVATION PROFESSIONAL APP in a format - 
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the RESERVATION PROFESSIONAL APP like Home
*/

const reservationProfessionalRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Leads', subFeatures: ['leadsList', 'leadsDetails'] },
    { feature: 'People Resources', subFeatures: ['personLists'] },
    { feature: 'Organization Units', subFeatures: ['organization'] },
    { feature: 'Production', subFeatures: ['productionDetails'] },
    { feature: 'Catalog', subFeatures: ['catalog', 'catalogDetails'] },
    { feature: 'Shortlist', subFeatures: ['shortlistDetails', 'shortlistResource'] }
];

const reservationProfessionalRoutes = getAppRoutes(
    'reservationProfessional',
    reservationProfessionalRoutesInSequence
) as RouteType[];

const defaultReservationProfessionalRoute: RouteType[] = [
    {
        label: 'Home',
        feature: 'Home',
        app: RESERVATION_PROFESSIONAL,
        icon: Icons.Home,
        link: `${RESERVATION_PROFESSIONAL.home}`,
        path: `${RESERVATION_PROFESSIONAL.home}`,
        component: LazyImport(() => import('./reservationProfessional/Home')),
        drawer: true
    }
];

/* 
    PRODUCTION PROFESSIONAL APP Routes 
    Define routes in sequence required for PRODUCTION PROFESSIONAL APP in a format - 
        feature: 'FeatureName', subFeatures: ['subFeature1', 'subFeature2']
    This will ensure that the routes are loaded in the sequence defined.
    Get App Routes for the feature and subFeatures defined above.
    Add the default routes for the PRODUCTION PROFESSIONAL APP like Home
*/
const productionProfessionalRoutesInSequence: { feature: keyof typeof FEATURE_ROUTES; subFeatures: string[] }[] = [
    { feature: 'Production', subFeatures: ['productionWorkspace'] },
    { feature: 'Shortlist', subFeatures: ['shortlistDetails', 'shortlistResource'] },
    { feature: 'Organization Units', subFeatures: ['organizationUnits'] },
    { feature: 'People Resources', subFeatures: ['personLists'] }
];
const additionalProductionProfessionalRoutes = getAppRoutes(
    'productionProfessional',
    productionProfessionalRoutesInSequence
) as RouteType[];

const defaultProductionProfessionalRoutes: RouteType[] = [
    {
        label: 'Home',
        app: PRODUCTION_PROFESSIONAL,
        feature: 'Home',
        icon: Icons.Home,
        link: `${PRODUCTION_PROFESSIONAL.home}`,
        path: `${PRODUCTION_PROFESSIONAL.home}`,
        drawer: true,
        component: LazyImport(() => import('./productionProfessional/Home'))
    }
];

const root = createRoot(document.getElementById('app'));
root.render(
    <App
        routes={[]
            .concat([...defaultSupplierRoutes, ...additionalSupplierRoutes])
            .concat([...defaultProfileRoutes, ...additionalProfileRoutes])
            .concat([...defaultAdminRoutes, ...additionalAdminRoutes])
            .concat([...defaultMarketplaceRoutes, ...additionalMarketplaceRoutes])
            .concat([...defaultPlanningRoutes, ...additionalPlanningRoutes])
            .concat([...guestRoutes])
            .concat([...defaultReservationProfessionalRoute, ...reservationProfessionalRoutes])
            .concat([...defaultProductionProfessionalRoutes, ...additionalProductionProfessionalRoutes])}
    />
);
